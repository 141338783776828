import React from 'react';
import { Box } from 'grommet';
import { THEME_COLORS } from '../../config/theme';
import './spinner.css';

export const loader = () => {
  return (
    <Box pad="small" align="start">
      <Spinner size={10} />
    </Box>
  );
};

const Spinner = ({ size, color }) => {
  const iconSize = size || 20;
  const balls = [...Array(3)].map((_, index) => (
    <span
      key={index}
      style={{
        background: color || THEME_COLORS.brand,
        width: iconSize,
        height: iconSize
      }}
    />
  ));
  return <div className="beat-loader">{balls}</div>;
};

export default Spinner;

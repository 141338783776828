import { Box, Button } from 'grommet';
import { theme, THEME_COLORS } from '../../config/theme';
import Spinner from '../Spinner/Spinner';

const ButtonWithSpinner = ({ isLoading, label, disabled, ...props }) => {
  let spinnerColor = THEME_COLORS.brand;
  if (props.primary) {
    spinnerColor = theme.button.primary.color;
  } else if (props.secondary) {
    spinnerColor = theme.button.secondary.color;
  }
  return (
    <Button
      label={
        isLoading ? (
          <Box style={{ display: 'inline-block' }}>
            <Spinner size={10} color={spinnerColor} />
          </Box>
        ) : (
          label
        )
      }
      disabled={isLoading ? true : disabled}
      {...props}
    />
  );
};

export default ButtonWithSpinner;

import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'grommet';
import { Button } from 'src/components';
import { THEME_COLORS } from '../../config/theme';

/**
 * @description Implements a password field wrapper component that adds
 * @description a control to show/hide password text on click
 * @component
 * @property {Object} inputRef A react reference to the password input field
 * @property {Object} children The password component definition
 */
const PasswordReveal = ({ inputRef, children }) => {
  const [reveal, setReveal] = useState(true);

  return (
    <Box direction="row" align="center">
      {/* the actual password field/component will be inserted here */}
      {children}
      <Button
        style={{ border: 'none' }}
        icon={
          reveal ? (
            <FontAwesomeIcon icon={faEye} color={THEME_COLORS.brand} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} color={THEME_COLORS.brand} />
          )
        }
        onClick={() => {
          inputRef.current.type = reveal ? 'text' : 'password';
          setReveal(!reveal);
        }}
      />
    </Box>
  );
};

export default PasswordReveal;

import React, { useCallback, useContext } from 'react';
import { Box, ResponsiveContext, Text } from 'grommet';
import { t } from 'i18next';
import { THEME_COLORS } from '../../config/theme';
import { isSlotTimeTodayAndPast } from '../Card/cardData';
import ToolTip from './ToolTip';

/**
 * Callback when one of slot changed
 * @callback ChangeSlotCallback
 * @param {Object} slot
 */

/**
 * A box displaying a slot time ans status
 * @param {Object} props Component props
 * @param {Object} props.slot The associated event slot
 * @param {boolean} props.isPast Slot is in the past
 * @param {string} props.tip Text of the tooltip
 * @param {Object} props.color Color of the box and tooltip
 * @param {Object} props.isEmployee Display for employee or practitioner
 * @param {ChangeSlotCallback} props.onChangeSlot Callback when one of slot changed
 * @param {Object} props.selectedSlot if provided defines slot currently selected by user in order to highlight with different background color
 * @returns {React.ReactElement}
 */
const SlotTimeBox = ({ slot, isPast, tip, color, isEmployee, onChangeSlot, selectedSlot }) => {
  const { from, booked, reserved } = slot;
  let isDisabled = booked || isPast || (isEmployee ? reserved : false);
  const size = useContext(ResponsiveContext);
  const style = onChangeSlot
    ? isDisabled
      ? { cursor: 'not-allowed' }
      : null
    : // if no click handler, no cursor change on hover
      { cursor: 'default' };

  const handleOnClick = useCallback(() => {
    if (!isDisabled && onChangeSlot) {
      onChangeSlot(slot);
    }
  }, [slot, isDisabled, onChangeSlot]);

  const { from: selectedFrom = '' } = selectedSlot ?? {};

  return (
    <Box align="start" margin="small" onClick={handleOnClick}>
      <ToolTip content={tip} placement="bottom" backgroundColor={color} disabled={size === 'small'}>
        <Box
          pad={{ vertical: 'xsmall', horizontal: 'xsmall' }}
          direction="row"
          justify="center"
          style={style}
          align="center"
          background={selectedFrom ? (selectedFrom === from ? 'orange' : color) : color}
          round="xsmall"
        >
          <Text color="white">{from}</Text>
        </Box>
      </ToolTip>
    </Box>
  );
};

/**
 * UI Container to render list of event slot time boxes
 * rendering depends on showReserved and showBooked props
 * if showReserved = true, reserved slots will be rendered as reserved otherwise appear as unavailable
 * if showBooked = true, booked slots will be rendered as booked otherwise appear as unavailable
 * @param {Object} props Component props
 * @param {Object} props.event The related event object
 * @param {Object[]} props.slots Slots we need to render
 * @param {boolean} [props.showReserved=true] - whether or not to show reserved slots as reserved
 * @param {boolean} [props.showBooked=true] - whether or not to show booked slots as booked
 * @param {boolean} [props.isEmployee=false] - true if render done for Employee UI
 * @param {ChangeSlotCallback} [props.onChangeSlot] Callback when one slot is clicked
 * @param {string} [props.bookedColor=THEME_COLORS.booked] background color for booked slots
 * @returns {React.ReactElement}
 */
const SlotTimeBoxContainer = ({
  event,
  slots,
  showReserved = true,
  showBooked = true,
  isEmployee = false,
  onChangeSlot,
  bookedColor = THEME_COLORS.booked,
  selectedSlot
}) => {
  const allTimeBoxParameters = {
    booked: { color: bookedColor, tip: t('component.slotTimeBox.tip.booked') },
    reserved: { color: THEME_COLORS.reserved, tip: t('component.slotTimeBox.tip.reserved') },
    available: { color: THEME_COLORS.available, tip: t('component.slotTimeBox.tip.available') },
    unavailable: { color: THEME_COLORS.booked, tip: t('component.slotTimeBox.tip.unavailable') },
    disabled: { color: THEME_COLORS.grey, tip: t('component.slotTimeBox.tip.disabled') }
  };
  return (
    <Box direction="row" wrap height={{ max: '240px' }} overflow="auto">
      {slots &&
        slots.map((slot) => {
          const { from, booked, reserved } = slot;
          let isPast = event ? isSlotTimeTodayAndPast(event, slot) : false;
          let selectedTimeBoxParameter = allTimeBoxParameters.available;
          if (reserved) {
            selectedTimeBoxParameter = showReserved ? allTimeBoxParameters.reserved : allTimeBoxParameters.unavailable;
          } else if (booked) {
            selectedTimeBoxParameter = showBooked ? allTimeBoxParameters.booked : allTimeBoxParameters.unavailable;
          } else if (!booked && !reserved && isPast) {
            selectedTimeBoxParameter = allTimeBoxParameters.disabled;
          }
          return (
            <SlotTimeBox
              selectedSlot={selectedSlot}
              slot={slot}
              key={from}
              onChangeSlot={onChangeSlot}
              isEmployee={isEmployee}
              isPast={isPast}
              tip={selectedTimeBoxParameter.tip}
              color={selectedTimeBoxParameter.color}
            />
          );
        })}
    </Box>
  );
};

export default SlotTimeBoxContainer;

import { useEffect, useState } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

// Get address details of a placeId
export const getPlaceDetails = async ({ place_id }) => {
  const parameter = {
    // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
    placeId: place_id,
    // Specify the return data that you want (optional)
    fields: ['address_components']
  };
  //
  let { address_components } = await getDetails(parameter);
  let objectComponents = address_components.reduce((acc, curr) => {
    let key = curr?.types[0];
    acc[key] = curr?.long_name;
    return acc;
  }, {});
  let { postal_code: zipcode, locality: city, route, street_number } = objectComponents;
  let address = { zipcode, city, street: `${street_number} ${route}` };
  return address;
};

export function usePlace(props) {
  const {
    suggestions: { status, data },
    setValue
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      componentRestrictions: {
        country: ['fr', 'be', 'ch']
      }
    }
  });

  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (status === 'OK' && data) {
      setSuggestions(
        data.map(({ place_id, structured_formatting: { main_text, secondary_text } }) => ({
          label: `${main_text} ${secondary_text}`,
          value: place_id
        }))
      );
    }
  }, [data, status]);

  return { suggestions, setValue };
}

import { useEffect } from 'react';
import moment from 'moment';
import { addMethod, date, number, reach, ref, setLocale, string } from 'yup';
import { USER_GENDER } from '../constants';
import { getPlaceDetails } from './places';

// ----- REGEXP -----
// Regexp defining Password constraints (8 chars, 1 Uppercase letter, 1 lowercase letter, 1 digit, 1 special char from @, $, !, %, *, #, ?, &, /, _, -, .)
export const PASSWD_REGEXP = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[/@$!%*#?&\-._])[A-Za-z\d\-_@$!%*#?&./]{8,}$/;
// Regexp defining email validation constraints
export const EMAIL_REGEXP = /^\s*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+\s*$/;
// Regexp defining a valid name string - string can contain any lowercase and uppercase, special character for composed name (- , ' ) and white space
export const NAME_REGEXP = /^\s*[\p{L}'][ \p{L}'-]*[\p{L}]\s*$/u;
// Regexp defining a valid french phone number - number can contain spaces, - or .
export const FRENCH_PHONE_NUMBER_REGEXP = /^\s*(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}\s*$/gim;
// Regexp defining valid french zip code (5 digits)
const ZIP_CODE_REGEXP = /^\s*(?:0[1-9]|[1-8]\d|9[0-8])\d{3}\s*$/i;
// Regexp defining french Siret number (14 numbers all together without separation)
export const SIRET_REGEXP = /^\s*[0-9]{14}\s*$/;

// Regexp defining ADELI Id (9 digits all together without separation)
export const ADELI_REGEXP = /^[0-9]{9}$/;
// Regexp defining RPPS Id (11 digits all together without separation)
export const RPPS_REGEXP = /^[0-9]{11}$/;

export const PWD_ALLOWED_SPECIAL_CHARS = '@ $ ! % * # ? & / _ - .';
export const PWD_MIN_LENGTH = 8;
export const PWD_SPECIAL_CHAR_REGEXP = /(?=.*[/@$!%*#?&\-._])/;

// Minimum required age defining valid date of birth
const MIN_AGE = 16;
// Maximum date defining valid date of birth
const MAX_BIRTH_DATE = '1930-01-01';

// ----- ERROR MESSAGES -----
export const INVALID_PWD_ERROR = `Le mot de passe doit contenir au moins ${PWD_MIN_LENGTH} caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial au choix dans ${PWD_ALLOWED_SPECIAL_CHARS}`;

addMethod(string, 'checkPassword', function () {
  return this.matches(PASSWD_REGEXP, INVALID_PWD_ERROR);
});

addMethod(string, 'checkPasswordConfirmation', function () {
  return this.oneOf([ref('password'), null], 'Les mots de passes doivent être identiques');
});

addMethod(string, 'checkEmail', function () {
  return this.matches(EMAIL_REGEXP, "Format d'email invalide");
});

addMethod(string, 'checkName', function () {
  return this.matches(
    NAME_REGEXP,
    "Ce champ ne doit pas contenir de nombres ou de caractères spéciaux en dehors de - , ' ."
  );
});

addMethod(string, 'checkFrenchPhone', function () {
  return this.matches(FRENCH_PHONE_NUMBER_REGEXP, 'Veuillez saisir un numéro de téléphone valide');
});

addMethod(string, 'checkFrenchZipCode', function () {
  return this.matches(ZIP_CODE_REGEXP, 'Veuillez entrer un code postal valide');
});

addMethod(string, 'checkSiret', function () {
  return this.matches(
    SIRET_REGEXP,
    'Veuillez entrer un numéro de SIRET valide (14 chiffres sans espaces ni séparateurs)'
  );
});

addMethod(date, 'checkBirthDate', function () {
  return this.transform(parseDate)
    .typeError('La date de naissance doit être une date valide au format JJ/MM/AAAA')
    .min(
      moment(MAX_BIRTH_DATE),
      `${'La date de naissance ne peut être antérieure au '}${moment(MAX_BIRTH_DATE).format('DD/MM/YYYY')}`
    )
    .max(
      maximumBirthDate(MIN_AGE, 'DD/MM/YYYY'),
      `${'La date de naissance ne peut pas être plus récente que le '}${maximumBirthDate(MIN_AGE, 'DD/MM/YYYY')}`
    );
});

addMethod(number, 'checkGender', function () {
  return this.min(USER_GENDER.FEMALE).max(USER_GENDER.OTHER);
});

addMethod(string, 'checkAdeli', function () {
  return this.matches(
    ADELI_REGEXP,
    'Veuillez entrer un identifiant ADELI valide (9 chiffres sans espaces ni séparateurs)'
  );
});

addMethod(string, 'checkRPPS', function () {
  return this.matches(
    RPPS_REGEXP,
    'Veuillez entrer un identifiant RPPS valide (11 chiffres sans espaces ni séparateurs)'
  );
});

export function buildYupLocale(_, translate) {
  console.log('formValidation.js/buildYupLocale | Building locale');

  setLocale({
    mixed: {
      required: translate('form.required')
    }
  });
}

/**
 * Normalize phone number by removing separators and national indicators (support only +33 french indicator)
 * @param {string} num phone number to be normalized
 * @returns {string} returns a phone number where spaces, -  . and french national indicator have been removed
 */
export const normalizePhoneNumber = (num) => {
  if (num && typeof num === 'string') {
    let ret = num.split(' ').join('');
    ret = ret.split('-').join('');
    ret = ret.split('.').join('');
    return ret.startsWith('+33') ? ret.replace('+33', '0') : ret;
  }
  return '';
};

// Check in yup validation schema if fieldName is required
export const isRequired = (validationSchemas, fieldName, values) => {
  const fieldValidationSchema = validationSchemas
    ? reach(validationSchemas, fieldName, values, values).resolve({
        parent: values,
        context: values,
        value: values
      })
    : false;
  const resolvedSchema = fieldValidationSchema
    ? fieldValidationSchema.resolve({
        parent: values,
        context: validationSchemas
      })
    : false;

  const description = resolvedSchema.describe();
  const isRequired = !description.optional && !description.nullable;

  return isRequired;
};

/**
 * Returns the most recent birthDate accepted to register assuming user
 * must have a minimum age
 * Accepts a parameter corresponding to minimum age
 * @param {number} age min user age in years
 * @returns {string} most recent accepted birthdate in YYYY-MM-DD format
 */
export const maximumBirthDate = (age, format) => {
  return moment().subtract(age, 'years').format(format);
};

/**
 * checks if given date is between 01/01/1900 and date given as param
 * so that user is at least 16 years old
 * @param {Date} value the date to check (momentjs)
 * @returns {boolean} true if date as input is inside expected range
 */
export const minMaxDate = (value) => {
  return value.isBetween(MAX_BIRTH_DATE, maximumBirthDate(MIN_AGE, 'YYYY-MM-DD'));
};

/**
 * Parse date input into JS date format
 * @param {any} value the yup passed in value that represent the current state of the cast
 * @param {any} originalValue the yup passed in original value (raw initial value) provided at schema validation time
 * @returns {null | "invalid date" | Date} null if originalValue is an empty string, otherwise "invalid date" or JS date format
 */
export const parseDate = (value, originalValue) => {
  if (originalValue === '') {
    return null;
  } else {
    const parsedDate = moment(originalValue, 'DD/MM/YYYY').toDate();
    return parsedDate instanceof Date ? parsedDate : 'invalid date';
  }
};

/**
 * returns a string without empty spaces at the beginning and the end
 * @param {string} string string to normalize
 * @returns {string}
 */

export const normalizeString = (string) => {
  if (string && typeof string === 'string') {
    return string.trim();
  }
  return '';
};

/**
 * Returns a string without the "undefined" inserted by google places API when provided address doesn't have any street number
 * @param {string} string Address to normalize
 * @returns {string}
 */
export const normalizeAddress = (string) => {
  if (string && typeof string === 'string') {
    let stringToRemove = /undefined/;
    return string.replace(stringToRemove, '').trim();
  }
  return '';
};

/**
 * Normalize an email address by applying lowercase and trim
 * @param {string} email Email to normalize
 * @returns {string} A normalized email
 */
export const normalizeEmail = (email) => {
  if (email && typeof email === 'string') {
    return email.trim().toLowerCase();
  }
  return '';
};

/**
 * checks if phone number is a mobile phone number
 * mobile phone number rules:
 * - valid french phone number 10 digits
 * - or starts by +33 followed by nine digits without zero
 * - starts with 06 or 07 or +336 or +337
 * @param {string} phone the phone number to check
 * @returns {boolean} true if phone is a mobile phone number or false otherwise
 */
export const isMobilePhoneNumber = (phone) => {
  const number = phone.trim();
  if (number.match(FRENCH_PHONE_NUMBER_REGEXP)) {
    return number.startsWith('06') || number.startsWith('07') || number.startsWith('+336') || number.startsWith('+337');
  }
  return false;
};

/**
 * Log an error message on a formik error object
 * @param {object} errors Formik errors object from useFormik
 * @returns
 */
export function useFormikErrorsLogger(errors) {
  return useEffect(() => {
    if (errors && typeof errors === 'object' && Object.keys(errors).length) {
      console.log('Formik validation errors', errors);
    }
  }, [errors]);
}

export const handleSelectStreetSuggestion = async (event, setFieldValue) => {
  let suggestion = event?.suggestion;
  let { street, city, zipcode } = await getPlaceDetails({
    place_id: suggestion?.value
  });
  setFieldValue('street', normalizeAddress(street));
  setFieldValue('city', city);
  setFieldValue('zipcode', zipcode);
};

export const normalizeStringOnBlur = (event, handleBlur, setFieldValue, fieldName) => {
  setFieldValue(fieldName, event.target.value.replaceAll(/(?<=\S)\s+(?=\S)/g, ''));
  handleBlur(event);
};

export const normalizeDateStringOnBlur = (event, handleBlur, setFieldValue, fieldName) => {
  const dateInstance = moment.utc(event.target.value, 'DD/MM/YYYY');
  setFieldValue(fieldName, dateInstance.isValid() ? dateInstance.format('DD/MM/YYYY') : event.target.value);
  handleBlur(event);
};

/**
 * returns true if value not empty and matches regexp
 * @param {string} val - value to check
 * @param {RegExp} regexp - regular expression to check against
 * @returns {boolean} true if val not empty and matches regexp
 */
export const hasValidRegexpValue = (val, regexp) => {
  if (val) {
    const reg = new RegExp(regexp);
    return reg.test(val);
  }
  return false;
};

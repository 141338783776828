import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import { Avatar, Box } from 'grommet';
import styled from 'styled-components';
import { ToolTip } from '../../components';
import { THEME_COLORS } from '../../config/theme';

// Google map markor
const Markor = (props) => {
  const { t } = useTranslation();
  const markorTitle = props.selected ? `${props.name} (${t('page.admin.sites.legend.connection.site')})` : props.name;
  return (
    <>
      <ToolTip content={markorTitle} placement="bottom" backgroundColor={THEME_COLORS.text}>
        <Box animation={props.selected ? { type: 'pulse', delay: 0, duration: 750 } : null}>
          {props.logo ? (
            <div
              style={{
                width: '35px',
                height: '35px'
              }}
            >
              <Avatar size="35px" src={props.logo} style={{ border: '2px solid #FD8183' }} />
            </div>
          ) : (
            <div
              style={{
                background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                borderRadius: '4px',
                padding: '4px',
                width: '25px',
                height: '25px',
                border: '2px solid #FD8183',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}
            >
              <FontAwesomeIcon
                icon={faBuilding}
                color={THEME_COLORS[props.selected ? 'status-ok' : 'title']}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          )}
        </Box>
      </ToolTip>
    </>
  );
};

const MarkorMap = styled(Markor)`
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: ${(props) => (props.selected ? '1000' : '1')};
`;

const SitesMap = ({ mySite, myLogo, sites, zoom, mapRef = null, centerMap, onGoogleApiLoaded }) => {
  return (
    <div style={{ height: '75vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_API_KEY
        }}
        defaultCenter={centerMap}
        defaultZoom={zoom}
        ref={mapRef}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
      >
        {sites &&
          sites
            .filter((s) => s?.address?.coordinates)
            .map((site) => {
              let { lat, lng } = site.address.coordinates;
              console.log(`Adding Marker for lat: ${lat} lng: ${lng}`);
              return (
                <MarkorMap
                  key={site._id}
                  lat={lat}
                  lng={lng}
                  selected={site._id === mySite}
                  name={site.name}
                  logo={site._id === mySite ? myLogo : null}
                />
              );
            })}
      </GoogleMapReact>
    </div>
  );
};

const CompanySitesMap = ({ company, sites, logo }) => {
  const mapRef = useRef();
  const [zoom] = useState(8);
  const MapCenter = useMemo(() => company?.address?.coordinates || { lat: 48.8534, lng: 2.3488 }, [company]);
  const onGoogleApiLoaded = (google) => {};

  return (
    <>
      {sites && sites.length > 0 && (
        <SitesMap
          mySite={company._id}
          myLogo={logo}
          sites={sites}
          zoom={zoom}
          mapRef={mapRef}
          onGoogleApiLoaded={onGoogleApiLoaded}
          centerMap={MapCenter}
        />
      )}
    </>
  );
};

export default CompanySitesMap;

import styled from 'styled-components';

const BulletPoint = styled.div`
  border-radius: 50%;
  height: ${(props) => props.$size || '7px'};
  width: ${(props) => props.$size || '7px'};
  background: ${(props) => props.$pointColor};
  margin: 0 3.5px;
`;

export default BulletPoint;

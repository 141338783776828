import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, FormField, ResponsiveContext, Text } from 'grommet';
import { Button, Modal, TextInput } from 'src/components';
import { requestAuthUserEmailUpdate } from 'src/features/auth/authSlice';
import { EMAIL_REGEXP, hasValidRegexpValue } from '../../utility/formValidation';

const INIT_EMAILS = {
  newEmail: '',
  newEmailConfirm: ''
};

const ModalEmailChange = ({ modal, setModal }) => {
  const user = useSelector((state) => state?.auth?.user);
  const [modalFeedback, setModalFeedback] = useState({});
  const [emailValues, setEmailValues] = useState(INIT_EMAILS);
  const [emailOk, setEmailOk] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    // whenever the modal is toggled, remove feedback message & clear emailValues
    setModalFeedback({});
    setEmailValues(INIT_EMAILS);
  }, [modal]);
  const size = useContext(ResponsiveContext);

  const checkEmailValues = () => {
    const { newEmail = '', newEmailConfirm = '' } = emailValues ?? {};
    setModalFeedback({});
    setEmailOk(false);
    if (newEmail !== '' && !hasValidRegexpValue(newEmail, EMAIL_REGEXP)) {
      setModalFeedback({
        status: 'error',
        message: t('form.new.email.format.error')
      });
      return;
    }
    if (newEmail === user?.email) {
      setModalFeedback({
        status: 'error',
        message: t('form.new.email.no.change')
      });
      return;
    }
    if (newEmailConfirm !== '' && !hasValidRegexpValue(newEmailConfirm, EMAIL_REGEXP)) {
      setModalFeedback({
        status: 'error',
        message: t('form.new.email.format.error')
      });
      return;
    } else {
      if (newEmail === '' || newEmailConfirm === '') {
        return;
      }
      if (newEmail !== newEmailConfirm) {
        setModalFeedback({
          status: 'error',
          message: t('form.new.email.values.mismatch.error')
        });
        return;
      }
      setEmailOk(true);
    }
  };

  const updateEmailState = (field, value, check) => {
    const emails = { ...emailValues };
    emails[field] = value.toLowerCase();
    setEmailValues(emails);
    if (check) {
      checkEmailValues();
    }
  };

  const confirmAndDisconnect = () => {
    // close modal and redirect to email sent landing page
    setModal(false);
    history.push('/email-sent');
  };

  return (
    <Modal show={modal} setShow={setModal} flex={false}>
      <Box pad={size === 'small' ? 'large' : 'medium'} align="center" justify="between" width="medium">
        <Text size="large" weight="bold" color="brand" margin={{ bottom: 'small' }}>
          {t('modal.change.email.title')}
        </Text>
        <Text size="small" textAlign="center" color="title" margin={{ vertical: 'small' }}>
          {t('modal.change.email.description')}
        </Text>
        <FormField
          width="medium"
          name="newEmail"
          htmlFor="newEmail"
          required={true}
          label={t('form.new.email.field.label')}
          validate={[
            (email) => {
              if (!email) return t('form.required');
              return undefined;
            },
            {
              regexp: EMAIL_REGEXP,
              message: t('form.email.badFormat')
            }
          ]}
        >
          <TextInput
            type="email"
            id="newEmail"
            name="newEmail"
            value={emailValues.newEmail}
            onBlur={(e) => updateEmailState('newEmail', e.target.value, true)}
            onChange={(e) => updateEmailState('newEmail', e.target.value, false)}
            placeholder={
              <Text size="16px" color="lightGrey">
                {t('form.email.common.placeholder')}
              </Text>
            }
          />
        </FormField>
        <FormField
          width="medium"
          name="newEmailConfirm"
          htmlFor="newEmailConfirm"
          required={true}
          validate={[
            (email) => {
              if (!email) return t('form.required');
              return undefined;
            },
            {
              regexp: EMAIL_REGEXP,
              message: t('form.email.badFormat')
            }
          ]}
        >
          <TextInput
            type="email"
            id="newEmailConfirm"
            name="newEmailConfirm"
            value={emailValues.newEmailConfirm}
            onBlur={(e) => updateEmailState('newEmailConfirm', e.target.value, true)}
            onChange={(e) => updateEmailState('newEmailConfirm', e.target.value, false)}
            onPaste={(e) => {
              // disable Paste action on confirm field
              e.preventDefault();
              return false;
            }}
            placeholder={
              <Text size="16px" color="lightGrey">
                {t('form.email.confirm.placeholder')}
              </Text>
            }
          />
        </FormField>
        <Box>
          {emailOk && (
            <Text size="small" textAlign="center">
              {t('modal.new.email.info')}
            </Text>
          )}
        </Box>
        {modalFeedback?.status && (
          <Box
            align="center"
            margin={{ top: 'small' }}
            animation={{
              type: 'fadeIn',
              duration: 300
            }}
          >
            <Text
              size="xsmall"
              textAlign="center"
              color={modalFeedback.status === 'error' ? 'status-critical' : 'brand'}
            >
              {modalFeedback.message}
            </Text>
          </Box>
        )}
        <Box direction="row" gap="small" margin={{ top: 'medium' }}>
          <Button
            label={t('common.button.cancel')}
            onClick={() => {
              setModalFeedback({});
              setModal(false);
            }}
          />
          <Button
            primary
            label={t('common.label.ok').toUpperCase()}
            disabled={!emailOk}
            onClick={async () => {
              // perform email update request
              setEmailOk(false);
              const { status, error } = await dispatch(requestAuthUserEmailUpdate({ email: emailValues.newEmail }));
              if (status === 'success') {
                setModalFeedback({
                  status: 'success',
                  message: t('modal.new.email.update.success')
                });
                // give user 3 secs before auto closing dialog
                // and redirect user to landing page + disconnect
                setTimeout(confirmAndDisconnect, 3000, false);
              }
              if (status === 'error') {
                if (error === 'email_already_used') {
                  setModalFeedback({
                    status: 'error',
                    message: t('modal.new.email.update.error.exists')
                  });
                } else {
                  setModalFeedback({
                    status: 'error',
                    message: t('modal.new.email.update.error')
                  });
                }
              }
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalEmailChange;

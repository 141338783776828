import React, { useContext } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { theme } from '../../config/theme';

const RadiusSlider = ({ min, max, onChange, unit = 'Km', value = 0, sliderWidth = '450px' }) => {
  const responsiveSize = useContext(ResponsiveContext);
  return (
    <Box fill direction="row" pad={{ bottom: 'small' }} style={{ position: 'relative', width: sliderWidth }}>
      <Slider
        value={value}
        min={parseInt(min)}
        max={parseInt(max)}
        step={5}
        defaultValue={value}
        onChange={onChange}
        marks={{ 10: 10, 20: 20, 30: 30, 40: 40, 50: 50, 60: 60, 70: 70, 80: 80, 90: 90, 100: 100 }}
        dotStyle={{ borderColor: theme.global.colors.brand }}
        activeDotStyle={{ borderColor: theme.global.colors.brand }}
        railStyle={{ backgroundColor: theme.global.colors.lightBrand, height: 3 }}
        trackStyle={{ backgroundColor: theme.global.colors.lightBrand, height: 3 }}
        handleStyle={{
          borderColor: theme.global.colors.brand,
          height: 20,
          width: 20,
          marginLeft: 0,
          marginTop: -9,
          backgroundColor: theme.global.colors.lightBrand,
          opacity: 1
        }}
      />
      {responsiveSize !== 'small' && (
        <Box color="text" style={{ position: 'absolute', right: '-50px' }}>
          {unit}
        </Box>
      )}
    </Box>
  );
};

export default RadiusSlider;

import React, { useCallback, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import { rowBackgroundConfig, rowStyleDefault } from './VList';

/**
 * VList row internal component
 * @param {Object} props - component properties
 * @returns {React.Component}
 */
const Row = ({ index, data, rowBackground = rowBackgroundConfig, setSize, ListComponent }) => {
  const rowRef = useRef();
  const item = data[index];
  const rowBackgroundColor = rowBackground.alternateRowBackground
    ? index % 2
      ? rowBackground.oddRowColor
      : rowBackground.evenRowColor
    : rowBackground.evenRowColor;
  const newStyle = {
    ...rowStyleDefault,
    ...{
      backgroundColor: rowBackgroundColor
    }
  };

  React.useEffect(() => {
    setSize(index, rowRef.current.getBoundingClientRect().height);
  }, [index, setSize]);

  return (
    <div key={item?._id || index} ref={rowRef} style={newStyle}>
      <ListComponent item={item} />
    </div>
  );
};

/**
 * Virtual list with variable row height
 * @param {Object[]} [props.listData] - Array of the data to be rendered in each row
 * @param {React.Component} [props.ListComponent] - Row component
 * @param {number} [props.height = 300] - height of the virtual list
 * @returns {React.Component}
 */
const VariableVList = ({ listData, ListComponent, height = 300 }) => {
  const listRef = useRef();
  const sizeMap = useRef({});
  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);
  const getSize = (index) => sizeMap.current[index] || 50;

  return (
    <VariableSizeList
      ref={listRef}
      height={height}
      width="100%"
      itemCount={listData.length}
      itemSize={getSize}
      itemData={listData}
    >
      {({ index, style, data }) => (
        <div style={style}>
          <Row
            index={index}
            data={data}
            setSize={setSize}
            rowBackground={rowBackgroundConfig}
            ListComponent={ListComponent}
          />
        </div>
      )}
    </VariableSizeList>
  );
};

export default VariableVList;

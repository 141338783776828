import React from 'react';
import { Box, Text } from 'grommet';
import { THEME_COLORS } from '../../config/theme';

/**
 * Displays a text that will be truncated with ellipsis (...) if too long
 * to fit in given width
 * if truncated a tooltip will also appear on hover to show full text
 * @param {string} message - the text to display
 * @param {string} width - the width of text container, example '400px'
 * @param {string} [textSize='small'] - the size of the message text
 * @param {string} [tipSize='small'] - the size of the message tooltip text
 * @param {string} [alignText='start'] - the message text align property
 * @param {string} textColor - the message text color
 * @param {number} [textWeight=500] - the message text weight
 * @returns {ReactFragment} - Truncated text component
 */
const TruncatedText = ({
  message,
  width,
  textSize = 'small',
  tipSize = 'small',
  alignText = 'start',
  textColor,
  textWeight = 500
}) => {
  return (
    <Box width={width}>
      <Text
        textAlign={alignText}
        color={textColor}
        weight={textWeight}
        size={textSize}
        truncate="tip"
        tip={{
          plain: true,
          dropProps: { align: { bottom: 'top' } },
          content: (
            <Box
              pad="xxsmall"
              elevation="small"
              background={THEME_COLORS.brand}
              round="xsmall"
              margin="xsmall"
              overflow="hidden"
              align="center"
            >
              <Text size={tipSize} color={THEME_COLORS.white}>
                {message}
              </Text>
            </Box>
          )
        }}
      >
        {message}
      </Text>
    </Box>
  );
};

export default TruncatedText;

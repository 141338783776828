import React from 'react';
import { faBuilding, faCircleUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box } from 'grommet';
import { CircledFontAwesomeIcon } from '../../components';
import { THEME_COLORS } from '../../config/theme';
import './AvatarWithPlaceholder.css';

/**
 * A placeholder for avatars
 * @param {Object} props Component props
 * @param {string} props.sizePx  The size to apply
 * @param {string} [props.isCompany]  This is an Avatar for a company
 * @returns {Component}
 */
export const AvatarPlaceholder = ({ sizePx, isCompany }) => {
  if (isCompany) {
    return <CircledFontAwesomeIcon icon={faBuilding} sizePx={sizePx} color={THEME_COLORS.brand} />;
  }
  return (
    <FontAwesomeIcon
      icon={faCircleUser}
      color={THEME_COLORS.brand}
      style={{
        width: sizePx,
        height: sizePx
      }}
    />
  );
};

const avatarDefaultStyle = {
  size: '45', // NO px unit on purpose, see below
  margin: '5px'
};
/**
 * Displays avatar if src url provided,
 * otherwise display a placeholder avatar except if noPlaceHolder prop set
 * used for both user avatars (practitioners) and company logos
 * @param {Object} [props] Component props (see details below)
 * @param {string} [props.avatarSrc] The avatar image url coming from db
 * @param {boolean} [props.isCompany] if true company placeholder avatar should be used if url not provided
 * @param {string} [props.sizePx] Size in pixel of the avatar or placeholder
 * @param {object} [props.avatarStyle={}] optional stylesheet to apply to Avatar component
 * @param {string} [props.avatarMargin=''] Logo image specific margin
 * @param {boolean} [props.noPlaceHolder=false] if true no avatar placeholder is displayed
 * @returns {React.Component} JSX component
 */
const AvatarWithPlaceholder = ({
  avatarSrc,
  isCompany,
  sizePx,
  avatarStyle = {},
  avatarMargin = '',
  noPlaceHolder = false
}) => {
  const size = `${parseInt(sizePx ? sizePx : avatarDefaultStyle.size, 10)}px`;
  const avatarClass = `profileImage${sizePx ? sizePx : avatarDefaultStyle.size}`;
  return (
    <Box margin={avatarMargin ? avatarMargin : avatarDefaultStyle.margin}>
      {avatarSrc ? (
        <Avatar className={`avatarImage ${avatarClass}`} size={size} src={avatarSrc} style={avatarStyle} />
      ) : (
        <>{noPlaceHolder ? '' : <AvatarPlaceholder isCompany={isCompany} sizePx={size} />}</>
      )}
    </Box>
  );
};

export default AvatarWithPlaceholder;

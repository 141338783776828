import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Anchor, Box, Button, Grommet, ResponsiveContext, Text } from 'grommet';
import illustrationServiceUnavailable from '../../assets/images/illustration-service-unavailable.png';
import { theme, THEME_COLORS } from '../../config/theme';
import Layout, { FormHead } from '../../features/auth/LayoutAuth';
import { getExtendedHelpUrls } from '../../utility/helpMenuUtils';

const AppError = () => {
  const { t } = useTranslation();
  const { helpSupportUrl } = getExtendedHelpUrls();
  const size = useContext(ResponsiveContext);

  return (
    <Grommet theme={theme} fill="horizontal">
      <Layout>
        <FormHead showCatchPhrase />
        <Box margin="medium" align="center" pad={size === 'small' ? 'medium' : ''}>
          <LazyLoadImage
            alt={t('page.network.error.title')}
            src={illustrationServiceUnavailable}
            width={size === 'small' ? '75%' : '50%'}
          />
        </Box>
        <Text size={size === 'small' ? 'large' : 'xlarge'} color={THEME_COLORS.danger} weight={600}>
          {t('page.app.error.title')}
        </Text>

        <Text textAlign="center" size="medium" color={THEME_COLORS.text} margin="medium">
          <Trans i18nKey="page.app.error.message" t={t} components={{ br: <br /> }} />
        </Text>
        <Button
          size="large"
          bold
          primary
          label={t('component.helpLink.contact.us')}
          onClick={() => window.open(helpSupportUrl, '_self')}
          cursor="pointer"
        />
        <Anchor href="/signin?reload" size="xsmall" margin={{ top: 'small' }}>
          {t('common.button.connect')}
        </Anchor>
      </Layout>
    </Grommet>
  );
};

export default AppError;

import { useTranslation } from 'react-i18next';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { specialtyGeneratesRevenue } from '../../../features/specialties/specialtiesUtils';
import {
  getEventCommission,
  getEventPractitioner,
  getPractitionerName,
  getPractitionerTitleLabel
} from '../../../utility/practitionerUtils';
import ItemsTable from './ItemsTable';

const styles = StyleSheet.create({
  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  image: {
    width: '40%'
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 18,
    marginTop: 12
  },
  errorTitle: {
    fontSize: 18,
    marginTop: 12,
    color: 'red',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 14,
    marginTop: 12
  },
  cardSubtitle: {
    fontSize: 14,
    marginBottom: 12
  },
  text: {
    fontSize: 14
  },
  reasonText: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10
  },
  card: {
    border: '2 solid #000',
    marginTop: 12,
    marginBottom: 12,
    padding: 5
  }
});

const ErrorDocument = ({ reason }) => {
  const { t } = useTranslation();
  const { body, imageContainer, image, errorTitle, reasonText } = styles;
  return (
    <Document>
      <Page style={body} wrap>
        <View style={imageContainer}>
          <Image style={image} src="/Viabeez_pdf.jpg" />
        </View>
        <Text style={errorTitle}>{t('pdf.events.analytics.error.title')}</Text>
        <Text style={reasonText}>{reason}</Text>
      </Page>
    </Document>
  );
};

const AnalyticsDocument = ({ tableData, event, achievedAppointmentsCount, totalRevenue }) => {
  const { t } = useTranslation();
  if (!event) {
    console.warn(`NO event data!`);
    return <ErrorDocument reason="missing event data" />;
  }
  const { email, phone } = getEventPractitioner(event);
  const { specialty, dateStart, company, practitioner } = event;

  if (!practitioner) {
    console.warn(`Event ${event?._id} has no practitioner data!`);
    return <ErrorDocument reason="missing practitioner data" />;
  }

  const {
    name,
    address: { street, city, zipcode }
  } = company;
  const { label } = specialty;
  const practitionerName = getPractitionerName(event);
  const eventCommission = getEventCommission(event);
  const eventHasRevenueInfo = specialtyGeneratesRevenue(specialty);
  const { body, imageContainer, image, title, subtitle, text, card, cardSubtitle } = styles;

  return (
    <Document>
      <Page style={body} wrap>
        <View style={imageContainer}>
          <Image style={image} src="/Viabeez_pdf.jpg" />
        </View>
        <Text style={title}>
          {t('pdf.events.analytics.edit.title', { editDate: moment().tz('UTC').format('DD/MM/YYYY') })}
        </Text>
        <Text style={subtitle}>
          {t('pdf.events.analytics.event.date', {
            eventSpecialty: label,
            eventDate: moment(dateStart).tz('UTC').format('DD/MM/YYYY')
          })}
        </Text>
        <Text style={subtitle}>{t('pdf.events.analytics.company.title')}</Text>
        <Text style={text}>{name}</Text>
        <Text style={text}>
          {street}, {zipcode} {city}
        </Text>
        <View>
          <Text style={subtitle}>{t('pdf.events.analytics.pro.title')}</Text>
          <Text style={text}>{practitionerName}</Text>
          <Text style={text}>{getPractitionerTitleLabel(practitioner?.specialty)}</Text>
          <Text style={text}>
            {email ? email : `${t('common.label.short.email')} : ${t('common.not.provided.male.label')}`}
          </Text>
          <Text style={text}>
            {phone ? phone : `${t('common.label.short.phone')} : ${t('common.not.provided.male.label')}`}
          </Text>
        </View>
        <View style={card}>
          <Text style={cardSubtitle}>{t('pdf.events.analytics.revenue.title')}</Text>
          <Text style={text}>{t('component.event.card.eventStatus.done.verbose', { achievedAppointmentsCount })}</Text>
          {eventHasRevenueInfo && (
            <>
              <Text style={text}>{t('component.event.card.total.revenue.verbose', { totalRevenue })}</Text>
              <Text style={text}>
                {t('event.commission.applicable')}
                {eventCommission !== '' ? ` ${eventCommission}%` : t('common.not.defined.male.label')}
              </Text>
            </>
          )}
        </View>
        {tableData.length > 0 && <ItemsTable tableData={tableData} eventHasRevenueInfo={eventHasRevenueInfo} />}
      </Page>
    </Document>
  );
};

export default AnalyticsDocument;

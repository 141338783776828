import React from 'react';
import { Box } from 'grommet';

/**
 * @deprecated Use RoundedButton instead
 */
const RoundedLogoButton = (props) => {
  return (
    <Box
      round
      background="accent"
      pad="xsmall"
      align="center"
      justify="center"
      focusIndicator
      style={{ cursor: 'pointer' }}
      {...props}
    >
      {props.icon}
    </Box>
  );
};

export default RoundedLogoButton;

import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24
  }
});

const ItemsTable = ({ tableData, eventHasRevenueInfo }) => (
  <View style={styles.tableContainer}>
    <TableHeader eventHasRevenueInfo={eventHasRevenueInfo} />
    {tableData.map((data) => (
      <TableRow key={data.time} data={data} eventHasRevenueInfo={eventHasRevenueInfo} />
    ))}
  </View>
);

export default ItemsTable;

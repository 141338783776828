import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, CheckBox, Text } from 'grommet';
import { VIABEEZ_CONSENT_NOTICE_URL, VIABEEZ_TERMS_URL } from 'src/constants';

/**
 * opens the given url in a new browser window
 * @function
 * @param {string} url the url of document to open in new window
 * @param {object} evt the link click event object
 */
const openLink = (evt, url) => {
  if (evt) {
    // prevent click from also applying toggle to checkbox
    evt.preventDefault();
  }
  if (url) {
    window.open(url, 'ViabeezConsent', 'width=500,height=700,resizable,scrollbars=yes,status=1');
  } else {
    console.log('openLink error: no url provided');
  }
};

/**
 * @description Implements User consent UI Component
 * @description forces user to accept Viabeez Terms of use + grant personal data access
 * @description in order to access and use the platform
 * @param {Object} props - component properties
 * @property {function} props.setUserOk - callback function to set boolean state whether or not user accepts Terms
 * @property {function} [props.disabled=false] - if true the form is disabled
 */
const UserConsent = ({ setUserOk, disabled = false }) => {
  const { t } = useTranslation();
  const [userTermsAccept, setUserTermsAccept] = useState(false);
  const [userConsentAccept, setUserConsentAccept] = useState(false);

  useEffect(() => {
    setUserOk(userConsentAccept && userTermsAccept);
  }, [setUserOk, userTermsAccept, userConsentAccept]);

  return (
    <Box>
      <CheckBox
        disabled={disabled}
        pad={{ top: 'small', bottom: 'small' }}
        checked={userTermsAccept}
        label={
          <Text size="xsmall">
            {t('page.user.consent.terms.accept1')}{' '}
            <Anchor onClick={(evt) => openLink(evt, VIABEEZ_TERMS_URL)}>{t('page.user.consent.terms.cgu')}</Anchor>{' '}
            {t('page.user.consent.terms.accept2')}
          </Text>
        }
        onChange={(event) => setUserTermsAccept(event.target.checked)}
      />
      <CheckBox
        disabled={disabled}
        pad={{ bottom: 'small' }}
        checked={userConsentAccept}
        label={
          <Text size="xsmall">
            {t('page.user.consent.consent.accept')}{' '}
            <Anchor onClick={(evt) => openLink(evt, VIABEEZ_CONSENT_NOTICE_URL)}>
              {t('page.user.consent.terms.consent.notice')}
            </Anchor>
            {'.'}
          </Text>
        }
        onChange={(event) => setUserConsentAccept(event.target.checked)}
      />
    </Box>
  );
};

export default UserConsent;

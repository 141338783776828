import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { Anchor, Box, Text } from 'grommet';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import EventWaitingListInfo from '../../features/events/EventWaitingListInfo';
import { eventWaitingListSize, getAppointmentsAndAvailableSlots } from '../../utility/eventUtils';

const circularProgressStyles = buildStyles({
  rotation: 1 / 2 + 1 / 8,
  strokeLinecap: 'butt',
  trailColor: 'brand',
  textSize: '34px'
});
circularProgressStyles.text.fontWeight = 'bold';

/**
 * Renders event appointments stats with
 * X = # of employees who booked an appointment
 * Y = # of appointment time slots available
 * Format is the following:
 * if X and Y = 0 => "Aucune inscription" as red text
 * if X = Y => "COMPLET (Y créneau(x))"
 * otherwise X inscrit(s) / Y créneau(x)
 * display of waiting list size if:
 *  - event is full
 *  - AND property showWaitingList set to true
 * displays a link to show event planning modal if onClickShowPlanning prop is not null
 * @component
 * @param {Object} props Component props
 * @param {Object} props.event The event to get actual practitioner info
 * @param {boolean} [props.hasBookingText] Display the appointment counts as text
 * @param {boolean} [props.hasBookingMeter] Display the appointment counts as circular meter
 * @param {boolean} [props.showWaitingList=false] if true display event waiting list size
 * @param {function|null} [props.onClickShowPlanning=null] callback when requested to show event planning
 * @returns {Component} the event appointments stats component to render
 */
const AppointmentsCount = ({
  event,
  hasBookingText,
  hasBookingMeter,
  showWaitingList = false,
  onClickShowPlanning = null,
  ...restProps
}) => {
  const { t } = useTranslation();
  const textSize = '16px';
  const { nbSlots, nbAppointments } = getAppointmentsAndAvailableSlots(event);
  const value = nbSlots ? Math.round((nbAppointments / nbSlots) * 100) : 0; // Division by 0 if nbSlots===0

  const ShowPlanningLink = () => (
    <Box>
      {!!onClickShowPlanning && (
        <Anchor onClick={() => onClickShowPlanning(event)} style={{ textAlign: 'center' }}>
          <Text color="brand" size="xsmall">
            {t('event.show.planning.label')}
          </Text>
        </Anchor>
      )}
    </Box>
  );

  if (value <= 0) {
    return (
      <Box pad="small" align="center" justify="center" style={{ minHeight: '75px' }} {...restProps}>
        <Text weight={700} size={textSize} color="status-critical" textAlign="center">
          {t('component.appointmentsCount.no.registration')}
        </Text>
      </Box>
    );
  }

  if (value >= 100) {
    return (
      <Box pad="small" direction="column" align="center" justify="center" style={{ minHeight: '75px' }} {...restProps}>
        <Text size={textSize} weight={600} color="status-critical">
          {t('component.appointmentsCount.full')}
        </Text>
        <Text size="small">{t('component.appointmentsCount.slot', { count: nbSlots })}</Text>
        <ShowPlanningLink />
        {showWaitingList && (
          <EventWaitingListInfo
            size={eventWaitingListSize(event)}
            textColor="#dd2e44"
            textSize="12px"
            textWeight={700}
          />
        )}
      </Box>
    );
  }

  return (
    <>
      <Box
        pad={{ horizontal: 'small', top: 'small', bottom: 'none' }}
        align="center"
        style={{ display: 'grid', grid: 'auto / 80px 60px' }}
        {...restProps}
      >
        {hasBookingText && (
          // Adding \u00a0 (non-breaking space) for better readability
          <Text size="small" margin={{ horizontal: '4px' }}>
            {t('component.appointmentsCount.registered', { count: nbAppointments })}
            {' /'}
            <br />
            {t('component.appointmentsCount.slot', { count: nbSlots })}
          </Text>
        )}
        {hasBookingMeter && (
          <Box style={{ width: '50px', height: '50px', justifySelf: 'end' }} margin={{ horizontal: '4px' }}>
            <CircularProgressbar
              value={value}
              text={`${value}%`}
              circleRatio={0.75}
              strokeWidth={10}
              styles={circularProgressStyles}
            />
          </Box>
        )}
      </Box>
      <ShowPlanningLink />
    </>
  );
};

export default AppointmentsCount;

import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, ResponsiveContext, Select, Text } from 'grommet';
import moment from 'moment-timezone';
import { DateInput, Tabs } from '..';
import { getCompanySelectOptions } from '../../features/specialties/specialtiesUtils';

/**
 * filters events list by provided criteria (date and/or specialty)
 * @param {object[]} events - list of events
 * @param {string} [dateFilter=''] - the event date to search for if defined or ""
 * @param {string} [specialtyFilter=''] - the event specialty to search for if defined or ""
 * @returns {object[]|[]} list of events matching criteria or all events if no criteria provided
 */
export const filterEvents = (events, dateFilter = '', specialtyFilter = '') => {
  if (!events) {
    return [];
  }
  if (!dateFilter && !specialtyFilter) {
    return events;
  }
  return events.filter((event) => {
    let match = true;
    if (dateFilter) {
      match = dateFilter === moment(event.dateStart).format('YYYY-MM-DD');
    }
    if (match && specialtyFilter) {
      match = specialtyFilter === event?.specialty?._id;
    }
    return match;
  });
};

const ALL_JDS_KEY = '';

/**
 * Component rendering the events selection and filtering bar
 * used for both practitioners & company admins
 * ability to filter events by date or type (specialty) for company admin
 * only date filtering for practitioners
 * @param {object} props - component properties
 * @param {number} props.index - index of currently selected tab
 * @param {function} props.setIndex - function to set currently selected tab
 * @param {object[]} props.tabs - JDS tabs definition
 * @param {function} props.setDateFilter - function to set event date filter
 * @param {string} props.dateFilter - event date filter value ('' if not defined)
 * @param {string} props.specialtyFilter - event specialty filter value ('' if not defined which means all)
 * @param {function} props.setSpecialtyFilter - function to set event specialty filter value
 * @param {Object} props.company - company of current user
 * @returns {React.Component} the Event Tab bar component
 */
const EventTabBar = ({
  index,
  setIndex,
  tabs,
  setDateFilter,
  dateFilter,
  specialtyFilter = ALL_JDS_KEY,
  setSpecialtyFilter = null,
  company
}) => {
  const { t } = useTranslation();
  const specialties = useSelector((state) => state?.specialties?.list);
  const specialtyGroups = useSelector((state) => state?.specialties?.groups);

  const responsiveSize = useContext(ResponsiveContext);

  const options = useMemo(() => {
    if (specialties && specialtyGroups) {
      return [
        { key: ALL_JDS_KEY, label: t('common.label.all.events') },
        ...getCompanySelectOptions(company?.specialtyGroups)
      ];
    }
    return [{ key: ALL_JDS_KEY, label: t('common.label.all.events') }];
  }, [company, t, specialties, specialtyGroups]);

  return (
    <Box direction="row" align="center">
      <Box>
        <Tabs tabs={tabs} index={index} setIndex={setIndex} fontSize={responsiveSize === 'small' ? '12px' : '16px'} />
      </Box>
      <Box margin={{ left: 'small' }} width="150px" elevation="small" round="4px">
        <DateInput
          value={dateFilter}
          size="15px"
          placeholder={
            <Text size={responsiveSize === 'small' ? '12px' : '15px'} weight={800}>
              {t('event.date.filter.label')}
            </Text>
          }
          onSelectDate={setDateFilter}
        />
      </Box>
      {setSpecialtyFilter && (
        <Box margin="small" elevation="small" round="4px">
          <Select
            size="15px"
            style={{ width: '150px' }}
            labelKey="label"
            valueKey={{ key: 'key', reduce: true }}
            value={specialtyFilter}
            options={options || []}
            onChange={({ option }) => setSpecialtyFilter(option.key)}
          />
        </Box>
      )}
    </Box>
  );
};

export default EventTabBar;

import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Box, Image, ResponsiveContext, Text } from 'grommet';
import { t } from 'i18next';
import { THEME_COLORS } from '../../config/theme';

/**
 * @description Display illustration and localisation content
 * @component
 * @property {String} illustrationWidth The image width
 * @property {String} illustrationSrc The URL of the image
 * @property {String} localisation Text content to be displayed
 */
const ShowImageAndTextHighlight = ({ illustrationWidth, illustrationSrc, localisation }) => {
  const responsiveSize = useContext(ResponsiveContext);
  const highlightBox = <Box pad="5px" align="center" margin={{ horizontal: 'medium', top: 'small' }}></Box>;
  const highlightText = (
    <Text
      size={responsiveSize === 'small' ? '18px' : '20px'}
      weight="bold"
      color={THEME_COLORS.title}
      textAlign="center"
      style={{ backgroundColor: THEME_COLORS.highlight }}
    ></Text>
  );
  const title = (
    <Text size={responsiveSize === 'small' ? '15px' : 'medium'} color={THEME_COLORS.title} textAlign="center"></Text>
  );
  const subTitle = <Text size="18px" color={THEME_COLORS.brand} textAlign="center"></Text>;
  const infoText = <Text size="medium" color={THEME_COLORS.text} margin={{ top: 'medium' }} textAlign="center"></Text>;
  return (
    <Box align="center">
      <Image width={illustrationWidth} src={illustrationSrc} />

      <Box align="center" width={responsiveSize === 'small' ? '100%' : '75%'}>
        <Trans
          i18nKey={localisation}
          t={t}
          components={{
            br: <br />,
            HighlightBox: highlightBox,
            HighlightText: highlightText,
            InfoText: infoText,
            SubTitle: subTitle,
            Title: title
          }}
        />
      </Box>
    </Box>
  );
};

export default ShowImageAndTextHighlight;

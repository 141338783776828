import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from 'grommet';
import Modal from './Modal';

/**
 * A generic action modal
 * @param {Object} [props] Component props (see details below)
 * @param {boolean} props.showModal The visibility of the modal
 * @param {Function} [props.onHideModal] callback triggered when user clicks outside of the modal or want to close it
 * @param {Object} [props.feedback] Optional feedback
 * @param {string} [props.feedback.message] Feedback message
 * @param {'error' | 'success'} [props.feedback.type] Feedback type, can be error or success
 * @param {string} [props.title] The modal's title
 * @param {string} [props.body] The modal's body text (!!! TEXT ONLY - use children for custom content)
 * @param {boolean} [props.noConfirmButton] Do not use confirm button
 * @param {string} [props.confirmLabel] The confirm button's label
 * @param {Function} [props.onConfirm] Callback triggered when user confirms operation
 * @param {boolean} [props.noCancelButton] Do not use cancel button
 * @param {string} [props.cancelLabel] The cancel button's label
 * @param {Function} [props.onCancel] Callback triggered when user cancels operation
 * @param {string} [props.modalWidth= 'medium'] Modal width
 * @param {string} [props.cancelButtonWidth = null] if defined cancel button width
 * @param {string} [props.confirmButtonWidth = null] if defined confirm button width
 * @param {boolean} [props.autoHideOnConfirm] if true modal hides on confirm action
 * @param {boolean} [props.autoHideOnCancel] if true modal hides on cancel action
 * @param {Component} [props.children] Modal children components
 * @param {string|object} [props.modalPadding = 'small'] Modal content padding
 * @param {string} [props.titleSize = 'large'] Modal title text size
 * @param {string|object} [props.bodyMargin= 'small'] Modal body margin
 * @returns {React.FC} Modal component
 */
const ActionModal = ({
  showModal,
  onHideModal,
  feedback,
  title,
  body,
  noConfirmButton,
  confirmLabel,
  onConfirm,
  noCancelButton,
  cancelLabel,
  onCancel,
  modalWidth = 'medium',
  cancelButtonWidth = null,
  confirmButtonWidth = null,
  autoHideOnConfirm,
  autoHideOnCancel,
  children,
  modalPadding = 'small',
  bodyMargin = 'small',
  titleSize = 'large'
}) => {
  const { t } = useTranslation();
  const handleConfirmClick = () => {
    if (onConfirm && typeof onConfirm === 'function') {
      if (autoHideOnConfirm) {
        onHideModal();
      }
      onConfirm();
    } else {
      onHideModal();
    }
  };

  const handleCancelClick = () => {
    if (onCancel && typeof onCancel === 'function') {
      if (autoHideOnCancel) {
        onHideModal();
      }
      onCancel();
    } else {
      onHideModal();
    }
  };
  return (
    <Modal show={showModal} setShow={onHideModal} flex={false}>
      <Box pad={modalPadding} align="center" justify="center" width={modalWidth}>
        {title && (
          <Box fill align="center" justify="center" background="brand" pad="small">
            <Text size={titleSize} weight="bold" color="white" margin={{ bottom: 'small' }} textAlign="center">
              {title}
            </Text>
          </Box>
        )}
        {body && (
          <Box margin={bodyMargin} align="center" justify="center">
            <Text textAlign="center">{body}</Text>
          </Box>
        )}
        {feedback && (
          <Box>
            <Text size="small" color={feedback.type === 'success' ? 'status-ok' : 'status-error'} textAlign="center">
              {feedback.message}
            </Text>
          </Box>
        )}
        {children}
        <Box direction="row" gap="small" margin={{ vertical: 'small' }}>
          {!noCancelButton && (
            <Box width={cancelButtonWidth ? cancelButtonWidth : ''}>
              <Button label={cancelLabel || t('common.button.cancel')} onClick={handleCancelClick} />
            </Box>
          )}
          {!noConfirmButton && (
            <Box width={confirmButtonWidth ? confirmButtonWidth : ''}>
              <Button primary label={confirmLabel || t('common.label.confirm')} onClick={handleConfirmClick} />
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ActionModal;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleCheck, faCircleExclamation, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from 'grommet';
import { ActionModal } from '../../components';
import { THEME_COLORS } from '../../config/theme';
import { sendEventReminderToUnsubscribed } from '../../utility/api';

const SendEventReminderModal = ({ event, onCancel, onComplete }) => {
  const { t } = useTranslation();
  const { _id: eventId, lastReminderDate = '' } = event;
  const EmailReminderBody = () => {
    let reminderDateInfo = t('no.reminder.label');
    if (lastReminderDate) {
      const reminderDate = new Date(lastReminderDate);
      reminderDateInfo = `${t('last.reminder.date.label')} : ${reminderDate.toLocaleString()}`;
    }
    return (
      <>
        <Text size="small" weight={700}>
          {reminderDateInfo}
        </Text>
        <br />
        <br />
        {t('page.events.modal.sendReminder.body')}
      </>
    );
  };

  const handleConfirm = useCallback(() => {
    sendEventReminderToUnsubscribed(eventId)
      .then((ret) => {
        const { status, delay = '?' } = ret;
        if (status === 'success') {
          onComplete({
            title: t('page.events.sendReminder.feedback.success.title'),
            icon: <FontAwesomeIcon icon={faCircleCheck} color={THEME_COLORS['status-ok']} size="2x" />,
            status: 'normal',
            message: t('page.events.sendReminder.feedback.success.message')
          });
        } else {
          // if not success for now can only be too early unauthorized request error
          onComplete({
            icon: <FontAwesomeIcon icon={faTriangleExclamation} color={THEME_COLORS.orange} size="2x" />,
            title: t('page.events.sendReminder.feedback.error.title'),
            status: 'warning',
            message: t('page.events.sendReminder.feedback.unauthorized.message', { days: delay })
          });
        }
      })
      .catch((error) => {
        console.error('SendEventReminderModal/sendEventReminderToUnsubscribed | ', error);
        onComplete({
          icon: <FontAwesomeIcon icon={faCircleExclamation} color={THEME_COLORS['status-error']} size="2x" />,
          title: t('page.events.sendReminder.feedback.error.title'),
          status: 'critical',
          message: t('page.events.sendReminder.feedback.error.message')
        });
      });
  }, [eventId, onComplete, t]);

  return (
    <ActionModal
      showModal={!!eventId}
      onHideModal={onCancel}
      title={t('page.events.modal.sendReminder.title')}
      body={<EmailReminderBody />}
      cancelLabel={t('common.button.cancel')}
      confirmLabel={t('common.button.send')}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    />
  );
};

export default SendEventReminderModal;

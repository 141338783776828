import React from 'react';
import { Text } from 'grommet';

const P = (props) => {
  if (props.level === 'h1') {
    return (
      <Text weight={props.weight ? props.weight : 700} color={props.color ? props.color : 'brand'} as="h1" {...props}>
        {props.children}
      </Text>
    );
  } else if (props.level === 'h2') {
    return (
      <Text weight={props.weight ? props.weight : 700} color={props.color ? props.color : 'title'} as="h2" {...props}>
        {props.children}
      </Text>
    );
  } else if (props.level === 'h3') {
    return (
      <Text weight={props.weight ? props.weight : 600} color={props.color ? props.color : 'title'} as="h3" {...props}>
        {props.children}
      </Text>
    );
  } else {
    return (
      <Text weight={props.weight ? props.weight : 400} color={props.color ? props.color : 'text'} {...props}>
        {props.children}
      </Text>
    );
  }
};

export default P;

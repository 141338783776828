import React from 'react';
import { faCircleCheck, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import { Modal, PrimaryButton, T } from '../../components';
import { THEME_COLORS } from '../../config/theme';

/**
 * @description Implements a status modal dialog
 * based on feedback object that contains props:
 *  - status: either "success" or "error"
 *  - message: the status message
 * @component
 * @property {boolean} show if true dialog must show
 * @property {function} setShow function to call to toggle dialog display status
 * @property {object} feedback The status object
 * @property {String[]} title An array providing dialog title for success & error cases
 *  title[0] = success title, title[1] = error title
 * @property {String} [actionText="Fermer"] the label of the action button, defaults to "Fermer"
 * @property {callback} [onSuccess=null] callback triggered when status Ok and user clicks button
 * @property {callback} [onError=null] callback triggered when status Error and user clicks button
 */
const StatusModal = ({ show, setShow, feedback, title, actionText = 'Fermer', onSuccess = null, onError = null }) => {
  return (
    <Modal show={show} setShow={setShow}>
      <Box
        pad={{ vertical: 'medium', horizontal: 'small' }}
        align="center"
        justify="center"
        width="medium"
        gap="medium"
      >
        <Box direction="row" gap="medium">
          {feedback.status === 'error' ? (
            <FontAwesomeIcon icon={faTriangleExclamation} color={THEME_COLORS.red} size="3x" />
          ) : (
            <FontAwesomeIcon icon={faCircleCheck} color={THEME_COLORS.text} size="3x" />
          )}
          <T level="h2" color="title">
            {feedback.status === 'error' ? title[1] : title[0]}
          </T>
        </Box>
        <Text
          size="small"
          color={feedback.status === 'error' ? 'status-critical' : 'brand'}
          textAlign="center"
          style={{ whiteSpace: 'pre-line' }}
        >
          {feedback.message}
        </Text>
        <PrimaryButton
          margin={{ top: 'medium' }}
          label={actionText}
          onClick={() => {
            setShow(false);
            if (feedback.status === 'success') {
              if (onSuccess) {
                onSuccess();
              }
            } else {
              if (onError) {
                onError();
              }
            }
          }}
        />
      </Box>
    </Modal>
  );
};

export default StatusModal;

import React from 'react';
import { Box } from 'grommet';
import { ClipboardCopy } from 'src/components';
import styled from 'styled-components';

const CodeValue = styled.code`
  color: #000000;
  font-size: 12px;
  background: #ffeff0;
  word-wrap: break-word;
  padding: 0.1rem 0.3rem 0.2rem;
  border-radius: 0.2rem;
`;

const DatabaseId = ({ value, iconTipId, copyTipId }) => (
  <Box direction="row" gap="xsmall">
    <CodeValue>{value}</CodeValue>
    <ClipboardCopy buffer={value} iconTipId={iconTipId} copyTipId={copyTipId} />
  </Box>
);

export default DatabaseId;

import React from 'react';
import { Box } from 'grommet';

const CardButton = (props) => {
  return (
    <>
      <Box
        fill="horizontal"
        border={{
          side: 'top',
          size: '0.25px',
          color: '#EEEEEE'
        }}
      ></Box>
      <Box fill="horizontal" justify="center" align="center" gap="small" focusIndicator {...props}>
        {props.children}
      </Box>
    </>
  );
};

export default CardButton;

// @ts-check

import { VIABEEZ_TERMS_URL } from '../constants';

/**
 * @typedef {string} DeployEnvTypes
 */

/**
 * @typedef {Object} AppConfig
 * @property {DeployEnvTypes} deployEnv The deployment environment
 * @property {string} portalUrl The viabeez portal URL
 * @property {string} legalUrl The viabeez legal  URL
 * @property {string} helpFaqUrl The viabeez FAQ  URL
 * @property {string} helpSupportUrl The contact  URL
 * @property {string} backendUrl The backend server URL
 * @property {number} requestTimeout The timeout value for axios requests
 * @property {number} eventCreationDelay The minimum number of DAYS delay when creating  date
 * @property {number} eventUpdateDelay The minimum number of DAYS delay when updating date (future)
 */

/**
 * @typedef {Object} DeployEnvConfig
 * @property {number} eventCreationDelay The minimum number of DAYS delay when creating  date
 * @property {number} eventUpdateDelay The minimum number of DAYS delay when updating date (future)
 * @property {string} portalUrl The viabeez portal URL
 * @property {string} helpFaqUrl The viabeez FAQ  URL
 * @property {string} helpSupportUrl The contact  URL
 * @property {string} legalUrl The viabeez legal  URL
 */

/**
 * @typedef {Object} EnvVarConfig
 * @property {string} deployEnv The deploy env var
 * @property {string} backendUrl the backend url en var
 * @property {number} requestTimeout The timeout value for axios requests
 */

/**
 * @enum {DeployEnvTypes}
 */
export const DeployEnv = {
  Prod: 'prod',
  PP: 'pp',
  Demo: 'demo',
  Dev: 'dev'
};

const DEFAULT_EVENT_CREATION_DELAY = 30;
const DEFAULT_EVENT_UPDATE_DELAY = 15;
const PORTAL_URL_PROD = 'https://viabeez.com';
const PORTAL_URL_PP = 'https://dev.viabeez.com';
const PORTAL_URL_DEMO = 'https://staging.viabeez.com';

/**
 * Get config that depends on deploy env
 * @param {DeployEnv} deployEnv The deployment environment
 * @returns {DeployEnvConfig}
 */
function getConfigByEnv(deployEnv) {
  let portalUrl = '';
  let helpFaqUrl = '';
  let helpSupportUrl = '';
  const legalUrl = VIABEEZ_TERMS_URL;
  let eventCreationDelay = DEFAULT_EVENT_CREATION_DELAY;
  let eventUpdateDelay = DEFAULT_EVENT_UPDATE_DELAY;

  switch (deployEnv) {
    case DeployEnv.Dev:
      portalUrl = PORTAL_URL_PP;
      helpFaqUrl = `${PORTAL_URL_PP}/aide`;
      helpSupportUrl = `${PORTAL_URL_PP}/support`;
      eventCreationDelay = 3;
      eventUpdateDelay = 3;
      break;
    case DeployEnv.PP:
      portalUrl = PORTAL_URL_PP;
      helpFaqUrl = `${PORTAL_URL_PP}/aide`;
      helpSupportUrl = `${PORTAL_URL_PP}/support`;
      eventCreationDelay = 3;
      eventUpdateDelay = 3;
      break;
    case DeployEnv.Demo:
      portalUrl = PORTAL_URL_DEMO;
      helpFaqUrl = `${PORTAL_URL_DEMO}/aide`;
      helpSupportUrl = `${PORTAL_URL_DEMO}/support`;
      eventCreationDelay = 3;
      eventUpdateDelay = 3;
      break;
    case DeployEnv.Prod:
      portalUrl = PORTAL_URL_PROD;
      helpFaqUrl = `${PORTAL_URL_PROD}/aide`;
      helpSupportUrl = `${PORTAL_URL_PROD}/support`;
      break;
    default:
  }

  return {
    portalUrl,
    helpFaqUrl,
    helpSupportUrl,
    legalUrl,
    eventCreationDelay,
    eventUpdateDelay
  };
}

/** @type {AppConfig} */
let appConfig;

/**
 * Get a react process config value from process.env
 * @function
 * @param {string} key The key of the process env to retrieve
 * @param {boolean} canBeEmpty The env var can be empty , otherwise raises an exception
 * @returns {string|undefined}
 */
export function getReactProcessVar(key, canBeEmpty = false) {
  if (!key) {
    console.error(`getAppConfig.js/getReactProcessVar - Missing key argument`);
  }

  const envValue = process.env[key];
  if (!canBeEmpty && (envValue == null || envValue === '')) {
    console.error(`getAppConfig.js/getReactProcessVar - Missing environment variable for key=${key}`);
  }
  return envValue || '';
}

/**
 * Get config object from environment variables
 * @returns {EnvVarConfig}
 */
function getEnvVarConfig() {
  const deployEnv = getReactProcessVar('REACT_APP_DEPLOY_ENV');
  const backendUrl = getReactProcessVar('REACT_APP_BACKEND_URL');
  const requestTimeout = getReactProcessVar('REACT_APP_REQUEST_TIMEOUT', true);
  return {
    deployEnv,
    backendUrl,
    requestTimeout: requestTimeout ? parseInt(requestTimeout, 10) : undefined
  };
}

/**
 * Initialize the application configuration object
 * @function
 */
export function initAppConfig() {
  const { deployEnv, backendUrl, requestTimeout } = getEnvVarConfig();
  const { eventCreationDelay, eventUpdateDelay, portalUrl, helpFaqUrl, helpSupportUrl, legalUrl } =
    getConfigByEnv(deployEnv);

  appConfig = {
    deployEnv,
    portalUrl,
    helpFaqUrl,
    helpSupportUrl,
    legalUrl,
    backendUrl,
    requestTimeout,
    eventCreationDelay,
    eventUpdateDelay
  };

  console.info('appConfig.js/getAppConfig - Initialized App Config:', appConfig);
}

/**
 * Get the application configuration object
 * @function
 * @returns {AppConfig} The application configuration object
 */
export function getAppConfig() {
  if (!appConfig) {
    initAppConfig();
  }
  return appConfig;
}

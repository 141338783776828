/**
 * Sector form option record to be used by form Select
 * @typedef {Object} SectorFormOption
 * @property {string} key
 * @property {string} label
 */

import { isAfter } from 'date-fns';
import { t } from 'i18next';
import { entries, upperCase } from 'lodash';
import { getCompanyStatistics } from '../utility/api';
import { isAccountsManagedCompany } from '../utility/permissions';

/**
 * Enum of company sectors
 */
const CompanySector = {
  industry: 'industry',
  corporateServices: 'corporateServices',
  constructionCraft: 'constructionCraft',
  humanServices: 'humanServices',
  foodSector: 'foodSector',
  energyEnvironment: 'energyEnvironment',
  chemistryPlastics: 'chemistryPlastics',
  bankInsurance: 'bankInsurance',
  publicAdministration: 'publicAdministration',
  digital: 'digital',
  medias: 'medias',
  transport: 'transport',
  medicoSocial: 'medicoSocial',
  nonFoodTrade: 'nonFoodTrade',
  other: 'other'
};
Object.freeze(CompanySector);

const companySectorData = {
  [CompanySector.industry]: {
    label: 'Industrie'
  },
  [CompanySector.corporateServices]: {
    label: 'Services aux entreprises'
  },
  [CompanySector.constructionCraft]: {
    label: 'BTP, construction'
  },
  [CompanySector.humanServices]: {
    label: 'Services à la personne'
  },
  [CompanySector.foodSector]: {
    label: 'Filières alimentaires'
  },
  [CompanySector.energyEnvironment]: {
    label: 'Energie, environnement'
  },
  [CompanySector.chemistryPlastics]: {
    label: 'Chimie, plasturgie'
  },
  [CompanySector.bankInsurance]: {
    label: 'Banque, assurance'
  },
  [CompanySector.publicAdministration]: {
    label: 'Administration publique'
  },
  [CompanySector.digital]: {
    label: 'Entreprise du digital'
  },
  [CompanySector.medias]: {
    label: 'Médias'
  },
  [CompanySector.transport]: {
    label: 'Transport, logistique'
  },
  [CompanySector.medicoSocial]: {
    label: 'Médico-social'
  },
  [CompanySector.nonFoodTrade]: {
    label: 'Commerce non-alimentaire'
  },
  [CompanySector.other]: {
    label: 'Autre'
  }
};
Object.freeze(companySectorData);

/**
 * Get the matching sector
 * @param {string} sectorKey A sector key
 * @returns the matching sector
 */
export const getCompanySector = (sectorKey) => {
  return CompanySector[sectorKey];
};

/**
 * Get the list of all sectors form options to be used by form Select
 * Always put 'other' at the end
 * @returns {SectorFormOption[]} List of all sectors form option
 */
export const getCompanySectorFormOptions = () => {
  return entries(companySectorData)
    .sort(([sectorKey1, sectorData1], [, sectorData2]) => {
      if (sectorKey1 === CompanySector.other) {
        // Always 'other' at the end
        return 1;
      }
      return sectorData1 && sectorData1.label > sectorData2.label ? 1 : -1;
    })
    .reduce((accu, [sectorKey, sectorData]) => {
      accu.push({ key: sectorKey, label: sectorData.label });
      return accu;
    }, []);
};

/**
 * Get the sector label for the provided sector key
 * @param {string} sectorKey A sector key
 * @returns {string} The label of the matching sector
 */
const getCompanySectorData = (sectorKey) => {
  const sector = CompanySector[sectorKey];
  if (sector) {
    return companySectorData[sector];
  }
};

/**
 * Get the sector for the provided sector key
 * @param {string} sectorKey A sector key
 * @returns {string} The label of the matching sector
 */
export const getCompanySectorLabel = (sectorKey) => {
  const sectorData = getCompanySectorData(sectorKey);
  return sectorData?.label || sectorKey || '';
};

/**
 * builds the url for beneficiary account creation by company code
 * @param {string} [code=''] - company beneficiary account creation code
 * @returns {string} url to create a company beneficiary account from code
 */
export const getCompanyAccountCreationByCodeLink = (code = '') => `${window.location.origin}/inscription?code=${code}`;

// Minimum number of validated beneficiaries to allow for full range of event duration values
// if count is below Minimum, max event duration is defined by EVENT_MIN_DURATION_HOURS constant
const NO_RESTRICTION_MIN_BENEFICIARIES = 40;

/**
 * Checks if company has a restriction on selectable event duration
 * companies with less than NO_RESTRICTION_MIN_BENEFICIARIES beneficiaries can't
 * schedule event lasting more than EVENT_MIN_DURATION_HOURS hours
 * @param {Object[]} beneficiaries - array of beneficiaries of the company to check
 * @returns {boolean} true if company has event duration restriction
 */
export const isEventDurationRestrictedCompany = async (company, beneficiaries = []) => {
  if (company) {
    let validEmployeesCount;
    if (isAccountsManagedCompany(company)) {
      const pendingEmployeesCount = beneficiaries.filter((beneficiary) => !beneficiary.isEmailValid).length;
      validEmployeesCount = beneficiaries.length - pendingEmployeesCount;
    } else {
      const { _id: companyId } = company;
      console.log(`isEventDurationRestrictedCompany: no beneficiary mgt permission for company: ${companyId}`);
      // company doesn't have beneficiary mgt option so no data about users
      // we need to fetch company stats instead
      console.log(`isEventDurationRestrictedCompany: Fetching company stats for company: ${companyId} ...`);
      const {
        data: {
          statistics: { activeBeneficiaryCounter = 0 }
        }
      } = await getCompanyStatistics(companyId);
      validEmployeesCount = activeBeneficiaryCounter;
    }
    return validEmployeesCount < NO_RESTRICTION_MIN_BENEFICIARIES;
  }
  console.error(`isEventDurationRestrictedCompany: no company data provided!`);
  return false;
};

/**
 * Checks if company has child site(s)
 * @param {Object} company - company to check
 * @returns {boolean} true if company has site(s)
 */
export const companyHasSites = (company) => {
  const { sites = [] } = company ?? {};
  return !!sites.length;
};

/**
 * !!! Today, company options property only contains healthStatusSelfAssessment property if set
 * all other company options are direct properties of company object
 * TODO: move all other company options inside options object
 * @param {Object} company
 * @returns {object}
 */
export const getCompanyOptions = (company) => {
  if (!company) {
    console.error('companyUtils.js::getCompanyOptions - Error : company is undefined');
    return {};
  }
  if (!company?.options) {
    console.log(`companyUtils.js::getCompanyOptions - No options property found for this company : ${company?._id}`);
    return {};
  }
  return company.options;
};

export const getCurrentCompanyCode = (company) => {
  const { codes = [] } = company ?? {};
  const currentCode = codes?.filter((code) => code.current);
  if (currentCode?.length) {
    return currentCode[0].value;
  }
  return upperCase(t('common.not.defined.male.label'));
};

/**
 * filters company event tokens to return only one that are available
 * ie not expired and not used yet
 * @param {object[]} eventTokens - company event tokens
 * @returns {object[]} available event tokens
 */
export const getAvailableEventTokens = (eventTokens) => {
  return eventTokens?.filter((token) => isAvailableEventToken(token)) ?? [];
};

/**
 * filters company event tokens to return only one that are marked as used (even if expired yet)
 * @param {object[]} eventTokens - company event tokens
 * @returns {object[]} used event tokens
 */
export const getUsedTokens = (eventTokens) => {
  return eventTokens?.filter((token) => isUsedEventToken(token)) ?? [];
};

/**
 * filters company event tokens to return only one that were NOT used and are expired now
 * @param {object[]} eventTokens - company event tokens
 * @returns {object[]} unused expired event tokens
 */
export const getExpiredTokens = (eventTokens) => {
  return eventTokens?.filter((token) => isExpiredEventToken(token)) ?? [];
};

// util function testing event token state
export const isUsedEventToken = (token) => !!token?.used;
export const isExpiredEventToken = (token) => !token?.used && isAfter(new Date(), token.expiry);
export const isAvailableEventToken = (token) => !token?.used && !isAfter(new Date(), token.expiry);

/**
 * checks if company admins are allowed to create an event
 * true if company configured with unlimited events flag or at least one available event token
 * @param {object} company - company to check
 * @returns {boolean} true if company admin can create an event
 */
export const canCreateEvent = (company) => {
  const { unlimitedEvents = false } = getCompanyOptions(company);
  return unlimitedEvents || getAvailableEventTokens(company?.eventTokens).length > 0;
};

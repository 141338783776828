import React, { useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Anchor, Box, ResponsiveContext, Text } from 'grommet';
import EventPractitioner from 'src/features/employeeDashboard/EventPractitioner';
import styled from 'styled-components';
import { ActionModal, CheckBox, EventTags, Separator, SlotTimeBoxContainer } from '../../components';
import { fetchAndRefreshEmployeeEvents } from '../../features/events/eventsSlice';
import { isPhoneSpecialty, isRemoteSpecialty } from '../../features/specialties/specialtiesUtils';
import SpecialtyDocumentLink from '../../features/specialties/SpecialtyDocumentLink';
import { toggleAvailableSlotSubscription } from '../../utility/api';
import { EventLocation, getAppointmentDuration, getAppointmentPrice, toDate } from '../Card/cardData';

const ShadowBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const CardFooter = ({ event }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state?.auth?.user);
  const [errorMessageKey, setErrorMessageKey] = useState('');
  const [checked, setChecked] = useState(
    !!event?.availableSlotSubscribers?.find((subscriberId) => currentUser._id === subscriberId)
  );

  const handleClickCheckbox = useCallback(
    (checkboxNewState) => {
      toggleAvailableSlotSubscription(event._id, checkboxNewState)
        .then((res) => {
          if (res.status === 'success') {
            setChecked(checkboxNewState);
          }
        })
        .catch((error) => {
          console.error('EmployeeEventCard.js/handleClickCheckbox | ', error);
          if (error.message === 'event_not_full') {
            setErrorMessageKey('beneficiary.available.event.card.subscribe.slots.update.error.not.full');
          }
        });
    },
    [event, setChecked]
  );

  const handleHideErrorModal = useCallback(() => {
    setErrorMessageKey('');
    dispatch(fetchAndRefreshEmployeeEvents());
  }, [dispatch]);

  return (
    <Box justify="end" flex="grow">
      {event?.slots?.reduce((acc, val) => {
        if (!val.booked && !val.reserved) {
          return ++acc;
        }
        return acc;
      }, 0) === 0 && (
        <Box align="row" justify="center" width="100%">
          <Text color="red" size="medium" textAlign="center">
            {t('beneficiary.available.event.card.no.slot.available')}
          </Text>
          <CheckBox
            label={
              <Text color="text" size="medium" weight="normal">
                {t('beneficiary.available.event.card.subscribe.slots.update.label')}
              </Text>
            }
            checked={checked}
            setChecked={handleClickCheckbox}
          />
        </Box>
      )}
      <ActionModal
        showModal={!!errorMessageKey}
        onHideModal={handleHideErrorModal}
        body={
          <Trans
            i18nKey={errorMessageKey} // optional -> fallbacks to defaults if not provided
            t={t}
            components={{ br: <br /> }}
          />
        }
        confirmLabel={t('common.label.ok').toUpperCase()}
        onConfirm={handleHideErrorModal}
        noCancelButton
      />
    </Box>
  );
};

const EmployeeEventCard = ({ event, book, user, onEventBooked, onShowPractitionerInfo, ...rest }) => {
  const { t } = useTranslation();
  const handleChangeSlots = (slot) => {
    book({ event: event, slot });
  };
  const userData = useSelector((state) => state.auth.user);
  const appointment = event?.appointments?.find((app) => app.employee === user);
  const isDelegateEvent = event?.specialty?.delegate;
  const responsiveSize = useContext(ResponsiveContext);
  const isSmallSize = responsiveSize === 'small';
  const {
    employeeInfo = '',
    slots,
    dateStart,
    specialty: eventSpecialty = {},
    company: { address = {} } = {}
  } = event ?? {};

  return (
    <ShadowBox round="8px" overflow="hidden" {...rest}>
      <Box fill pad={{ bottom: 'small' }}>
        <Box fill="horizontal" background="brand" pad="small">
          <Text color="white" weight="bold">
            {eventSpecialty?.label}
          </Text>
        </Box>
        <Box height={isSmallSize ? '' : '300px'} justify="start">
          <EventPractitioner event={event} />
          <Box margin={{ horizontal: 'medium', vertical: 'small' }}>
            <Text>{address?.street || ''}</Text>
            <Text>
              {address?.zipcode || ''} {address?.city || ''}
            </Text>
            <EventLocation event={event} textColor="accent" textSize="medium" textWeight={600} />
          </Box>
          <EventTags event={event} />
          <Box align="center" margin={{ horizontal: 'small', vertical: 'none' }}>
            <SpecialtyDocumentLink specialty={eventSpecialty} linkTextId="event.description.document.url" />
          </Box>
          {employeeInfo && (
            <Box align="center" margin={{ horizontal: 'small', vertical: 'none' }}>
              <Anchor onClick={() => onShowPractitionerInfo(event)} style={{ textAlign: 'center' }}>
                <Text color="brand" size="xsmall" margin={{ top: 'medium', bottom: 'medium' }} weight={700}>
                  {t('beneficiary.view.practitioner.info.label')}
                </Text>
              </Anchor>
            </Box>
          )}
          {isDelegateEvent && !isRemoteSpecialty(eventSpecialty) && (
            <Box margin={{ horizontal: 'small', vertical: 'small' }} alignSelf="center">
              <Text textAlign="center" size="small" weight={700} style={{ fontStyle: 'italic' }}>
                {t('component.event.card.beneficiary.delegate.detail')}
              </Text>
            </Box>
          )}
        </Box>
        <Separator />

        <Box align="center" margin="small" flex="grow">
          <Text weight={600} color="title">
            {/*Set of options make the date display using the following format: "Mardi 17 Octobre 2023" */}
            {toDate(dateStart, {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })}
          </Text>
          <Text weight={500} size="small">
            {t('beneficiary.event.consultation.duration', { duration: getAppointmentDuration(event) })}
          </Text>
          {isPhoneSpecialty(eventSpecialty) && userData?.phone && (
            <Text weight={500} size="xsmall">
              {t('beneficiary.available.event.card.phone.call.info')}
            </Text>
          )}
          {getAppointmentPrice(event)}
          {appointment && (
            <Anchor onClick={(evt) => onEventBooked()} style={{ textAlign: 'center' }}>
              <Text color="brand" margin={{ top: 'none', bottom: 'small' }} weight={500}>
                {t('beneficiary.available.event.card.appointment.already.booked')}
                <br />
                de{` ${appointment.start} à ${appointment.end}.`}
              </Text>
            </Anchor>
          )}
          <>
            {isPhoneSpecialty(eventSpecialty) && !userData?.phone ? (
              <Text
                color="accent"
                margin={{ top: 'medium', bottom: 'medium' }}
                weight={500}
                style={{ textAlign: 'center' }}
              >
                {t('beneficiary.available.event.card.no.phone.warning')}
              </Text>
            ) : (
              <>
                <SlotTimeBoxContainer
                  event={event}
                  slots={slots}
                  showBooked={false}
                  showReserved={false}
                  isEmployee={true}
                  onChangeSlot={appointment ? null : handleChangeSlots}
                />
                <CardFooter event={event} />
              </>
            )}
          </>
        </Box>
      </Box>
    </ShadowBox>
  );
};

export default EmployeeEventCard;

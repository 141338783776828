import React from 'react';
import { Box } from 'grommet';
import styled, { css } from 'styled-components';
import { T } from '../../components';

const ShadowBox = styled(Box)`
  ${(props) =>
    props.type === 'header'
      ? css`
          background: none;
        `
      : css`
          background: ${(props) => {
            if (props.myId && props.myId !== '' && props.myId === props.dataId) {
              // TODO: use color from themes
              // TODO: plan for a component prop
              return '#D6EAF8';
            }
            return props.background ? props.background : '#ffffff';
          }};
          box-shadow: ${(props) =>
            props.boxShadow ? `0px 4px 10px 5px ${props.boxShadow}` : '0px 4px 10px 5px rgba(233, 233, 233, 0.07)'};
          border-radius: 8px;
          height: 53px;
        `}
`;

/** Generate cell content : it can either be simple text or a function to render
 * The cell differentiate between header and table row as well (type)
 */
const cell = ({ type, header, render, column, row, property }) => {
  if (type === 'header') {
    return typeof header === 'function' ? (
      header()
    ) : (
      <T weight={500} size="16px">
        {header}
      </T>
    );
  } else {
    return typeof render === 'function' ? (
      column.render(row)
    ) : (
      <T size="16px" weight={500} color="title">
        {row[property]}
      </T>
    );
  }
};

/** Table row, all columns without a rowStyle are grouped
 * and displayed in a same ShadowBox,
 * those with a rowStyle are separated and each have their customized shadowbox
 */
const Row = ({ myId, dataId, row, columns, type, shouldPad, onClickRow, showCursor, backgroundColor = '' }) => {
  return (
    <Box
      direction="row"
      justify="between"
      gap="small"
      align="center"
      onClick={() => {
        if (typeof onClickRow === 'function' && row) {
          onClickRow(row);
        }
      }}
    >
      <ShadowBox
        background={backgroundColor}
        myId={myId}
        dataId={dataId}
        type={type}
        pad={{
          vertical: type !== 'header' ? 'medium' : '',
          horizontal: shouldPad ? 'medium' : ''
        }}
        direction="row"
        justify="between"
        gap="xsmall"
        align="center"
        fill="horizontal"
        style={{ cursor: showCursor ? 'pointer' : 'default' }}
      >
        {columns
          .filter((c) => !c.rowStyle)
          .map((c) => {
            let { property, render, size = 'small', header } = c;
            return (
              <Box key={property} width={size}>
                {cell({
                  type,
                  header,
                  render,
                  column: c,
                  row,
                  property
                })}
              </Box>
            );
          })}
      </ShadowBox>
      {columns
        .filter((c) => c.rowStyle)
        .map((c) => {
          let { property, render, rowStyle, header, rowClick } = c;
          return (
            <Box
              width={rowStyle?.width ? rowStyle?.width : '100px'}
              key={property}
              justify="center"
              align="end"
              pad={rowStyle?.pad ? rowStyle?.pad : 'none'}
            >
              <ShadowBox
                myId={myId}
                dataId={dataId}
                type={type}
                onClick={rowClick ? () => rowClick(row) : undefined}
                justify="center"
                align="center"
                width={rowStyle?.width ? rowStyle?.width : 'auto'}
                background={rowStyle?.background}
                boxShadow={rowStyle?.boxShadow}
              >
                {cell({
                  type,
                  header,
                  render,
                  column: c,
                  row,
                  property
                })}
              </ShadowBox>
            </Box>
          );
        })}
    </Box>
  );
};

const CardDataTable = ({
  columns,
  data,
  myId = '', // if provided, defines Id in table that represents User row
  showHeader = true,
  shouldPad = true,
  onClickRow,
  showCursor = false,
  ...rest
}) => {
  return (
    <Box {...rest} gap="small" style={{ maxHeight: '75vh' }}>
      {/* HEADER */}
      {showHeader && (
        <Box fill="horizontal" flex={false}>
          <Row type="header" columns={columns} shouldPad={shouldPad} />
        </Box>
      )}
      {/* ROWS*/}
      <Box gap="small" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
        {data &&
          data.map((e, idx) => (
            <Box key={e._id} fill="horizontal" flex={false}>
              <Row
                backgroundColor={e?.backgroundColor || ''}
                myId={myId}
                dataId={e._id}
                showCursor={showCursor}
                row={e}
                columns={columns}
                shouldPad={shouldPad}
                onClickRow={onClickRow}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default CardDataTable;

import i18n from 'i18next';
import { createEvent } from 'ics';
import fileDownload from 'js-file-download';
import moment from 'moment-timezone';
import { EVENT_STATUS } from '../constants';
import { isRemoteSpecialty } from '../features/specialties/specialtiesUtils';
import {
  getEventPractitionerEmail,
  getPractitionerName,
  getPractitionerTitleLabel
} from '../utility/practitionerUtils';

const GOOGLE_AGENDA_BASE_URL = 'https://calendar.google.com/calendar/render?action=TEMPLATE';
const MS_AGENDA_URL_PATH = '/calendar/0/action/compose?allday=false&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent';
const OFFICE_AGENDA_URL_PATH = '/calendar/action/compose?allday=false&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent';
const OUTLOOK_AGENDA_BASE_URL = `https://outlook.live.com${MS_AGENDA_URL_PATH}`;
const OFFICE_AGENDA_BASE_URL = `https://outlook.office.com${OFFICE_AGENDA_URL_PATH}`;
const YAHOO_AGENDA_BASE_URL = 'https://calendar.yahoo.com/?v=60';

const convertToText = (text) => {
  const patterns = [
    ['<br/>', '\n'],
    ['<b>', ''],
    ['</b>', ''],
    ['<a>', ''],
    ['</a>', '']
  ];
  let convertedText = text;
  patterns.forEach((pattern) => (convertedText = convertedText.replaceAll(pattern[0], pattern[1])));
  return convertedText;
};

/**
 * Generates calendar details that are common to beneficiary and practitioner
 * => event location, date, start/end times
 * @param {object} event - the event to generate a calendar item for
 * @param {object} company - the company associated to the event
 * @param {string} startTime - start time in HH:MM format
 * @param {string} endTime - end time in HH:MM format
 * @returns {object} Object containing calendar item location, date, start hour, start minutes, end hour, end minutes
 */
const generateCommonCalendarData = (event, company, startTime, endTime) => {
  const { address: { street = '', city = '', zipcode = '' } = {} } = company;
  const location = `${street}, ${zipcode} ${city}`;
  const eventDay = moment(event.dateStart).tz('Europe/Paris');
  const [sh, sm] = startTime.split(':').map((e) => parseInt(e, 10));
  const [eh, em] = endTime.split(':').map((e) => parseInt(e, 10));
  const startDate = [eventDay.year(), eventDay.month(), eventDay.date(), sh, sm];
  const endDate = [eventDay.year(), eventDay.month(), eventDay.date(), eh, em];
  const start = moment(new Date(...startDate)).format('YYYYMMDDTHHmmSSz');
  const end = moment(new Date(...endDate)).format('YYYYMMDDTHHmmSSz');
  const startMS = moment(new Date(...startDate)).format('YYYY-MM-DDTHH:mm');
  const endMS = moment(new Date(...endDate)).format('YYYY-MM-DDTHH:mm');
  return { location, eventDay, start, end, startMS, endMS, sh, sm, eh, em };
};

/**
 * computes calendar details to add a calendar item to Beneficiary calendar
 * for booked appointment from event, company, appointment & practitioner
 * @param {object} event - event to process
 * @param {object} company - company that scheduled event
 * @param {object} appointment - appointment to build calendar event for
 * @param {object} practitioner - practitioner managing appointment
 * @returns {object} calendar item details
 */
export const generateBeneficiaryCalendarData = (event, company, appointment, practitioner) => {
  const { location, eventDay, start, end, startMS, endMS, sh, sm, eh, em } = generateCommonCalendarData(
    event,
    company,
    appointment.start,
    appointment.end
  );
  const { location: room } = event;
  const practitionerName = getPractitionerName(event);
  const practitionerPosition = getPractitionerTitleLabel(practitioner?.specialty);
  const { employeeInfo = '', specialty, status } = event ?? {};
  const { name } = company;
  const email = getEventPractitionerEmail(event);
  const title = i18n.t('event.agenda.appointment.title', { name: practitionerName, position: practitionerPosition });
  let description = i18n.t('event.agenda.appointment.description', { name });
  description += i18n.t('event.agenda.appointment.details', { name: practitionerName, position: practitionerPosition });
  if (isRemoteSpecialty(specialty)) {
    description += `<br/><br/>${i18n.t('remote.event.calendar.label')}`;
    if (email) {
      description += `<br/><br/>${i18n.t('beneficiary.remote.event.calendar.info')} <b>${email}</b>`;
    }
  } else {
    if (room) {
      description += `<br/><br/><b>${i18n.t('common.label.room')} :</b> ${room}`;
    }
  }
  if (employeeInfo) {
    description += i18n.t('event.agenda.appointment.practitioner.info', { info: employeeInfo });
  }
  if (status === EVENT_STATUS.reassign) {
    description += i18n.t('event.agenda.appointment.practitioner.reassign.warning', { info: employeeInfo });
  }
  description += i18n.t('event.agenda.appointment.check.warning');
  const textDescription = convertToText(description);
  return { title, location, description, textDescription, eventDay, start, end, startMS, endMS, sh, sm, eh, em };
};

/**
 * computes calendar details to add a calendar item to Practitioner calendar
 * for an event he registered for from event & company details
 * @param {object} event - event to process
 * @param {object} company - company that scheduled event
 * @returns {object} calendar item details
 */
export const generatePractitionerCalendarData = (event, company) => {
  const { practitionerInfo = '', timeStart, timeEnd, location: room, specialty, contactPhone } = event ?? {};
  const { location, eventDay, start, end, startMS, endMS, sh, sm, eh, em } = generateCommonCalendarData(
    event,
    company,
    timeStart,
    timeEnd
  );
  const { name } = company;
  const { map } = company?.fileUploads?.reduce((acc, file) => ({ ...acc, [file.kind]: file }), {});
  let description = i18n.t('event.agenda.item.description', { name: name ? name.toUpperCase() : '' });
  const title = `${i18n.t('common.viabeez.prefix')} ${description}`;
  description += `<br/><br/>${i18n.t('common.label.contact.phone')}<a href="tel:${contactPhone}">${contactPhone}</a>`;
  if (map) {
    description += `<br/><br/><a href=${map.url}>${i18n.t('common.label.see.map')}</a>`;
  }
  if (isRemoteSpecialty(specialty)) {
    description += `<br/><br/>${i18n.t('remote.event.calendar.label')}`;
    description += `<br/><br/><b>${i18n.t('practitioner.remote.event.calendar.info')}</b>`;
  } else {
    if (room) {
      description += `<br/><br/><b>${i18n.t('common.label.room')} :</b> ${room}`;
    }
  }
  if (practitionerInfo) {
    description += i18n.t('event.agenda.appointment.company.info', { info: practitionerInfo });
  }

  const textDescription = convertToText(description);
  return { location, title, description, textDescription, eventDay, start, end, startMS, endMS, sh, sm, eh, em };
};

/**
 * Creates an iCalendar (.ics) file for appointment from event details
 * and generates download from browser
 * @param {object} calendarData - object containing appointment details
 */
export const downloadIcalEvent = (calendarData) => {
  const { title, location, description, textDescription, eventDay, sh, sm, eh, em } = calendarData;
  const start = [eventDay.year(), eventDay.month() + 1, eventDay.date(), sh, sm];
  const end = [eventDay.year(), eventDay.month() + 1, eventDay.date(), eh, em];
  const iCalendarEvent = {
    start,
    end,
    title,
    description: textDescription,
    location,
    status: 'CONFIRMED',
    organizer: { name: 'Viabeez' },
    busyStatus: 'BUSY',
    htmlContent: description
  };

  const result = createEvent(iCalendarEvent);
  if (result.error) {
    console.error(`ICS event creation error: ${result.error}`);
    return;
  }
  fileDownload(result.value, 'viabeez.ics');
};

/**
 * Generates a url to create appointment in Google calendar from event details
 * @param {object} calendarData - object containing appointment details
 * @returns {string} calendar event url
 */
export const createGoogleEvent = (calendarData) => {
  const { title, location, description, start, end } = calendarData;
  const urlTitle = encodeURIComponent(title);
  const urlLocation = encodeURIComponent(location);
  const urlDescription = encodeURIComponent(description);
  return `${GOOGLE_AGENDA_BASE_URL}&text=${urlTitle}&dates=${start}/${end}&ctz=Europe/Paris&details=${urlDescription}&location=${urlLocation}`;
};

/**
 * Generates a url to create appointment in Outlook calendar from event details
 * @param {object} calendarData - object containing appointment details
 * @returns {string} calendar event url
 */
export const createOutlookEvent = (calendarData) => {
  const { title, location, description, startMS, endMS } = calendarData;
  const urlTitle = encodeURIComponent(title);
  const urlLocation = encodeURIComponent(location);
  const urlDescription = encodeURIComponent(description);
  return `${OUTLOOK_AGENDA_BASE_URL}&startdt=${startMS}&enddt=${endMS}&subject=${urlTitle}&body=${urlDescription}&location=${urlLocation}`;
};

/**
 * Generates a url to create appointment in Office 365 calendar from event details
 * @param {object} calendarData - object containing appointment details
 * @returns {string} calendar event url
 */
export const createOfficeEvent = (calendarData) => {
  const { title, location, description, startMS, endMS } = calendarData;
  const urlTitle = encodeURIComponent(title);
  const urlLocation = encodeURIComponent(location);
  const urlDescription = encodeURIComponent(description);
  return `${OFFICE_AGENDA_BASE_URL}&startdt=${startMS}&enddt=${endMS}&subject=${urlTitle}&body=${urlDescription}&location=${urlLocation}`;
};

/**
 * Generates a url to create appointment in Yahoo calendar from event details
 * @param {object} calendarData - object containing appointment details
 * @returns {string} calendar event url
 */
export const createYahooEvent = (calendarData) => {
  const { title, location, textDescription, start, end } = calendarData;
  const urlTitle = encodeURIComponent(title);
  const urlLocation = encodeURIComponent(location);
  const urlDescription = encodeURIComponent(textDescription);
  return `${YAHOO_AGENDA_BASE_URL}&title=${urlTitle}&st=${start}&et=${end}&in_loc=${urlLocation}&desc=${urlDescription}`;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import { THEME_COLORS } from '../../config/theme';

const PractitionerReassignWarning = () => {
  const { t } = useTranslation();
  return (
    <Box direction="row" gap="xsmall" align="center">
      <FontAwesomeIcon bounce icon={faCircleInfo} color={THEME_COLORS['status-error']} size="sm" />
      <Text color={THEME_COLORS['status-error']} size="small" textAlign="center" weight={700}>
        {t('practitioner.reassign.warning')}
      </Text>
    </Box>
  );
};

export default PractitionerReassignWarning;

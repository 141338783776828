import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Formik } from 'formik';
import { Anchor, Box, FormField, MaskedInput, ResponsiveContext, TextInput } from 'grommet';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { THEME_COLORS } from '../../config/theme';

const FormInput = ({
  inputName,
  labelId,
  placeholderId,
  required,
  index,
  value,
  errors,
  disabled,
  handleChange,
  handleBlur,
  style,
  type = ''
}) => {
  const { t } = useTranslation();
  return (
    <FormField
      margin=""
      height="34px"
      style={style}
      label={t(labelId)}
      error={errors?.dataList?.[index]?.[inputName]}
      required={required}
      fill
    >
      {type === 'year' ? (
        <MaskedInput
          style={{ fontWeight: 400 }}
          size="small"
          name={`dataList.${index}.${inputName}`}
          disabled={disabled}
          value={value ? value : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          mask={[
            {
              length: 4,
              regexp: /^\d{0,4}$/,
              placeholder: t(placeholderId)
            }
          ]}
        />
      ) : (
        <TextInput
          style={{ fontWeight: 400 }}
          size="small"
          name={`dataList.${index}.${inputName}`}
          placeholder={t(placeholderId)}
          disabled={disabled}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </FormField>
  );
};

const isRequired = (validationSchema, fieldName) => {
  const fieldValidationSchema = Yup.reach(validationSchema, `dataList[].${fieldName}`);
  const tests = fieldValidationSchema.describe().tests;
  return tests ? !!tests.find((test) => test.name === 'required') : false;
};

const PractitionerProfileMultiForm = ({ formData, userData, setValid, setFormData, addLabelId, deleteLabelId }) => {
  const responsiveSize = useContext(ResponsiveContext);
  const { t } = useTranslation();
  const [formChanged, setFormChanged] = useState(false);

  let { schema, defaultValues, fields } = formData.reduce(
    (acc, { fieldName, validation, initialValue, disabled = false, label, placeholder, style = {}, type = '' }) => {
      acc.schema[fieldName] = validation;
      acc.defaultValues[fieldName] = initialValue;
      acc.fields.push({ name: fieldName, disabled, label, placeholder, style, type });
      return acc;
    },
    { schema: {}, defaultValues: {}, fields: [], errors: [] }
  );

  const initialValues = useMemo(() => {
    const values = !isEmpty(userData) ? userData : [defaultValues];
    return { dataList: values };
  }, [defaultValues, userData]);

  const validationSchema = Yup.object().shape({
    dataList: Yup.array().of(
      Yup.object().shape({
        ...schema
      })
    )
  });

  // use formRows to split fields on multiple lines if needed
  // each line is an array of fields
  // ex: const formRows = [[fields[0]], [fields[1], fields[2]]];
  // => field 0 on first line, fields 1 & 2 on second line
  const formRows = [fields];

  const validateFormData = useCallback(
    async (values) => {
      setFormChanged(true);
      if (values) {
        const isValid = await validationSchema.isValid(values);
        if (!isValid) {
          await validationSchema.validate(values).catch((err) => {
            return err.errors;
          });
        }
        setValid(isValid);
        if (isValid) {
          setFormData(values.dataList);
        }
      }
    },
    [validationSchema, setFormData, setValid]
  );

  useEffect(() => {
    const checkValid = async (values) => {
      const data = { dataList: values };
      const isValid = isEmpty(values) ? false : await validationSchema.isValid(data);
      setValid(isValid);
    };
    if (!formChanged && userData) {
      checkValid(userData);
    }
  }, [formChanged, userData, validationSchema, setValid]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
      validate={validateFormData}
    >
      {({ handleChange, handleBlur, values, errors, validateForm }) => (
        <FieldArray
          name="dataList"
          validateOnChange={false}
          render={(arrayHelpers) => (
            <>
              {values.dataList.map((formItem, formIndex) => (
                <Box key={`form-${formIndex}`}>
                  <Box direction="row-responsive">
                    <Box direction="column" style={{ flex: 3 }}>
                      {formRows.map((row, index) => (
                        <Box
                          key={`form-${formIndex}-row${index}`}
                          direction={responsiveSize === 'small' ? 'column' : 'row'}
                          gap="small"
                          margin="none"
                        >
                          {row.map((input) => (
                            <FormInput
                              type={input.type}
                              style={input.style}
                              labelId={input.label}
                              placeholderId={input.placeholder}
                              key={`form-${formIndex}-row${index}-${input.name}`}
                              inputName={input.name}
                              required={isRequired(validationSchema, input.name)}
                              index={formIndex}
                              value={formItem[input.name]}
                              errors={errors}
                              disabled={input.disabled}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          ))}
                        </Box>
                      ))}
                    </Box>
                    {values.dataList.length > 1 && (
                      <Box direction={responsiveSize === 'small' ? 'row' : 'column'}>
                        <Box style={{ height: '34px' }}></Box>
                        <FontAwesomeIcon
                          key={`form-delete-${formIndex}`}
                          title={t(deleteLabelId) + formIndex}
                          icon={faCircleXmark}
                          color={THEME_COLORS.text}
                          size="lg"
                          style={{ cursor: 'pointer', alignSelf: 'center', margin: '10px' }}
                          onClick={() => {
                            setFormChanged(true);
                            arrayHelpers.remove(formIndex);
                            // didn't find way to get validation work if called immediately
                            // in this case item with index formIndex is still in values
                            setTimeout(validateForm, 1000);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
              <Anchor
                weight="bold"
                size="small"
                color={THEME_COLORS.brand}
                icon={<FontAwesomeIcon icon={faCirclePlus} />}
                label={t(addLabelId)}
                onClick={() => {
                  arrayHelpers.push(defaultValues);
                  setFormChanged(true);
                  setValid(false);
                }}
              />
            </>
          )}
        />
      )}
    </Formik>
  );
};

export default PractitionerProfileMultiForm;

/**
 * computes S3 url for given document file name
 * @param {Object} appInfo - App info from state
 * @param {string} s3Key - name of file to build url for including extension
 * @returns {string} S3 bucket url to file
 */
export const computeDocumentUrl = (appInfo, s3Key) => {
  if (appInfo && s3Key) {
    const { bucket, region } = appInfo;
    return `https://${bucket}.s3.${region}.amazonaws.com/${s3Key}`;
  }
  console.warn('computeDocumentUrl() called with undefined appInfo or fileName!');
  return '';
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ActionModal } from '../../components';
import { logout } from '../../features/auth/authSlice';

/**
 * Modal to ask user to confirm that he wants to leave app
 * @component
 * @param {object} props - component properties
 * @param {boolean} props.show - disconnect modal visibility flag (true = visible)
 * @param {function} props.setShow - callback to toggle disconnect modal visibility flag
 * @returns {ReactFragment}
 */
const DisconnectModal = ({ show, setShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onConfirmationCancel = () => setShow(false);

  const onConfirmationExit = () => {
    setShow(false);
    dispatch(logout());
  };

  return (
    <ActionModal
      showModal={show}
      onHideModal={onConfirmationCancel}
      body={t('modal.logout.body')}
      confirmLabel={t('modal.logout.button.quit')}
      onConfirm={onConfirmationExit}
      onCancel={onConfirmationCancel}
    />
  );
};

export default DisconnectModal;

import moment from 'moment';
import 'moment/locale/fr';
import './utility/formValidation';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import App from './App';
import store from './app/store';
import * as serviceWorker from './serviceWorker';

moment.locale('fr'); // Use fr locales by default (moment)
window.__localeId__ = 'fr'; // Use fr locales by default (date-fns)

// Initialize GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: 'PageDataLayer'
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

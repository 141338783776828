import React from 'react';
import { useTranslation } from 'react-i18next';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Box, Text } from 'grommet';
import { ClipboardCopy } from '..';
import { THEME_COLORS } from '../../config/theme';
import { getCompanyAccountCreationByCodeLink } from '../../utility/companyUtils';

/**
 * displays the company beneficiary account creation link if company code
 * is enabled
 * includes:
 * - clickable link text
 * - copy to clipboard icon
 * @param {Object} props - component properties
 * @param {boolean} props.codeEnabled - true if company code is enabled
 * @param {string} [props.code=''] - company code value
 * @returns {React.Component}
 */
const CompanyCodeLink = ({ codeEnabled, code = '' }) => {
  const codeUrl = codeEnabled ? getCompanyAccountCreationByCodeLink(code) : '';
  const { t } = useTranslation();
  return (
    <>
      {codeEnabled && (
        <Box align="center" alignSelf="start" direction="row" gap="small">
          <FontAwesomeIcon icon={faLink} color={THEME_COLORS.brand} size="xs" />
          <Anchor href={codeUrl} target="_blank">
            <Text size="xsmall" title={t('beneficiary.account.creation.link')} color={THEME_COLORS.brand}>
              {codeUrl}
            </Text>
          </Anchor>
          <ClipboardCopy buffer={codeUrl} iconTipId="copy.link.to.clipboard" copyTipId="link.copied.to.clipboard" />
        </Box>
      )}
    </>
  );
};

export default CompanyCodeLink;

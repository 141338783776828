import { TextInput } from 'grommet';
import styled from 'styled-components';

const CustomTextInput = styled(TextInput)`
  && {
    font-size: ${(props) => (props.size ? props.size : '16px')};
    border: ${(props) => (props.border ? props.border : 'none')};
    color: ${(props) => (props.textColor ? props.textColor : '#444444')};
  }
`;

export default CustomTextInput;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import adminDashboardReducer from '../features/adminDashboard/adminDashboardSlice';
import authReducer from '../features/auth/authSlice';
import companiesReducer from '../features/companies/companiesSlice';
import eventsReducer from '../features/events/eventsSlice';
import navigationReducer from '../features/navigation/navigationSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';
import specialtiesReducer from '../features/specialties/specialtiesSlice';
import usersReducer from '../features/users/usersSlice';
import socketMiddleware from './middleware/socketMiddleware';

const appReducer = combineReducers({
  auth: authReducer,
  events: eventsReducer,
  adminDashboard: adminDashboardReducer,
  users: usersReducer,
  companies: companiesReducer,
  navigation: navigationReducer,
  notifications: notificationsReducer,
  specialties: specialtiesReducer
});

const reducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    if (action.payload === 'resetState') {
      state = undefined;
    }
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketMiddleware)
});

export const getAuthUser = () => store.getState()?.auth?.user;
export const getAuthUserSite = () => store.getState()?.auth?.connectionSite;

export default store;

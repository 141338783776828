import { useMemo } from 'react';
import { AppointmentStatus, computeAppointmentRevenue, parseRevenue } from '../../utility/practitionerUtils';

export function useAppointmentsInformations(appointments, event) {
  return useMemo(() => {
    const initialInformations = { achievedAppointmentsCount: 0, absentAppointmentsCount: 0, totalRevenue: 0 };
    let appointmentsInformations = initialInformations;

    if (appointments) {
      appointmentsInformations = appointments.reduce((acc, appointment) => {
        if (appointment && appointment.employee) {
          if (!appointment.status || appointment.status === AppointmentStatus.seen) {
            acc.achievedAppointmentsCount++;
            acc.totalRevenue += computeAppointmentRevenue(appointment, event);
          } else if (appointment.status === AppointmentStatus.absent) {
            acc.absentAppointmentsCount++;
          }
        }
        return acc;
      }, initialInformations);
    }

    return { ...appointmentsInformations, totalRevenue: parseRevenue(appointmentsInformations.totalRevenue) };
  }, [appointments, event]);
}

import { createSlice } from '@reduxjs/toolkit';
import api from '../../utility/api';

export const specialtiesSlice = createSlice({
  name: 'specialties',
  initialState: {
    list: [],
    groups: [],
    selectedSpecialty: '',
    selectedGroup: '',
    counters: {}
  },
  reducers: {
    addOrUpdateInList: (state, action) => {
      if (state.list.find((e) => e._id === action.payload._id)) {
        state.list = state.list.map((l) => (l._id === action.payload._id ? action.payload : l));
      } else {
        state.list = [action.payload, ...state.list];
      }
    },
    updateList: (state, action) => {
      state.list = action.payload;
    },
    updateGroups: (state, action) => {
      state.groups = action.payload;
    },
    updateSelectedSpecialty: (state, action) => {
      state.selectedSpecialty = action.payload;
    },
    updateSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    updateCounters: (state, action) => {
      state.counters = action.payload;
    }
  }
});

export const {
  addOrUpdateInList,
  updateList,
  updateGroups,
  updateSelectedSpecialty,
  updateSelectedGroup,
  updateCounters
} = specialtiesSlice.actions;

/**
 * Queries backend for list of specialties matching visibility criteria
 * @param {number|undefined} visibility - if provided defines visibility flag of specialties to fetch:
 * undefined: ALL specialties whatever visibility flag value is
 * 0 : ALL disabled specialties
 * 1 : available for practitioners (and enabled)
 * 2 : available for companies  (and enabled)
 * 3 : available for practitioners & companies (and enabled)
 * 4 : ALL enabled specialties whatever visibility (practitioner and/or company) is
 * !!! This API can only be called in an authenticated user context
 * @returns {Object[]} The specialties matching visibility criteria
 */
export const fetchSpecialties = (visibility) => async (dispatch) => {
  try {
    const params = new URLSearchParams('');
    const query = '/specialties?';
    if (visibility !== undefined) {
      params.set('visibility', visibility);
    }
    console.log(`fetchSpecialties:: Fetching specialties with visibility status: '${visibility}'...`);
    const { specialties } = await api.get(`${query}${params.toString()}`).then((res) => res.data);
    if (specialties) {
      console.log(`fetchSpecialties:: Fetched ${specialties.length} specialties.`);
      dispatch(updateList(specialties));
      return specialties;
    } else {
      console.warn('fetchSpecialties:: no data returned');
      return [];
    }
  } catch (e) {
    console.error('fetchSpecialties:: exception while fetching specialties, error: ', e.message);
  }
};

/**
 * Queries backend for list of specialties available for practitioners to create an account
 * !!! This API is public - expected to be used only for practitioner signup cases
 * it returns a subset of specialties data as API exposed to anybody
 * @returns {Object[]} The specialties available for practitioner signup
 */
export const fetchPractitionerSpecialties = () => async (dispatch) => {
  try {
    const params = new URLSearchParams('');
    const query = '/specialties/options?';
    params.set('visibility', 1);
    console.log(`fetchPractitionerSpecialties:: Fetching specialties available for practitioners...`);
    const { specialties } = await api.get(`${query}${params.toString()}`).then((res) => res.data);
    if (specialties) {
      console.log(`fetchPractitionerSpecialties:: Fetched ${specialties.length} specialties.`);
      dispatch(updateList(specialties));
      return specialties;
    } else {
      console.warn('fetchPractitionerSpecialties:: no data returned');
      return [];
    }
  } catch (e) {
    console.error(
      'fetchPractitionerSpecialties:: exception while fetching practitioner specialties, error: ',
      e.message
    );
  }
};

/**
 * Queries backend for list of specialty groups matching visibility criteria
 * @param {string|undefined} status - if provided defines visibility flag of specialties to fetch:
 * undefined: ALL specialty groups whatever enable status or type is
 * 0 : disabled groups only
 * 1 : enabled groups only
 * 2 : default group only
 * @returns {Object[]} The specialty group(s) matching visibility criteria
 */
export const fetchSpecialtyGroups = (status) => async (dispatch) => {
  try {
    const params = new URLSearchParams('');
    let query = '/specialties/groups?';
    if (status !== undefined) {
      params.set('status', status);
    }
    console.log(`fetchSpecialtyGroups:: Fetching specialty groups with status: '${status}'...`);
    const { specialtyGroups } = await api.get(`${query}${params.toString()}`).then((res) => res.data);
    if (specialtyGroups) {
      console.log(`fetchSpecialtyGroups:: Fetched ${specialtyGroups.length} specialty group(s).`);
      dispatch(updateGroups(specialtyGroups));
      return specialtyGroups;
    } else {
      console.warn('fetchSpecialtyGroups:: no data returned');
      return [];
    }
  } catch (e) {
    console.error('exception while fetching specialty groups, error :', e.message);
  }
};

export const fetchSpecialtyCounters = () => async (dispatch) => {
  try {
    console.log('fetchSpecialtyCounters:: Fetching specialty counters ...');
    const counters = await api.get('/specialties/counters').then((res) => res.data);
    console.log('fetchSpecialtyCounters:: Specialty counters fetched.');
    dispatch(updateCounters(counters));
  } catch (e) {
    console.error('exception while fetching specialty counters, error:', e.message);
  }
};

export const updateSpecialtyGroup = (id, formData) => async (dispatch) => {
  try {
    const result = await api.put(`/specialties/group/${id}`, formData);
    const { data } = result;
    if (data?.status === 'success') {
      dispatch(fetchSpecialties());
      dispatch(fetchSpecialtyGroups());
      dispatch(fetchSpecialtyCounters());
    }
    return { result };
  } catch (e) {
    console.error(`Error while updating specialty group Id: ${id} error: ${e.message}`);
    return { status: 'error' };
  }
};

export const addSpecialtyGroup = (formData) => async (dispatch) => {
  try {
    const { status } = await api.post('/specialties/group', formData).then((res) => res.data);
    if (status === 'success') {
      dispatch(fetchSpecialties());
      dispatch(fetchSpecialtyGroups());
      dispatch(fetchSpecialtyCounters());
    }
    return { status };
  } catch (e) {
    console.error(`Error while adding new specialty group: ${e.message}`);
    return { status: 'error', data: e?.response?.data?.errors };
  }
};

export const addSpecialty = (formData) => async (dispatch) => {
  try {
    const result = await api.post('/specialties', formData);
    const { data } = result;
    if (data?.status === 'success') {
      dispatch(fetchSpecialties());
      dispatch(fetchSpecialtyCounters());
    }
    return { result };
  } catch (e) {
    console.error(`Error while adding new specialty: ${e.message}`);
    return { status: 'error', data: e?.response?.data?.errors };
  }
};

export const updateSpecialty = (id, formData) => async (dispatch) => {
  try {
    const result = await api.put(`/specialties/${id}`, formData);
    const { data } = result;
    if (data?.status === 'success') {
      dispatch(fetchSpecialties());
      dispatch(fetchSpecialtyCounters());
    }
    return { result };
  } catch (e) {
    console.error(`Error while updating specialty Id: ${id} error: ${e.message}`);
    return { status: 'error' };
  }
};

export default specialtiesSlice.reducer;

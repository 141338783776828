import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCloudQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { Box, Text } from 'grommet';
import { THEME_COLORS } from '../../../config/theme';
import { getRefreshToken } from '../../../utility/api';
import { getReactProcessVar } from '../../../utility/appConfig';
import { waitForDomElement } from '../../../utility/chartUtils';
import Spinner from '../../Spinner/Spinner';
import MongoChartError from './MongoChartError';

/**
 *
 * @param {string} [props.chartId] MongoDB chart Id provided by mongoDB Charts interface
 * @param {string} [props.width] Width of the chart, any valid width props for a div are accepted
 * @param {string} [props.height] Height of the chart, any valid height props for a div are accepted
 * @param {string} [props.title] Title of the chart
 * @param {Object} [props.filter] optional, any valid MQL (MongoDB Query Language) query
 * @returns {Component} An embedded mongoDB chart
 */
export const MongoChart = ({ chartId, width, height, title, filter }) => {
  const sdk = new ChartsEmbedSDK({
    baseUrl: getReactProcessVar('REACT_APP_MONGO_CHARTS_PROJECT_BASE_URL'),
    getUserToken: () => getRefreshToken()
  });
  const { t } = useTranslation();
  console.log(`MongoChart.js::main - ${chartId} - sdk instance`, sdk);
  const [error, setError] = useState(false);
  const [rendered, setRendered] = useState(false);
  const [isEmptyChart, setIsEmptyChart] = useState(false);
  // Adding some randomness, allow rendering same chart multiple times
  const chartDOMId = `chart${chartId}-${String(Math.floor(Math.random() * 100))}`;
  const chartQueryDOMId = `#${chartDOMId}`;
  const [chart] = useState(
    sdk.createChart({
      chartId,
      showAttribution: false,
      renderingSpec: { version: 1, title: '' },
      autoRefresh: false,
      ...(filter && { filter })
    })
  );

  useEffect(() => {
    // Avoid errors in strict mode
    if (rendered === false) {
      waitForDomElement(chartQueryDOMId).then((chartDiv) => {
        console.log(`MongoChart.js::useEffect - ${chartId} - Div is defined and dashboard ready to be rendered`, chart);
        chart
          .render(chartDiv)
          .then(() => {
            chart.getData().then((data) => {
              const { documents } = data;
              // In case of null value, mongo charts API returns only one document with a prop value = null
              const [firstDocument] = documents;
              setIsEmptyChart(documents.length === 0 || firstDocument.value === null);
            });
            console.log(`MongoChart.js::useEffect - ${chartId} - dashboard rendered.`, chart);
            setRendered(true);
          })
          .catch((err) => {
            setError(true);
            console.error(`MongoChart.js::useEffect - ${chartId} - Error while rendering chart : ${err}`);
          });
      });
    }
  }, [chart, chartId, chartQueryDOMId, rendered]);

  const refreshChart = useCallback(() => {
    setError(false);
    chart
      .refresh()
      .then(() => {
        setRendered(true);
      })
      .catch((err) => {
        setError(true);
        console.error(`Error while refreshing chart : ${err}`);
      });
  }, [chart]);

  return (
    <>
      <Text weight={'bold'} color={'title'} textAlign="center">
        {title}
      </Text>
      <div
        id={chartDOMId}
        style={{ width, height, ...((error || !rendered || isEmptyChart) && { display: 'none' }) }}
      />
      {error ? <MongoChartError onRefresh={refreshChart} width={width} height={height} /> : null}
      {!rendered && !error ? (
        <Box width={width} height={height}>
          <Spinner />
        </Box>
      ) : null}
      {isEmptyChart && !error ? (
        <Box width={width} height={height} justify="center" gap="small" align="center">
          <FontAwesomeIcon icon={faCloudQuestion} size="3x" color={THEME_COLORS.text} />
          <Text color="text" weight={'bold'}>
            {t('component.chart.no.data')}
          </Text>
        </Box>
      ) : null}
    </>
  );
};

export default MongoChart;

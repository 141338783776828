import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponsiveContext } from 'grommet';
import { THEME_COLORS } from '../../config/theme';
import ToolTip from '../Misc/ToolTip';
import RoundButton from './RoundButton';

/**
 * Navigation item that displays rounded button with a logo and tooltip logic.
 * Tooltip is displayed
 * @component
 * @property {callback} handleClick a callback that handle the logic when clicking on the button
 * @property {string} tooltipContent translation value returned by the i18n
 * @property {IconDefinition} icon icon definition coming from fontAwesome
 * @property {ReactNode} children any react node
 * @property {object} [roundButtonProps={}] any props that can be passed to Grommet button component
 * @returns {Component}
 */
const NavItem = ({ handleClick, tooltipContent, icon, children, roundButtonProps = {} }) => {
  const responsiveSize = useContext(ResponsiveContext);
  return (
    <ToolTip content={tooltipContent} placement="bottom" disabled={responsiveSize === 'small' ? true : false}>
      <RoundButton size="30px" flex={false} onClick={handleClick} {...roundButtonProps}>
        {icon && <FontAwesomeIcon icon={icon} color={THEME_COLORS.white} size="xs" />}
        {children}
      </RoundButton>
    </ToolTip>
  );
};

export default NavItem;

import React from 'react';
import { Box, Text } from 'grommet';

/**
 * @deprecated Use <Button .../> instead
 */
const Secondary = (props) => {
  return (
    <Box
      align="center"
      justify="center"
      background={props.background ? props.background : 'white'}
      round={props.border ? props.border : '4px'}
      border={{ color: 'accent', size: '2px' }}
      pad={{ horizontal: 'medium', vertical: 'xsmall' }}
      flex={false}
      height={{ min: '36px' }}
      focusIndicator
      {...props}
    >
      <Text color={props.color ? props.color : 'accent'} weight={props.weight ? props.weight : 700}>
        {props.label}
      </Text>
    </Box>
  );
};

export default Secondary;

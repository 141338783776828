import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

/**
 * Extends the Trans component by simplifying it for some common options (br, bold,...)
 * @param {Object} props Properties
 * @param {string} props.i18nKey The translation string key
 * @param {string} [props.br] The translation key can have <br/> tags. Default: false
 * @param {string} [props.bold] The translation key can have <b/> tags. Default: false
 * @returns {React.ReactElement}
 */
const TransEx = ({ i18nKey, br = false, bold = false, ...props }) => {
  const { t } = useTranslation();
  return (
    <Trans i18nKey={i18nKey} t={t} components={{ ...(br && { br: <br /> }), ...(bold && { b: <b /> }) }} {...props} />
  );
};

export default TransEx;

/**
 * gets an object property value - works for nested props as well
 * for nested props, path is given in dot notation as propString value
 * ex: obj = { a: 1, b : { c : { d: 2, e: 3}, f: 'bbbb'}}
 * to get obj.b.c.e value => propString = 'b.c.e'
 * @param {object} obj - object to query
 * @param {string} propString - object property to get value for
 * @returns {*|null} the property value if found or null
 */
export const getPropByString = (obj, propString) => {
  if (!propString) {
    return null;
  }
  const props = propString.split('.');
  let candidate;
  props.every((prop) => {
    candidate = obj[prop];
    if (!candidate) {
      return false;
    }
    obj = candidate;
    return true;
  });
  return candidate;
};

import { TextInput } from 'grommet';
import styled from 'styled-components';

const SearchInput = styled(TextInput)`
  border: none;
  text-overflow: ellipsis;
  border-radius: 6px;
  background-color: white;
  && {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => (props.textColor ? props.textColor : '#444444')};
  }

  &&::placeholder {
    font-size: 16px;
    font-weight: 600;
    color: #c1c1c1;
  }
`;

export default SearchInput;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const borderColor = '#000';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderTopColor: '#000',
    borderTopWidth: 2,
    borderRightColor: '#000',
    borderRightWidth: 2,
    borderLeftColor: '#000',
    borderLeftWidth: 2,
    alignItems: 'center',
    height: 50,
    textAlign: 'center',
    flexGrow: 1,
    fontSize: 10
  },
  cellBorder: { borderRightColor: borderColor, borderRightWidth: 1 },
  cell: {
    height: '100%',
    padding: '10px 5px',
    width: '15%'
  }
});

const TableHeader = ({ eventHasRevenueInfo }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <Text style={{ ...styles.cell, ...styles.cellBorder }}>{t('pdf.events.analytics.slot')}</Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder }}>{t('pdf.events.analytics.lastName')}</Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder }}>{t('pdf.events.analytics.firstName')}</Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: '15%' }}>
        {t('pdf.events.analytics.confirmation')}
      </Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: '15%' }}>{t('pdf.events.analytics.status')}</Text>
      {eventHasRevenueInfo && (
        <Text style={{ ...styles.cell, ...styles.cellBorder }}>{t('pdf.events.analytics.revenue')}</Text>
      )}
      <Text style={styles.cell}>Validité</Text>
    </View>
  );
};

export default TableHeader;

import { io } from 'socket.io-client';
import { addOrUpdateInList, updateList } from '../../features/notifications/notificationsSlice';
import { getRefreshToken } from '../../utility/api';
import { getAppConfig } from '../../utility/appConfig';

let socket;

const BACKEND_NOTIFICATIONS_URL = `${getAppConfig().backendUrl}/notifications`;

const socketLog = (msg, error = null) => {
  const logText = `[SOCKET]:: ${msg} ${error || ''}`;
  if (error) {
    console.error(logText);
  } else {
    console.log(logText);
  }
};

const socketMiddleware = ({ dispatch }) => {
  return (next) => (action) => {
    if (action.type === 'USER_LOGOUT') {
      if (socket) {
        socket.disconnect();
        socket = null;
      }
    } else {
      // create socket if user is authenticated
      // as there's no token refresh mechanism for socket
      // use refresh token instead of auth token
      const token = getRefreshToken();
      if (!socket && token) {
        socket = io(BACKEND_NOTIFICATIONS_URL, {
          transports: ['polling', 'websocket'],
          auth: { token }
        });

        socketLog('socket created.');

        socket.on('connect', () => {
          if (socket.connected) {
            socketLog('socket connected.');
            return;
          }
          if (socket.disconnected) {
            socketLog('socket disconnected.');
            return;
          }
        });
        console.log('socket created.');
        socket.on('error', (err) => {
          console.warn(`Socket error: ${err}, reverting to HTTP polling...`);
          socket.io.opts.transports = ['polling', 'websocket'];
        });
        socket.on('connect_error', (err) => {
          // revert to classic upgrade
          console.warn(`Socket connection error: ${err}, reverting to HTTP polling...`);
          socket.io.opts.transports = ['polling', 'websocket'];
        });
        socket.on('reconnect_error', (error) => {
          socketLog('error during socket reconnection!', error);
        });
        socket.on('reconnect_failed', () => {
          socketLog('socket failed to reconnect!', {});
        });
        // listen to certain events
        socket.on('list-notifications', (notifications) => {
          dispatch(updateList(notifications));
        });
        socket.on('new-notification', (notification) => {
          dispatch(addOrUpdateInList(notification));
        });
      }

      if (action.type === 'EMIT_SOCKET') {
        if (socket) {
          let { name, data } = action.payload;
          socket.emit(name, data);
          return;
        }
      }
    }
    return next(action);
  };
};

export default socketMiddleware;

/**
 * Implements an Enum like object
 * makes sure only unique items are kept
 * resulting object is frozen to prevent later changes
 * Usage: myEnum = new Enum( item1, item2, item3, ...)
 *
 * @param {object} items a list of object expected to contain both 'key' and
 * 'value' properties
 * example : 
 * new Enum(
  { key: 'key1', value: 'value for key1' },
  { key: 'key2', value: 'value for key2' },
  );
 *
 * All Enum keys can be queried like this: keys = [...myEnum]
 */
export class Enum {
  constructor(...items) {
    const uniqueItems = [...new Set(items)];
    uniqueItems.forEach((item) => {
      if ('key' in item && 'value' in item) {
        this[item.key] = item.value;
      }
    });
    Object.freeze(this);
  }

  *[Symbol.iterator]() {
    for (let key of Object.keys(this)) yield key;
  }
}

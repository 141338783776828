import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import appInfo from '../_generated_/appInfo';
import { buildYupLocale } from './formValidation';

export const i18nReady = i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init(
    {
      lng: 'fr',
      fallbackLng: 'fr',
      debug: true,
      interpolation: {
        escapeValue: false
      },
      backend: {
        queryStringParams: { cachebust: appInfo?.pkgVersion || Date.now() }
      }
    },
    buildYupLocale
  );

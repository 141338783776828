import React from 'react';
import { useTranslation } from 'react-i18next';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Box, Text } from 'grommet';
import { ClipboardCopy } from '..';
import { THEME_COLORS } from '../../config/theme';

/**
 * displays an email address
 * includes:
 * - clickable link => triggers a mailto action
 * - copy to clipboard icon
 * @param {Object} props - component properties
 * @param {string} [props.email=''] - email address
 * @param {string} [props.mailToLabelId=''] - tooltip label id for mailto link
 * @returns {React.Component}
 */
const EmailLink = ({ email = '', mailToLabelId = '' }) => {
  const { t } = useTranslation();
  if (email === '') {
    return null;
  }
  return (
    <>
      {email !== '' && (
        <Box align="center" alignSelf="start" direction="row" gap="xsmall" onClick={(event) => event.stopPropagation()}>
          <FontAwesomeIcon icon={faEnvelope} color={THEME_COLORS.brand} size="xs" />
          <Anchor href={`mailto:${email}`} target="_blank">
            <Text size="xsmall" title={t(mailToLabelId)} color={THEME_COLORS.brand}>
              {email}
            </Text>
          </Anchor>
          <ClipboardCopy buffer={email} iconTipId="copy.email.to.clipboard" copyTipId="email.copied.to.clipboard" />
        </Box>
      )}
    </>
  );
};

export default EmailLink;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from 'grommet';
import { T, TextInput } from 'src/components';
import './SecurityCodeInput.css';

const CODE_LENGTH = 4; // security code expected to be on 4 digits minimum

/**
 * pads a string (expected to be a numeric value) if needed on CODE_LENGTH digits
 * ex: 12 => 0012, or 123 => 0123, 5623 => 5623
 * @param {string} val - numeric value to pad
 * @returns {string} padded number on CODE_LENGTH digits
 */
const padNumber = (val = '') => {
  const str = val.toString();
  return str.padStart(CODE_LENGTH, '0');
};

/**
 * renders an input field to allow user to enter a numeric security code
 * that will be padded on CODE_LENGTH digits
 * NOTE: on purpose, the field doesn't restrict input length (min/max)
 * @param {Object} props - component props
 * @param {string} props.inputId - input field Id
 * @param {string} props.inputName - input field name
 * @param {string} props.inputValue - input current value
 * @param {function} props.onBlur - callback function on input blur event
 * @param {function} props.onChange - callback function on input change event
 * @returns {React.ReactFragment} security code input component
 */
const SecurityCodeInput = ({ inputId, inputName, inputValue, onBlur, onChange }) => {
  const { t } = useTranslation();
  return (
    <FormField name={inputId} htmlFor={inputId} label={t('admin.security.code')} fill>
      <TextInput
        className="no-arrows"
        type="number"
        id={inputId}
        name={inputName}
        value={inputValue}
        onKeyDown={(evt) => ['e', 'E', '+', '-', 'Enter'].includes(evt.key) && evt.preventDefault()}
        onBlur={(e) => onBlur(padNumber(e.target.value))}
        onChange={(e) => onChange(padNumber(e.target.value))}
        placeholder={
          <T size="16px" color="lightGrey">
            {t('admin.security.code.placeholder')}
          </T>
        }
      />
    </FormField>
  );
};

export default SecurityCodeInput;

import React from 'react';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'grommet';
import { THEME_COLORS } from '../../config/theme';
import RoundButton from '../Button/RoundButton';

/**
 * @description Implements application Bar customizable dropdown menu
 * @description displays as a red ball with a hamburger icon
 * @component
 * @property {object[]}  menuItems - array of menu entries
 */
const AppBarMenu = ({ menuItems }) => {
  return (
    <Menu
      direction="row"
      dropAlign={{ right: 'right', top: 'bottom' }}
      dropProps={{ margin: { top: '9px' } }}
      justifyContent="end"
      margin={{ top: '4px', right: '4px' }}
      items={menuItems}
    >
      <RoundButton size="36px" onClick={() => {}}>
        <FontAwesomeIcon icon={faBars} color={THEME_COLORS.white} size="lg" />
      </RoundButton>
    </Menu>
  );
};

export default AppBarMenu;

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Anchor, Box, ResponsiveContext, Text } from 'grommet';
import { EventCalendarMenu, EventTags } from 'src/components';
import {
  CARD_ACTION_BOX_MARGIN,
  CARD_ACTION_TEXT_SIZE,
  EventLocation,
  getAppointmentPrice,
  toDate
} from 'src/components/Card/cardData';
import EventPractitioner from 'src/features/employeeDashboard/EventPractitioner';
import styled from 'styled-components';
import { isRemoteSpecialty } from '../../features/specialties/specialtiesUtils';
import SpecialtyDocumentLink from '../../features/specialties/SpecialtyDocumentLink';
import { getSpecialtyLabel } from '../../utility/practitionerUtils';
import { CardButton } from '../index';

const ShadowBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(210, 210, 210, 0.08);
  border-radius: 8px;
`;

const EmployeeScheduledEventCard = ({ event, userId, onUnBookEvent, onMoveAppointment, onShowPractitionerInfo }) => {
  const { t } = useTranslation();
  const company = useSelector((state) => state?.auth?.user?.company);
  const authUser = useSelector((state) => state?.auth?.user);
  const [slot, setSlot] = useState(); // appointment start/end
  useEffect(() => {
    const employeeAppointment = event.appointments.find((appointment) => appointment.employee === userId);
    if (employeeAppointment) {
      const employeeSlot = event.slots.find((slot) => slot.from === employeeAppointment.start);
      setSlot(employeeSlot);
    }
  }, [event.appointments, event.slots, userId]);

  const isDelegateEvent = event?.specialty?.delegate;
  const responsiveSize = useContext(ResponsiveContext);
  const isSmallSize = responsiveSize === 'small';
  const isMediumSize = responsiveSize === 'medium';
  const { dateStart, specialty, _id: eventId, employeeInfo = '' } = event;

  return (
    <Box pad="small" basis={isMediumSize ? '1/2' : isSmallSize ? '1/1' : '1/3'} fill="horizontal">
      <ShadowBox
        round="8px"
        overflow="hidden"
        align="center"
        background="rgba(65, 184, 252, 0.2);"
        height={{ min: '300px' }}
      >
        <Box fill="horizontal" background="brand" direction="row" justify="between" pad="small">
          <Box>
            <Text color="white" weight={700}>
              {toDate(dateStart)} {slot && `${slot.from} - ${slot.to}`}
            </Text>
            <Text color="white">{getSpecialtyLabel(event?.specialty)}</Text>
          </Box>
        </Box>
        <Box fill background="white" justify="center">
          <Box margin={{ vertical: 'small', horizontal: 'medium' }} align="center" direction="row" gap="small">
            <EventPractitioner event={event} />
          </Box>
          <Box dir="row" align="center">
            <EventLocation event={event} textColor="title" textSize="medium" textWeight={600} />
          </Box>
          <Box align="center" height={{ min: '160px' }}>
            <Box alignSelf="start">
              <EventTags event={event} margin={{ horizontal: 'small' }} />
            </Box>
            <Box align="center">
              <Box pad={{ right: 'none', left: 'none' }}>{getAppointmentPrice(event)}</Box>
              <Box margin={{ horizontal: 'small', vertical: 'none' }}>
                <SpecialtyDocumentLink specialty={specialty} linkTextId="event.description.document.url" />
              </Box>
              {employeeInfo && (
                <Anchor onClick={() => onShowPractitionerInfo(event)} style={{ textAlign: 'center' }}>
                  <Text color="brand" size="xsmall" margin={{ top: 'medium', bottom: 'medium' }} weight={700}>
                    {t('beneficiary.view.practitioner.info.label')}
                  </Text>
                </Anchor>
              )}
              {isDelegateEvent && !isRemoteSpecialty(specialty) ? (
                <Box margin={{ horizontal: 'small', vertical: 'none' }}>
                  <Text
                    textAlign="center"
                    size="small"
                    weight={700}
                    style={{
                      fontStyle: 'italic'
                    }}
                  >
                    {t('component.event.card.beneficiary.delegate.detail')}
                  </Text>
                </Box>
              ) : null}
            </Box>
          </Box>
          <CardButton gap="none" margin={CARD_ACTION_BOX_MARGIN} style={{ maxWidth: '95%', alignSelf: 'center' }}>
            <EventCalendarMenu event={event} company={company} user={authUser} />
          </CardButton>
          <CardButton onClick={() => onMoveAppointment({ event, slot })} margin={CARD_ACTION_BOX_MARGIN}>
            <Text weight={700} color="brand" size={CARD_ACTION_TEXT_SIZE}>
              {t('common.move.appointment.label')}
            </Text>
          </CardButton>
          <CardButton onClick={() => onUnBookEvent({ eventId, slot })} margin={CARD_ACTION_BOX_MARGIN}>
            <Text weight={700} color="danger" size={CARD_ACTION_TEXT_SIZE}>
              {t('common.cancel.appointment.label')}
            </Text>
          </CardButton>
        </Box>
      </ShadowBox>
    </Box>
  );
};

export default EmployeeScheduledEventCard;

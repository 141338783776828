import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'grommet';
import PractitionerProfileModal from 'src/components/Modal/PractitionerProfileModal';
import PractitionerReassignWarning from 'src/features/practitioner/PractitionerReassignWarning';
import { AvatarWithPlaceholder, EmailLink } from '../../components';
import { getEventUserAvatarSrc, PractitionerName } from '../../components/Card/cardData';
import { EVENT_STATUS } from '../../constants';
import { getEventPractitioner, getPractitionerTitleLabel } from '../../utility/practitionerUtils';

const EventPractitioner = ({ event }) => {
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(false);
  const practitioner = getEventPractitioner(event);
  const { status } = event ?? {};
  const isReassignEvent = status === EVENT_STATUS.reassign;
  const { email = '', specialty = '' } = practitioner;

  return (
    <Box direction="row" gap="small" align="center" margin={{ horizontal: 'medium', top: 'small' }}>
      <Box
        style={{
          cursor: isReassignEvent ? 'default' : 'pointer'
        }}
        onClick={
          isReassignEvent
            ? () => {}
            : () => {
                setShowProfile((prevState) => !prevState);
              }
        }
        title={isReassignEvent ? '' : t('common.practitioner.display.profile')}
      >
        <AvatarWithPlaceholder avatarSrc={getEventUserAvatarSrc(event)} />
      </Box>
      <Box>
        <Box
          style={{
            cursor: isReassignEvent ? 'default' : 'pointer'
          }}
          onClick={
            isReassignEvent
              ? () => {}
              : () => {
                  setShowProfile((prevState) => !prevState);
                }
          }
          title={isReassignEvent ? '' : t('common.practitioner.display.profile')}
        >
          <PractitionerName event={event} displayOptions={{ showSubscriberNetwork: false }} />
        </Box>
        <PractitionerProfileModal practitioner={practitioner} show={showProfile} setShow={setShowProfile} />
        {isReassignEvent ? (
          <PractitionerReassignWarning />
        ) : (
          <>
            <Text color="brand">{getPractitionerTitleLabel(specialty)}</Text>
            <EmailLink email={email} mailToLabelId="send.email.to.practitioner.label" />
          </>
        )}
      </Box>
    </Box>
  );
};

export default EventPractitioner;

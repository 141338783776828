import { Grid } from 'grommet';
import styled from 'styled-components';

const ShadowGrid = styled(Grid)`
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(210, 210, 210, 0.08);
  border-radius: 8px;
  &:hover {
    box-shadow: 0px 6px 20px rgba(210, 210, 210, 0.3);
  }
  min-height: 75px;
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
`;

export default ShadowGrid;

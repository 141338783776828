import { useEffect, useState } from 'react';
import { getUser } from '../../utility/api';
import { ascendantSort } from '../../utility/sort';

export function usePopulatedAppointment(eventAppointments, withCanceled, isAdmin = false) {
  const [populatedAppointments, setPopulatedAppointments] = useState([]);

  useEffect(() => {
    let effectIsUp = true;
    if (isAdmin) {
      // ONLY VB admin is allowed to see list of appointments

      (async function () {
        if (eventAppointments) {
          let filteredEventAppointments = [...eventAppointments].reduce((accuAppointments, appointment) => {
            // Remove appointment with employee === null (canceled)
            if (appointment.employee) {
              accuAppointments.push(appointment);
            }

            if (withCanceled) {
              // Add canceled appointment from history array
              const lastCanceledAppointment = appointment.history && appointment.history[0];
              if (lastCanceledAppointment) {
                accuAppointments.push({
                  ...appointment,
                  employee: lastCanceledAppointment.employee,
                  status: lastCanceledAppointment.status,
                  isCanceled: true
                });
              }
            }

            return accuAppointments;
          }, []);

          // Sort and add user object (populated)
          const populatedAppointmentPromises = filteredEventAppointments
            .sort((a, b) => ascendantSort(a.start, b.start))
            .map(async (appointment) => {
              return getUser(appointment.employee).then(({ user }) => {
                return { ...appointment, populatedEmployee: user };
              });
            });
          return await Promise.all(populatedAppointmentPromises);
        }
      })()
        .then((newPopulatedAppointments) => {
          if (effectIsUp) {
            // Only set state if effect is up
            setPopulatedAppointments(newPopulatedAppointments);
          }
        })
        .catch((err) => {
          console.error('usePopulatedAppointment.js/usePopulatedAppointment', err);
        });
    }

    return () => {
      effectIsUp = false;
    };
  }, [isAdmin, eventAppointments, withCanceled]);
  return populatedAppointments;
}

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const locales = { fr };

// eslint-disable-next-line import/no-anonymous-default-export
export default function (date, formatStr) {
  return format(date, formatStr, {
    locale: locales[window.__localeId__]
  });
}

import React, { forwardRef } from 'react';
import { Box, Form, FormField, Select } from 'grommet';
import { FormHead } from 'src/features/auth/LayoutAuth';
import { isRequired } from 'src/utility/formValidation';
import { T, TextInput } from '..';
import PasswordReveal from './PasswordReveal';

/**
 * function that render an input with respect to the type
 * @param {string} type type of input (text, password)
 * @param {string} name name of the input. It will be the name used to register the stored values in formik
 * @param {Object} values formik object values
 * @param {function} handleChange formik handleChange function
 * @param {function} handleBlur formik handleBlur function
 * @param {string} placeholder placeholder to be inserted in the returned input
 * @param {RefObject | undefined} passwordRef reference of the password (can be empty if type text)
 * @param {function | undefined} customHandleChange custom handle change function to replace the handleChange function of formik
 * @param {function | undefined} onSuggestionSelect functions that handles the selection of suggestion of street input (geocoder)
 * @param {array | undefined}  suggestions array of suggestions to be displayed in a dropdown (can be undefined)
 * @returns {ReactElement} return a form input
 */

const inputToRender = (
  type = 'text',
  name,
  values,
  handleChange,
  handleBlur,
  placeholder,
  passwordRef,
  customHandleChange,
  customHandleBlur,
  onSuggestionSelect,
  suggestions,
  options,
  inputRef
) => {
  switch (type) {
    case 'text':
      return (
        <TextInput
          ref={inputRef}
          value={values[name]}
          id={name}
          name={name}
          onChange={customHandleChange || handleChange}
          onBlur={customHandleBlur || handleBlur}
          suggestions={suggestions}
          onSuggestionSelect={onSuggestionSelect}
          placeholder={
            <T size="16px" color="lightGrey">
              {placeholder}
            </T>
          }
        />
      );
    case 'password':
      return (
        <PasswordReveal inputRef={passwordRef}>
          <TextInput
            ref={passwordRef}
            type="password"
            value={values[name]}
            id={name}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={
              <T size="16px" color="lightGrey">
                {placeholder}
              </T>
            }
          />
        </PasswordReveal>
      );

    case 'select':
      return (
        <Select
          id={name}
          name={name}
          size="16px"
          placeholder={
            <T size="16px" color="lightGrey">
              {placeholder}
            </T>
          }
          options={options}
          labelKey="label"
          valueKey={{ key: 'key', reduce: true }}
          value={values[name]}
          onChange={customHandleChange || handleChange}
          dropProps={{
            style: {
              boxShadow: 'rgb(255 255 255) 0px 0px 0px 2px, rgb(0 0 0 / 20%) 0px 0px 2px 3px'
            }
          }}
        />
      );

    default:
      return null;
  }
};

/**
 * @component FormInput render an input with respect of the type. If type is a function
 * then, it returns the function provided in type
 * @param {string} type
 * @param {RefObject | null} passwordRef - only for password input
 * @param {RefObject | null} inputRef - only for Text input
 * @param {string} name
 * @param {string} label
 * @param {string} placeholder
 * @param {boolean} required
 * @param {Object} formik
 * @param {function | undefined} customHandleChange
 * @param {string | undefined} error
 * @param {array} suggestions
 * @param {function | undefined} onSuggestionSelect
 * @returns {ReactElement}
 */
const FormInput = forwardRef(
  ({
    type,
    passwordRef = null,
    name,
    label,
    placeholder,
    required,
    formik,
    customHandleChange,
    customHandleBlur,
    error,
    suggestions,
    onSuggestionSelect,
    options,
    inputRef = null
  }) => {
    let { values, touched, errors, handleChange, handleBlur } = formik;
    if (typeof type === 'function') {
      return type();
    } else {
      return (
        <FormField
          name={name}
          htmlFor={name}
          label={label}
          error={(touched[name] && errors[name]) || error}
          required={required}
        >
          {inputToRender(
            type,
            name,
            values,
            handleChange,
            handleBlur,
            placeholder,
            passwordRef,
            customHandleChange,
            customHandleBlur,
            onSuggestionSelect,
            suggestions,
            options,
            inputRef
          )}
        </FormField>
      );
    }
  }
);

const FormFooter = ({ control }) => {
  return control();
};

const FormFeedback = ({ feedback }) => {
  return feedback();
};

/**
 * @component It returns a complete form with the provided content (label, name, type, customHandleChange, error) with the formik object containing all the logic and the validation schemas
 * @param {Object} formContent
 * @param {Object} formik
 * @param {Object} validationSchemas
 * @returns {reactElement}
 */
const FormBuilder = forwardRef(({ formContent, formik, validationSchemas, ...rest }) => {
  let { header, inputs, feedback, footer } = formContent;
  let { values } = formik;
  return (
    <>
      {header && <FormHead title={header.title} description={header.description} />}
      <Box {...rest}>
        <Form>
          {inputs.map((input, index) => {
            return (
              <FormInput
                key={index}
                type={input.type}
                name={input.name}
                label={input.label}
                placeholder={input.placeholder}
                formik={formik}
                password={input.password}
                passwordRef={input.passwordRef}
                customHandleChange={input.handleChange}
                customHandleBlur={input.handleBlur}
                error={input.error}
                suggestions={input.suggestions}
                onSuggestionSelect={input.onSuggestionSelect}
                required={input.name && isRequired(validationSchemas, input.name, values)}
                options={input.options}
                inputRef={input.ref}
              />
            );
          })}
          {feedback && <FormFeedback feedback={feedback} />}
          <FormFooter control={footer} />
        </Form>
      </Box>
    </>
  );
});

export default FormBuilder;

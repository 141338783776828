import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ResponsiveContext, Text } from 'grommet';
import { LIST_FILTERS, ListFilterSelect, UserSearch, VList } from '../../components';
import { SPECIALTY_STATUS_DISABLED } from '../../features/specialties/specialtiesUtils';
import { getPropByString } from '../../utility/objectUtils';
import { getUserPositionLabel } from '../../utility/userUtils';

// all special chars in regular expression that need to be escaped if found in search text
const escRegExp = RegExp(
  '[' + ['-', '[', ']', '/', '{', '}', '(', ')', '*', '+', '?', '.', '\\', '^', '$', '|'].join('\\') + ']',
  'g'
);

/**
 * Renders a user list that has search and filtering capabilities
 * list based on virtualized VList
 * @param {Object} props - component props
 * @param {Object} props.users - list of users to display (not filtered)
 * @param {string} props.userLabel - Id of localization string for user label (singular)
 * @param {string} props.searchBoxWidth - width of search box
 * @param {string} props.userSearchLabel - search box place holder
 * @param {string[]} props.searchFieldNames - list of user searchable properties
 * @param {number} props.listViewportHeight - list viewport height
 * @param {number} props.itemHeight - list item height
 * @param {Component} props.UserListItem - Component to render a list row for this type of user
 * @param {Object} props.listItemProps - user specific list item properties
 * @param {Component|null} [props.ListLegend=null] - Component if applicable to display list legend on header
 * @param {Component|null} [props.NoData=null] - Component if applicable to render when no data to display in list
 * @param {Object|{}} [props.headerBackground={}] - Component Header background styling object
 * @param {Object} props.filtersList - list of possible filter keys in menu
 * @param {'male'|'female'} [props.itemGender='male'] - determines list item gender (male or female)
 * @returns {Component} Users list component
 */
const UserSearchFilterList = ({
  users,
  userLabel,
  searchBoxWidth,
  userSearchLabel,
  searchFieldNames,
  listViewportHeight,
  itemHeight,
  UserListItem,
  listItemProps,
  ListLegend = null,
  NoData = null,
  headerBackground = {},
  rowBackground,
  filtersList,
  itemGender = 'male'
}) => {
  const { t } = useTranslation();
  const responsiveSize = useContext(ResponsiveContext);
  const isSmallSize = responsiveSize === 'small';
  const [searchFilter, setSearchFilter] = useState('');
  const [listFilter, setListFilter] = useState(LIST_FILTERS.All);
  const { componentTopMargin, listTopMargin, textSize } = useMemo(() => {
    return isSmallSize
      ? { componentTopMargin: 'medium', listTopMargin: 'small', textSize: '16px' }
      : { componentTopMargin: 'none', listTopMargin: 'xsmall', textSize: responsiveSize === 'large' ? '18px' : '16px' };
  }, [isSmallSize, responsiveSize]);

  const filteredUsers = useMemo(() => {
    if (listFilter === LIST_FILTERS.All) {
      return users;
    }
    return users.reduce((acc, user) => {
      // eslint-disable-next-line default-case
      switch (listFilter) {
        case LIST_FILTERS.Validated:
          if (!user.isEmailValid) {
            return acc;
          } else acc.push(user);
          break;
        case LIST_FILTERS.Pending:
          if (user.isEmailValid) {
            return acc;
          } else acc.push(user);
          break;
        // account for possible missing companyCode property
        case LIST_FILTERS.CodeCreated:
          if (!!user?.companyCode) {
            acc.push(user);
          } else return acc;
          break;
        case LIST_FILTERS.CompanyCreated:
          if (!!user?.companyCode) {
            return acc;
          } else acc.push(user);
          break;
        case LIST_FILTERS.CompanyValidated:
          if (user?.admin) {
            if (user.admin.isEmailValid === false) {
              return acc;
            } else {
              acc.push(user);
            }
          } else {
            // no admin user... we don't know validation state
            // default is NO
            return acc;
          }
          break;
        case LIST_FILTERS.CompanyPending:
          if (user?.admin) {
            if (user.admin.isEmailValid) {
              return acc;
            } else {
              acc.push(user);
            }
          } else {
            // no admin user... we don't know validation state
            // default is NOT validated
            acc.push(user);
          }
          break;
        case LIST_FILTERS.Active:
          if (user.isEmailValid && user?.onBoardingCompleted) {
            acc.push(user);
          } else return acc;
          break;
        case LIST_FILTERS.Inactive:
          if (!user.isEmailValid || !user?.onBoardingCompleted) {
            acc.push(user);
          } else return acc;
          break;
        case LIST_FILTERS.Standard:
          if (user?.practitionerType === 'default') {
            acc.push(user);
          } else return acc;
          break;
        case LIST_FILTERS.Network:
          if (user?.practitionerType === 'referent' || user?.practitionerType === 'provider') {
            acc.push(user);
          } else return acc;
          break;
        case LIST_FILTERS.specialtyEnabled:
          if (user.visibility !== SPECIALTY_STATUS_DISABLED) {
            acc.push(user);
          } else return acc;
          break;
        case LIST_FILTERS.specialtyDisabled:
          if (user.visibility === SPECIALTY_STATUS_DISABLED) {
            acc.push(user);
          } else return acc;
          break;
        case LIST_FILTERS.specialtyGroupEnabled:
          if (!user.disabled) {
            acc.push(user);
          } else return acc;
          break;
        case LIST_FILTERS.specialtyGroupDisabled:
          if (user.disabled) {
            acc.push(user);
          } else return acc;
          break;
      }
      return acc;
    }, []);
  }, [users, listFilter]);

  const userData = useMemo(() => {
    if (!searchFilter) return filteredUsers;
    return filteredUsers.reduce((acc, user) => {
      const searchFields = [];
      let zipSearch = false;
      searchFieldNames.forEach((field) => {
        if (field.includes('.')) {
          if (field === 'address.zipcode') {
            // specific processing for address.zipcode
            zipSearch = true;
          } else {
            searchFields.push(getPropByString(user, field) || '');
          }
        } else if (field === 'position') {
          searchFields.push(getUserPositionLabel(user[field] || ''));
        } else {
          searchFields.push(user[field] || '');
        }
      });
      if (searchFields.join(' ').toLowerCase().includes(searchFilter.toLowerCase())) {
        acc.push(user);
      }
      if (zipSearch) {
        // VBZ-1808 fix
        // VBZ-1845 fix
        if (user?.address?.zipcode?.match(new RegExp(`^${searchFilter.replace(escRegExp, '\\$&')}`))) {
          acc.push(user);
        }
      }
      return acc;
    }, []);
  }, [searchFilter, filteredUsers, searchFieldNames]);

  return (
    <>
      <Box
        direction="row"
        pad={{ left: 'small', right: 'small', top: componentTopMargin, bottom: 'none' }}
        align="center"
        fill="horizontal"
      >
        <Box direction="row" align="center" fill="horizontal" pad={{ left: 'small' }} background={headerBackground}>
          <UserSearch
            width={searchBoxWidth}
            filter={searchFilter}
            onFilterChange={setSearchFilter}
            placeHolderStringId={userSearchLabel}
          />
          <ListFilterSelect
            filterState={listFilter}
            userLabel={t(userLabel, { count: 2 })}
            changeFilter={setListFilter}
            filtersList={filtersList}
            itemGender={itemGender}
          />
          <Box direction="row" pad={{ left: 'small' }} justify="between" fill="horizontal" align="center">
            <Box>{ListLegend ? ListLegend : null}</Box>
            <Box pad={{ right: 'small' }}>
              <Text color="brand" size={textSize} weight={700}>
                {
                  <>
                    {userData.length ? userData.length : t('common.label.none', { context: itemGender })}{' '}
                    {t(userLabel, { count: userData.length })}
                  </>
                }
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box align="center" pad={{ left: 'medium', right: 'medium', top: listTopMargin }} fill="horizontal" flex={false}>
        {userData && userData.length > 0 ? (
          <VList
            listData={userData}
            listHeight={listViewportHeight}
            listItemHeight={itemHeight}
            ListItemComponent={UserListItem}
            listItemComponentProps={{ ...listItemProps, listFilter }}
            noScrollSpinner
            rowBackground={rowBackground}
          />
        ) : (
          <>{NoData ? NoData : <Text margin="small">{t('no.data.default.text')}</Text>}</>
        )}
      </Box>
    </>
  );
};

export default UserSearchFilterList;

/**
 * Function that returns a promise only resolved when DOM element is rendered
 *
 * @param {DOMString} selector Any selectors that might target a valid HTML element
 * @returns {Promise<Element>} HTML element
 */
export function waitForDomElement(selector) {
  return new Promise((resolve) => {
    const element = document.querySelector(selector);
    if (element) {
      return resolve(element);
    }

    const observer = new MutationObserver(() => {
      const observerElement = document.querySelector(selector);
      if (observerElement) {
        observer.disconnect();
        resolve(observerElement);
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

import moment from 'moment';

/**
 * Take event dateStart and timeStart string to parse it into Date instance
 * @param {string} dateStart start Date of the event (utc format)
 * @param {string} timeStart start time of the event (HH:MM)
 * @returns {string} string date with the correct hour
 */

export const parseEventDate = (date, time) => {
  const dateArray = date.split('T');
  dateArray.splice(1, 1, time);
  return moment(new Date(dateArray.join('T')))
    .utc()
    .format();
};

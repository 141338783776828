import React from 'react';
import { useTranslation } from 'react-i18next';
import { faBinarySlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

/**
 * Component that displays an error message when data fetching failed
 * @param {Object} props - component props
 * @param {string} props.messageId - id of error message to display
 * @param {string} [props.error=''] - error details
 * @returns {Component}
 */
const FetchError = ({ messageId, error = '' }) => {
  const { t } = useTranslation();
  return (
    <Box direction="row" gap="xsmall" margin={{ vertical: 'large' }} alignSelf="center" justify="center">
      <FontAwesomeIcon icon={faBinarySlash} size="2xl" color={THEME_COLORS['status-error']} />
      <Box direction="column" align="center">
        <Text color={THEME_COLORS['status-error']} textAlign="center" weight={'bold'}>
          {t(messageId)}
        </Text>
        <Text size="small" color="title">
          {error}
        </Text>
      </Box>
    </Box>
  );
};

export default FetchError;

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import {
  createEvent,
  deleteEvent,
  getCompanyEvents,
  getPractitionerPastEvents,
  getPractitionerScheduledEvents,
  getPractitionerWaitingEvents,
  replaceEventPractitioner,
  requestUpdateAppointmentsBulk,
  requestUpdateBooking,
  requestUpdateEvent,
  requestUpdateReservation
} from '../../utility/api';
import { parseEventDate } from '../../utility/parseEventDate';
import { canManageEvent } from '../../utility/permissions';
import { orderEventsByDate } from '../../utility/sort';

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    waitingEvents: [],
    scheduledEvents: [],
    pastEvents: [],
    reassignEvents: [],
    currentEvent: {}
  },
  reducers: {
    updateCurrentEvent: (state, action) => {
      state.currentEvent = action.payload;
    },
    updateWaitingEvents: (state, action) => {
      state.waitingEvents = action.payload;
    },
    updateScheduledEvents: (state, action) => {
      state.scheduledEvents = action.payload;
    },
    updateReassignEvents: (state, action) => {
      state.reassignEvents = action.payload;
    },
    updatePastEvents: (state, action) => {
      state.pastEvents = action.payload;
    }
  }
});

export const {
  updateCurrentEvent,
  updateWaitingEvents,
  updateScheduledEvents,
  updatePastEvents,
  updateReassignEvents
} = eventsSlice.actions;

// Employee Events

const refreshEmployeeWaitingEvents = (events) => (dispatch) => {
  try {
    const todayStart = moment(new Date()).tz('Europe/Paris').startOf('day').utc().format();

    const waitingEvents = events.filter((e) => {
      const eventDateStart = parseEventDate(e.dateStart, e.timeStart);
      return e.practitioner && eventDateStart >= todayStart;
    });
    const orderedEvents = orderEventsByDate(waitingEvents, 'asc');
    dispatch(updateWaitingEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshEmployeeWaitingEvents | ', error);
    throw error;
  }
};

const refreshEmployeeScheduledEvents = (events) => (dispatch, getState) => {
  try {
    const todayStart = moment(new Date()).tz('Europe/Paris').startOf('day').utc().format();
    const { auth } = getState();
    const scheduledEvents = events.filter((e) => {
      const eventDateStart = parseEventDate(e.dateStart, e.timeStart);
      return (
        e.practitioner &&
        eventDateStart >= todayStart &&
        e.appointments.some((item) => item.employee === auth?.user?._id)
      );
    });
    const orderedEvents = orderEventsByDate(scheduledEvents, 'asc');
    dispatch(updateScheduledEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshEmployeeScheduledEvents | ', error);
    throw error;
  }
};

const refreshEmployeePastEvents = (events) => (dispatch, getState) => {
  try {
    const todayStart = moment(new Date()).tz('Europe/Paris').startOf('day').utc().format();

    const { auth } = getState();
    const pastEvents = events.filter((e) => {
      const eventDateStart = parseEventDate(e.dateStart, e.timeStart);
      return eventDateStart < todayStart && e?.appointments?.some((item) => item.employee === auth?.user?._id);
    });
    const orderedEvents = orderEventsByDate(pastEvents, 'desc');
    dispatch(updatePastEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshEmployeePastEvents | ', error);
    throw error;
  }
};

export const fetchAndRefreshEmployeeEvents = () => async (dispatch) => {
  try {
    const { events } = await getCompanyEvents();
    dispatch(refreshEmployeeWaitingEvents(events));
    dispatch(refreshEmployeeScheduledEvents(events));
    dispatch(refreshEmployeePastEvents(events));
    return { status: 'success' };
  } catch (error) {
    console.error('eventsSlice.js/fetchAndRefreshEmployeeEvents ', error);
    throw error;
  }
};

// Practitioner Events

export const fetchPractitionerWaitingEvents = (radius) => async (dispatch) => {
  try {
    const { events } = await getPractitionerWaitingEvents(radius);
    dispatch(updateWaitingEvents(events));
  } catch (error) {
    console.error('eventsSlice.js/fetchPractitionerWaitingEvents | ', error);
    throw error;
  }
};

export const fetchPractitionerScheduledEvents = () => async (dispatch) => {
  try {
    const { events } = await getPractitionerScheduledEvents();
    dispatch(updateScheduledEvents(events));
  } catch (error) {
    console.error('eventsSlice.js/fetchPractitionerScheduledEvents | ', error);
    throw error;
  }
};

export const fetchPractitionerPastEvents = () => async (dispatch) => {
  try {
    const { events } = await getPractitionerPastEvents();
    dispatch(updatePastEvents(events));
  } catch (error) {
    console.error('eventsSlice.js/fetchPractitionerPastEvents | ', error);
    throw error;
  }
};

export const fetchAndRefreshPractitionerEvents = () => async (dispatch) => {
  try {
    await dispatch(fetchPractitionerWaitingEvents());
    await dispatch(fetchPractitionerScheduledEvents());
    await dispatch(fetchPractitionerPastEvents());
    return { status: 'success' };
  } catch (error) {
    console.error('eventsSlice.js/fetchAndRefreshPractitionerEvents | ', error);
    throw error;
  }
};

// Company Events

const refreshCompanyWaitingEvents = (events) => (dispatch) => {
  try {
    const todayStart = moment(new Date()).tz('Europe/Paris').startOf('day').utc().format();

    const waitingEvents = events.filter((e) => !e.practitioner && e.dateStart >= todayStart);
    const orderedEvents = orderEventsByDate(waitingEvents, 'asc');
    dispatch(updateWaitingEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshCompanyWaitingEvents | ', error);
    throw error;
  }
};

export const fetchCompanyWaitingEvents = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    if (auth?.user && canManageEvent(auth.user)) {
      const { events } = await getCompanyEvents();
      dispatch(refreshCompanyWaitingEvents(events));
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchCompanyWaitingEvents | ', error);
    throw error;
  }
};

const refreshCompanyScheduledEvents = (events) => (dispatch) => {
  try {
    const todayStart = moment(new Date()).tz('Europe/Paris').startOf('day').utc().format();

    const scheduledEvents = events.filter((e) => e.practitioner && e.dateStart >= todayStart);
    const orderedEvents = orderEventsByDate(scheduledEvents, 'asc');
    dispatch(updateScheduledEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshCompanyScheduledEvents | ', error);
    throw error;
  }
};

export const fetchCompanyScheduledEvents = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    if (auth?.user && canManageEvent(auth.user)) {
      const { events } = await getCompanyEvents();
      dispatch(refreshCompanyScheduledEvents(events));
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchCompanyScheduledEvents | ', error);
    throw error;
  }
};

const refreshCompanyPastEvents = (events) => (dispatch) => {
  try {
    const todayStart = moment(new Date()).tz('Europe/Paris').startOf('day').utc().format();

    const pastEvents = events.filter((e) => e.dateStart < todayStart);
    const orderedEvents = orderEventsByDate(pastEvents, 'desc');
    dispatch(updatePastEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshCompanyPastEvents | ', error);
    throw error;
  }
};

export const fetchCompanyPastEvents = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    if (auth?.user && canManageEvent(auth.user)) {
      const { events } = await getCompanyEvents();
      dispatch(refreshCompanyPastEvents(events));
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchCompanyPastEvents | ', error);
    throw error;
  }
};

export const fetchAndRefreshCompanyEvents = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    if (auth?.user && canManageEvent(auth.user)) {
      const { events } = await getCompanyEvents();
      await dispatch(refreshCompanyWaitingEvents(events));
      await dispatch(refreshCompanyScheduledEvents(events));
      await dispatch(refreshCompanyPastEvents(events));
    }
    return { status: 'success' };
  } catch (error) {
    console.error('eventsSlice.js/fetchAndRefreshCompanyEvents | ', error);
    throw error;
  }
};

// Events manipulation

export const submitNewEvent = (eventData, refreshEvents) => async (dispatch) => {
  try {
    const { event } = await createEvent(eventData);
    return { status: 'success', data: { event } };
  } catch (error) {
    console.error('eventsSlice.js/submitNewEvent | ', error);
    return { status: 'error', data: error.message };
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

export const updateEvent = (id, data, refreshEvents) => async (dispatch) => {
  try {
    const { event } = await requestUpdateEvent(id, data);
    return { status: 'success', data: { event } };
  } catch (error) {
    console.error('eventsSlice.js/updateEvent | ', error);
    return { status: 'error', data: error.message };
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

export const updateAppointmentsBulk = (data, refreshEvents) => async (dispatch) => {
  try {
    const { status } = await requestUpdateAppointmentsBulk(data);
    return { status };
  } catch (error) {
    console.error('eventsSlice.js/updateEvent | ', error);
    throw error;
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

export const updateBooking = (id, data, refreshEvents) => async (dispatch) => {
  const { slot, action } = data;
  try {
    // reason only valid for unbook action case
    // reason = 0 => unbook done by practitioner
    // otherwise code of reason as provided by beneficiary
    const { event } = await requestUpdateBooking(id, action, slot, data);
    return { status: 'success', data: { event } };
  } catch (error) {
    console.error('eventsSlice.js/updateBooking | ', error);
    return { status: 'error', data: error.message };
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

export const updateReservation =
  (id, { slots }, refreshEvents) =>
  async (dispatch) => {
    try {
      const { event } = await requestUpdateReservation(id, slots);
      return { status: 'success', data: { event } };
    } catch (error) {
      console.error('eventsSlice.js/updateReservation | ', error);
      return { status: 'error', data: error.message };
    } finally {
      if (refreshEvents) {
        dispatch(refreshEvents());
      }
    }
  };

export const removeEvent = (eventId, refreshEvents) => async (dispatch) => {
  try {
    await deleteEvent(eventId);
  } catch (error) {
    throw error;
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

/**
 * triggers action to replace practitioner on given event
 * ie performs API request
 * @param {string} eventId - event id
 * @param {string} practitionerId - id of new practitioner
 * @param {string} [providerId] - Id of new provider if practitioner is of referent type
 */
export const replaceEvent = (eventId, practitionerId, providerId) => async (dispatch) => {
  try {
    await replaceEventPractitioner(eventId, practitionerId, providerId);
  } catch (error) {
    throw error;
  }
};

export default eventsSlice.reducer;

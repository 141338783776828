import React, { useContext, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import { Box, ResponsiveContext } from 'grommet';
import { THEME_COLORS } from '../../config/theme';

/**
 * Default List header UI configuration object (text props & background color)
 */
const headerStyleDefault = {
  fontSize: '18px', // header text size
  color: THEME_COLORS.white, // header text color
  fontWeight: 400, // header text weight
  backgroundColor: THEME_COLORS.brand, // header background color
  borderRadius: '6px',
  paddingRTop: '10px',
  paddingBottom: '10px'
};

/**
 * Default List row UI styles
 */
export const rowStyleDefault = {
  fontSize: '16px',
  color: THEME_COLORS.title,
  borderRadius: '6px'
};

/**
 * Default List row UI configuration object
 */
export const rowBackgroundConfig = {
  alternateRowBackground: true, // if true odd & even rows will have different background colors
  oddRowColor: THEME_COLORS.greyBackground, // odd rows background color
  evenRowColor: THEME_COLORS.white // even rows background color
};

/**
 * renders the Static (fixed) List header (text only)
 * @param {Object}  props The component properties object
 * @param {Component}  props.ListItemComponent The component to use to render a single list element
 * @param {Object}  props.headerConfig The list columns headers configuration object
 * @param {boolean} listHasScrollbar Associated list has a scrollbar
 * @param {Object}  props.ListItemProps - Additional props to pass to the ListItemComponent
 * @returns {React.Component}
 */
const ListHeader = ({ ListItemComponent, headerConfig = {}, listHasScrollbar, ListItemProps }) => {
  const responsiveSize = useContext(ResponsiveContext);

  return (
    <>
      {responsiveSize !== 'small' && (
        <Box
          direction="row"
          fill="horizontal"
          pad={{ vertical: '10px' }}
          style={{
            ...headerStyleDefault,
            ...headerConfig,
            paddingRight: listHasScrollbar ? '15px' : '0' // compensate scrollbar shifting
          }}
        >
          <ListItemComponent isHeaderRow={true} {...ListItemProps} />
        </Box>
      )}
    </>
  );
};

/**
 * Implements a virtualized List of items with a fixed header above
 * @param {Object}  props The component properties object
 * @param {Object[]}  props.listData The list items array
 * @param {Object}  props.headerConfig - the list header configuration object (see default above)
 * @param {number}  props.listHeight - the total list viewport height
 * @param {number}  props.listWidth - the total list viewport width
 * @param {number}  [props.listItemHeight] - the list item height in px
 * @param {Object}  [props.rowBackground] - list row background configuration object (see default above)
 * @param {Component}  props.ListItemComponent - the component to use to render a single list element
 * @param {Object}  props.listItemComponentProps - Additional props to pass to the ListItemComponent
 * @param {boolean} props.noHeader Do not display the list header
 * @returns {React.Component}
 */
const VList = ({
  listData,
  headerConfig = headerStyleDefault,
  listHeight,
  listItemHeight,
  listWidth = '100%',
  rowBackground = rowBackgroundConfig,
  ListItemComponent,
  listItemComponentProps = {},
  noHeader
}) => {
  // renders List Item using Grid component
  const GridListItemRenderer = ({ index, style, data, isScrolling }) => {
    const item = data[index];
    const rowBackgroundColor = rowBackground.alternateRowBackground
      ? index % 2
        ? rowBackground.oddRowColor
        : rowBackground.evenRowColor
      : rowBackground.evenRowColor;
    const newStyle = {
      ...style,
      ...rowStyleDefault,
      ...{
        height: `${listItemHeight}px`,
        backgroundColor: rowBackgroundColor
      }
    };
    return (
      <Box key={item?._id || index} direction="row" align="center" style={newStyle}>
        <ListItemComponent item={item} {...listItemComponentProps} />
      </Box>
    );
  };

  const outerListRef = useRef(null);
  // TODO: review, doesn't seem necessary anymore...
  /* const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    if (outerListRef?.current?.scrollHeight && outerListRef.current?.clientHeight) {
      setHasScrollbar(outerListRef.current.scrollHeight > outerListRef.current?.clientHeight);
    }
  }, [listData, setHasScrollbar, outerListRef]); */

  return (
    <Box fill>
      {!noHeader && (
        <ListHeader
          ListItemComponent={ListItemComponent}
          headerConfig={headerConfig}
          listHasScrollbar={false /* hasScrollbar */}
          ListItemProps={listItemComponentProps}
        />
      )}
      <FixedSizeList
        outerRef={outerListRef}
        height={listHeight} // height of list in px
        itemData={listData}
        itemCount={listData.length}
        itemSize={listItemHeight} // height in px of one list item
        width={listWidth}
      >
        {GridListItemRenderer}
      </FixedSizeList>
    </Box>
  );
};

export default VList;

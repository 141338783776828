import i18n from 'i18next';
import { UNLIMITED_CATCHMENT_VALUE, VALIDATION_STATUS } from '../constants';
import { Enum } from '../utility/enum';
import { canRegisterEvent } from '../utility/permissions';

/**
 * checks if user is a pro referent
 * a Referent practitioner has practitionerType property set to "referent"
 * and has ability to register to events
 * @param {Object} user  practitioner to check
 * @returns {Boolean} true if practitioner is a pro referent
 */
export const isUserProReferent = (user) => {
  return user?.practitionerType === 'referent' && canRegisterEvent(user);
};

/**
 * checks if user is a provider practitioner
 * a Provider practitioner has a proReferent property
 * and does NOT have ability to register to events
 * @param {Object} user  practitioner to check
 * @returns {Boolean} true if practitioner is a provider practitioner
 */
export const isUserProvider = (user) => {
  if (user?.practitionerType && user.practitionerType === 'provider') {
    return true;
  }
  return !!user?.proReferent?.length && !canRegisterEvent(user);
};

/**
 * checks if user is a practitioner with unlimited catchment area
 * referent and provider practitioners have unlimited catchment by nature
 * @param {Object} user practitioner to check
 * @returns {Boolean} true if practitioner has unlimited catchment set
 */
export const isUnlimitedCatchmentPractitioner = (user) => {
  return (
    isUserProReferent(user) ||
    isUserProvider(user) ||
    (user.role === UserRole.practitioner && user?.catchmentArea === UNLIMITED_CATCHMENT_VALUE)
  );
};

// Implement Enum like list of possible user roles
export const UserRole = new Enum(
  { key: 'admin', value: 'admin' },
  { key: 'adminCompany', value: 'admin-company' },
  { key: 'practitioner', value: 'practitioner' },
  { key: 'employee', value: 'employee' }
);

// Implement Enum like list of possible practitioner types
export const PractitionerType = new Enum(
  { key: 'default', value: 'default' }, // pro indépendant
  { key: 'referent', value: 'referent' }, // pro referent
  { key: 'provider', value: 'provider' } // pro intervenant
);

// Implement Enum like list of possible company users counters types
export const CompanyUsersCounter = new Enum(
  { key: 'none', value: 'none' }, // no users counter expected
  { key: 'total', value: 'total' }, // only total users count (validated or not)
  { key: 'both', value: 'both' } // both total & validated users counters
);

export const getPractitionerTypeLabel = (practitionerType) => {
  switch (practitionerType) {
    case PractitionerType.referent:
      return i18n.t('practitioner.referent.short.label');
    case PractitionerType.provider:
      return i18n.t('practitioner.provider.short.label');
    default:
      return i18n.t('practitioner.default.short.label');
  }
};

/**
 * returns label of practitioner type
 * (only if activated)
 * @param {Object} practitioner practitioner to check
 * @returns {String} practitioner type label or "" if not activated
 */
export const getPractitionerTypeLabelDetailed = (practitioner) => {
  const { status, network, networkName } = practitioner;
  if (status !== VALIDATION_STATUS.activated) {
    return status === VALIDATION_STATUS.refused
      ? i18n.t('admin.vb.refused.practitioner.label')
      : i18n.t('admin.vb.pending.practitioner.label');
  }
  switch (practitioner?.practitionerType) {
    case PractitionerType.referent:
      return i18n.t('admin.vb.referent.practitioner.label');
    case PractitionerType.provider:
      let providerLabel = i18n.t('admin.vb.provider.practitioner.label');
      if (network && networkName) {
        providerLabel = i18n.t('admin.vb.network.name.provider.practitioner.label', { networkName });
      }
      return providerLabel;
    default:
      return i18n.t('common.user.role.practitioner.default');
  }
};

/**
 * Enum for uploaded file kind
 * @readonly
 * @enum {string}
 */
export const FileUploadKind = {
  avatar: 'avatar',
  diploma: 'diploma',
  idDocument: 'idDocument',
  onboardingPackage: 'onboardingPackage',
  map: 'map',
  other: 'other'
};

/**
 * Enum for uploaded file target
 * @readonly
 * @enum {string}
 */
export const FileUploadTargetType = { user: 'user', company: 'company' };

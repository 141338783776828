import React, { useCallback, useRef, useState } from 'react';
import Tree from 'react-d3-tree';
import { useTranslation } from 'react-i18next';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as d3 from 'd3';
import { Avatar, Box, Text } from 'grommet';
import { capitalize } from 'lodash';
import { THEME_COLORS } from '../../config/theme';

/**
 * Custom node element to be rendered in tree component
 * @property {Object} [props.nodeDatum] -  Data provided by the custom node rendering callback
 * @property {boolean} [props.adminDashboard] - Determine if user is in admin Viabeez context or not
 * @property {callback} [props.handleClick] - Callback that determine behavior when clicking on a node
 * @property {callback} [props.mouseOver] - Callback that determine behavior on hovering a node
 * @property {callback} [props.mouseOut] - Callback that determine behavior when mouse is leaving the node box
 * @returns {React.ReactElement} - Foreign object element allowing html injection in svg
 */
const renderForeignObjectNode = ({ nodeDatum, adminDashboard, handleClick, mouseOver, mouseOut }) => {
  const {
    name,
    attributes: { id, isAdmin, logo, isMySite },
    __rd3t: { depth }
  } = nodeDatum;
  const siteBorder = isMySite
    ? { color: THEME_COLORS.brand, style: 'dashed' }
    : isAdmin
      ? { color: THEME_COLORS.brand }
      : { color: THEME_COLORS.red };
  siteBorder.size = '2px';

  const nodeSize = { x: 200, y: 200 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -100,
    y: depth === 0 ? -120 : 20
  };

  return (
    <g>
      <foreignObject {...foreignObjectProps}>
        <Box
          direction="column"
          align="center"
          gap="small"
          key={id}
          id={id}
          width="200px"
          style={{ cursor: handleClick ? 'pointer' : 'default', zIndex: -1 }}
          onMouseOver={(event) => mouseOver(nodeDatum, event)}
          onMouseLeave={(event) => mouseOut(event)}
          onClick={adminDashboard ? () => handleClick(id) : null}
        >
          {logo ? (
            <Avatar size="50px" src={logo} style={{ border: '1px solid' }} />
          ) : (
            <FontAwesomeIcon icon={faBuilding} color={isAdmin ? THEME_COLORS.brand : THEME_COLORS.red} size="3x" />
          )}
          <Box align="center" round border={siteBorder} pad="small">
            <Text
              size="small"
              weight="bold"
              textAlign="center"
              truncate="tip"
              tip={{
                content: <Text size="xsmall">{name}</Text>
              }}
            >
              {name}
            </Text>
          </Box>
        </Box>
      </foreignObject>
    </g>
  );
};

/**
 * Renders the current site sites hierarchical view
 * @param {Object} props - component props
 * @param {Object} props.sites - object describing current site sites
 * @param {Object} props.user - connected user object or company admin (in case of VB admin view)
 * @param {boolean} props.adminDashboard - true if component renders in VB admin dashboard
 * @param {Function} props.handleClick - click handler
 * @param {string|null} props.logo - site logo url if defined
 * @returns {React.ReactElement} the Sites hierarchical view component
 */
const CompanySitesDiagram = ({ sites, user, adminDashboard = false, handleClick, logo }) => {
  const { t } = useTranslation();
  const tooltipRef = useRef();
  const treeRef = useRef();
  const isAdmin = useCallback(
    (site) => {
      return user?.sites?.includes(site) || user?.company?._id === site;
    },
    [user?.company?._id, user?.sites]
  );

  const isMySite = useCallback(
    (site) => {
      return adminDashboard ? false : user?.company?._id === site;
    },
    [adminDashboard, user?.company?._id]
  );

  /**
   * Generate the object needed to render the tree diagram
   * @param {[]} sites - Array of company sites returned by the API
   * @returns  {Object}
   */
  const getOrganizationTree = (sites) => {
    const tree = [];
    const map = new Map(
      sites.map((e) => {
        const isUserSite = isMySite(e._id);
        return [
          e._id,
          {
            name: e.name,
            children: [],
            attributes: { id: e._id, isAdmin: isAdmin(e._id), isMySite: isUserSite, logo: isUserSite ? logo : null }
          }
        ];
      })
    );
    for (const { parent = null, _id } of sites) {
      if (parent === null || map.get(parent) == null) {
        tree.push(map.get(_id));
      } else {
        map.get(parent).children.push(map.get(_id));
      }
    }
    return tree;
  };

  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 4 });
    }
  }, []);

  /**
   * Generate custom SVG path instruction to draw link between nodes
   * @param {Object} linkDatum - link datum passed by Tree component
   * @param {string} orientation - Orientation of the Tree chart
   * @returns {string} - SVG path instructions
   */
  const customPathFunction = (linkDatum, orientation) => {
    const { source, target } = linkDatum;
    const hasChildren = source.children.length > 0;
    const deltaY = target.y - source.y;
    return orientation === 'horizontal'
      ? `M${source.y},${source.x} H${source.y + deltaY / 2} V${target.x} H${target.y}`
      : `M${source.x},${hasChildren && source.depth > 0 ? source.y + 150 : source.y} V${source.y + deltaY / 1.8} H${target.x} V${target.y}`;
  };

  const mouseOver = (node, event) => {
    const {
      attributes: { isAdmin }
    } = node;
    const ttText = adminDashboard
      ? isAdmin
        ? t('page.admin.sites.site.managed.by.admin', {
            name: `${capitalize(user?.firstName)} ${user?.lastName?.toUpperCase()}`
          })
        : t('page.admin.sites.site.managed.by.other.admin')
      : isAdmin
        ? t('page.admin.sites.site.you.managed')
        : t('page.admin.sites.site.not.managed');
    const ttContent = `<span style="font-size:14px;">${ttText}</span>`;
    const { x, bottom, width } = event.target.getBoundingClientRect();
    const tooltipDiv = tooltipRef.current;
    if (tooltipDiv) {
      d3.select(tooltipDiv).transition().duration(200).style('opacity', 0.9);
      const tooltipElementWidth = d3.select(tooltipDiv).html(ttContent).node().getBoundingClientRect().width;
      d3.select(tooltipDiv)
        .style('left', x + width / 2 - tooltipElementWidth / 2 + 'px')
        .style('top', bottom + 'px');
    }
  };

  const mouseOut = (event) => {
    event.stopPropagation();
    const tooltipDiv = tooltipRef.current;
    if (tooltipDiv) {
      d3.select(tooltipDiv).transition().duration(500).style('opacity', 0);
    }
  };

  return (
    <div style={{ height: '75vh', width: '100%' }} ref={containerRef}>
      <Box
        style={{ position: 'absolute', color: 'white', opacity: 0 }}
        ref={tooltipRef}
        background={'brand'}
        pad="small"
        round="xsmall"
      ></Box>
      <Tree
        data={getOrganizationTree(sites)}
        orientation="vertical"
        nodeSize={{ x: 250, y: 350 }}
        translate={translate}
        renderCustomNodeElement={({ nodeDatum }) =>
          renderForeignObjectNode({ nodeDatum, adminDashboard, handleClick, mouseOut, mouseOver })
        }
        pathFunc={customPathFunction}
        ref={treeRef}
      />
    </div>
  );
};

export default CompanySitesDiagram;

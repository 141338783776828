import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { THEME_COLORS } from '../../config/theme';
import 'react-popper-tooltip/dist/styles.css';

/**
 * A generic tooltip component using 'react-popper-tooltip' library. Surround your element with it, add a content and enjoy
 * @param {Object} props
 * @param {string|React.ReactElement} [props.content=''] The text or Element displayed in the tooltip , if empty no tooltip displayed
 * @param {string} [props.placement='auto'] Where to place the tooltip : 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end'
 * @param {number[]} [props.arrowOffset=[0, 8]] the tooltip X/Y arrow offset
 * @param {string} [props.textColor=THEME_COLORS.white] The content text color
 * @param {string} [props.textSize='16px'] The content text size
 * @param {string} [props.backgroundColor=THEME_COLORS.brand] The content background color
 * @param {boolean} [props.disabled=false] Disable the display of the tooltip
 * @param {React.ReactElement} [props.children] The react element pointed by the tooltip
 * @param {Object} [props.dropStyle={}] - optional tooltip drop container stylesheet
 * @returns {React.ReactElement}
 */
export default function ToolTip({
  content = '',
  placement = 'auto',
  arrowOffset = [0, 8],
  textColor = THEME_COLORS.white,
  textSize = '16px',
  backgroundColor = THEME_COLORS.brand,
  disabled = false,
  forceHide = false,
  children,
  maxWidth = 'none',
  dropStyle = {}
}) {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement,
    offset: arrowOffset
  });

  const displayTooltip = !forceHide && !disabled && !!content && !!visible && !!children;

  return (
    <>
      <span className="tooltip-trigger-node" ref={setTriggerRef}>
        {children ?? ''}
      </span>
      {displayTooltip && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container',
            style: {
              ...dropStyle,
              fontSize: textSize,
              '--tooltipBackground': backgroundColor,
              '--tooltipColor': textColor,
              '--tooltipBorder': backgroundColor
            }
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {content}
        </div>
      )}
    </>
  );
}

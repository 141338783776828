import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleXmark, faRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Text } from 'grommet';
import { THEME_COLORS } from '../../../config/theme';

/**
 * Callback that controls the refresh action
 *
 * @callback onRefresh
 * @return {void}
 */

/**
 * Component displayed when there is an error on chart/dashboard with the ability of reloading the targeted chart/dashboard
 *
 * @param {onRefresh} [prop.onRefresh] define actions when refreshing
 * @returns {React.Component}
 */
const MongoChartError = ({ onRefresh, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box gap="small" {...props}>
      <Box fill justify="center">
        <FontAwesomeIcon icon={faCircleXmark} color={THEME_COLORS['status-error']} size="2xl" />
        <Text weight={'bold'} textAlign="center" color="status-error">
          {t('component.chart.loading.error')}
        </Text>
      </Box>
      <Button
        label={
          <Box direction="row" align="center" gap="small">
            <FontAwesomeIcon icon={faRotate} size="lg" />
            <Text>{t('common.label.reload')}</Text>
          </Box>
        }
        primary
        size="small"
        style={{ width: 'fit-content' }}
        alignSelf="center"
        onClick={onRefresh}
      />
    </Box>
  );
};

export default MongoChartError;

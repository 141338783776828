import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { THEME_COLORS } from '../../config/theme';

const StyledMenuItemIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 28px;
`;

/**
 * Menu item icon component for the nav bars
 * @component
 * @returns {component} The menu icon component
 */
const MenuItemIcon = ({ faIcon }) => {
  return (
    <StyledMenuItemIcon>
      <FontAwesomeIcon icon={faIcon} color={THEME_COLORS.brand} size="lg" />
    </StyledMenuItemIcon>
  );
};

export default MenuItemIcon;

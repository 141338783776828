import React, { useCallback, useRef, useState } from 'react';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Calendar, Drop, TextInput } from 'grommet';
import moment from 'moment';
import { theme, THEME_COLORS } from '../../config/theme';
import './DateInput.css';

const DateInput = ({ placeholder, onSelectDate, size = '14px', value = '' }) => {
  const targetRef = useRef();
  const [selectedDate, setSelectedDate] = useState(moment().tz('UTC').format('YYYY-MM-DD'));
  const [inputValue, setInputValue] = useState(value ? moment(value).format('DD/MM/YYYY') : '');
  const [showCalendar, setShowCalendar] = useState(false);
  const handleFocusInput = useCallback(() => {
    setShowCalendar(true);
  }, []);
  const handleHideDrop = useCallback(() => {
    setShowCalendar(false);
  }, []);
  const handleClickClear = useCallback(() => {
    setSelectedDate(moment().tz('UTC').format('YYYY-MM-DD'));
    setInputValue('');
    onSelectDate('');
    targetRef.current.value = '';
  }, [onSelectDate]);
  const handleSelectDate = useCallback(
    (value) => {
      setSelectedDate(value);
      setInputValue(moment(value).format('DD/MM/YYYY'));
      setShowCalendar(false);
      onSelectDate(value);
    },
    [onSelectDate]
  );

  return (
    <div style={{ position: 'relative' }}>
      <Box
        direction="row"
        align="center"
        pad={{ right: 'xsmall' }}
        style={{ border: `solid 1px ${theme.global.colors.text}`, borderRadius: '4px' }}
      >
        <TextInput
          id="dateInputField"
          size={size}
          ref={targetRef}
          value={inputValue}
          style={{ fontWeight: 800, color: theme.global.colors.text }}
          placeholder={placeholder}
          onFocus={handleFocusInput}
          plain
        />
        <FontAwesomeIcon
          icon={faXmark}
          color={THEME_COLORS.brand}
          size="lg"
          style={{ cursor: 'pointer' }}
          onClick={handleClickClear}
        />
      </Box>
      {showCalendar && (
        <Drop align={{ top: 'bottom', left: 'left' }} target={targetRef.current} onClickOutside={handleHideDrop}>
          <Calendar
            margin="small"
            onSelect={handleSelectDate}
            date={selectedDate}
            daysOfWeek={true}
            firstDayOfWeek={1}
            showAdjacentDays={true}
          />
        </Drop>
      )}
    </div>
  );
};

export default DateInput;

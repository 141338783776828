import { createSlice } from '@reduxjs/toolkit';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    list: [],
    showNotifications: false
  },
  reducers: {
    addOrUpdateInList: (state, action) => {
      if (state.list.find((e) => e._id === action.payload._id)) {
        state.list = state.list.map((l) => (l._id === action.payload._id ? action.payload : l));
      } else {
        state.list = [action.payload, ...state.list];
      }
    },
    updateList: (state, action) => {
      state.list = action.payload;
    },
    showNotifications: (state, action) => {
      state.showNotifications = action.payload;
    }
  }
});

export const { addOrUpdateInList, updateList, showNotifications } = notificationsSlice.actions;

export const sendSocketEvent = ({ name, data }) => {
  return {
    type: 'EMIT_SOCKET',
    payload: { name, data }
  };
};

export default notificationsSlice.reducer;

import React from 'react';
import { Box, Grid, Text } from 'grommet';

const MainMenuItem = (props) => {
  const areas = [['icon', 'label']];
  const columns = ['35px', 'auto'];
  const rows = ['auto'];
  return (
    <Box gap="small" focusIndicator background={props.activated ? 'brand' : 'white'} round="4px" pad="small" {...props}>
      <Grid align="center" areas={areas} columns={columns} rows={rows} width="100%" pad={{ right: 'small' }}>
        <Box gridArea="icon">{props.icon}</Box>
        <Box gridArea="label" pad={{ left: 'small' }}>
          <Text color={props.activated ? 'white' : 'title'}>{props.children}</Text>
        </Box>
      </Grid>
    </Box>
  );
};

export default MainMenuItem;

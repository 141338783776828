import React from 'react';
import { Box, Text } from 'grommet';

/**
 * @description Implements a customizable Icon with badge value if not zero
 * @description If badge value >= 10, "10+" text is displayed instead of actual value
 * @component
 * @property {Object} icon The icon component to render
 * @property {Number} badgeValue the badge value, zero if no badge should show
 * @property {String} badgeColor color to use for badge background color (defaults to accent color)
 * @property {String} badgeTextColor color to use for badge text color (defaults to white)
 */
const BadgeIcon = ({ icon, badgeValue = 0, badgeColor = 'accent', badgeTextColor = 'white', ...props }) => {
  const isOutOfRange = badgeValue >= 10;

  return (
    <Box style={{ position: 'relative' }} {...props}>
      {icon}
      {badgeValue > 0 && (
        <Box
          width={isOutOfRange ? '20px' : '15px'}
          height={isOutOfRange ? '20px' : '15px'}
          background={badgeColor}
          style={{
            position: 'absolute',
            top: '3px',
            right: '-3px',
            borderRadius: '100px'
          }}
          align="center"
          justify="center"
        >
          <Text color={badgeTextColor} size="10px">
            {isOutOfRange ? '10+' : badgeValue}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default BadgeIcon;

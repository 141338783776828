import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'grommet';
import moment from 'moment-timezone';
import styled from 'styled-components';

const NotificationElement = ({ handleClick, generateText, notification: { isViewed, createdAt }, size, ...rest }) => {
  const notificationDiff = useMemo(() => {
    const now = moment(new Date());
    let diff = moment.duration(now.diff(createdAt));
    let minutes = Math.floor(diff.asMinutes());
    let hours = Math.floor(diff.asHours());
    let days = Math.floor(diff.asDays());
    return { days, hours, minutes };
  }, [createdAt]);

  const { t } = useTranslation();
  return (
    <Box
      direction="row"
      align="center"
      gap="15px"
      pad="small"
      flex={false}
      border={{ color: '#EFEFEF', side: 'bottom', size: '1px' }}
      style={{ borderRadius: '4px', cursor: 'pointer' }}
      onClick={handleClick}
      {...rest}
    >
      <Box flex={false} width="10px" height="10px">
        {!isViewed && <Box width="8px" height="8px" background="brand" style={{ borderRadius: '100px' }}></Box>}
      </Box>
      <Box>
        <Text color="black" size="10px" weight={400}>
          {generateText()}
        </Text>
        <Text size={size === 'small' ? '10px' : '12px'} margin={{ top: '5px' }}>
          {notificationDiff.days > 0
            ? `Il y a ${notificationDiff.days} ${t('common.days', { count: notificationDiff.days })}`
            : notificationDiff.hours > 0
              ? `Il y a ${notificationDiff.hours} ${t('common.hours', { count: notificationDiff.hours })}`
              : notificationDiff.minutes > 0
                ? `Il y a ${notificationDiff.minutes} ${t('common.minutes', { count: notificationDiff.minutes })}`
                : t('common.now.label')}
        </Text>
      </Box>
    </Box>
  );
};

const StyledElement = styled(NotificationElement)`
  &&:hover {
    background-color: #efefef;
  }
`;

export default StyledElement;

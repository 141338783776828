import React from 'react';
import { Box } from 'grommet';

const IconListItem = (props) => {
  return (
    <Box gap="small" focusIndicator {...props}>
      <Box direction="row" gap="small" align="center">
        {props.icon}
        {props.children}
      </Box>
      <Box fill="horizontal" border={{ color: '#EEEEEE', size: '1px' }} />
    </Box>
  );
};

export default IconListItem;

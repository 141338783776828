import { orderBy } from 'lodash';

export const ascendantSort = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

/**
 * Order events by specific order
 * @function
 * @param {array} events The events to sort
 * @param {'asc' | 'desc'} order The sort order
 * @returns {array} Returns the new sorted array
 */
export const orderEventsByDate = (events, order) => orderBy(events, [(event) => new Date(event.dateStart)], [order]);

import React from 'react';
import { useTranslation } from 'react-i18next';
import PasswordChecklist from 'react-password-checklist';
import { THEME_COLORS } from '../../../config/theme';
import { PWD_ALLOWED_SPECIAL_CHARS, PWD_MIN_LENGTH, PWD_SPECIAL_CHAR_REGEXP } from '../../../utility/formValidation';
import './PasswordValidator.css';

const PasswordValidator = ({ value, valueAgain }) => {
  const { t } = useTranslation();
  return (
    <PasswordChecklist
      rules={['minLength', 'capital', 'lowercase', 'specialChar', 'number', 'match']}
      minLength={PWD_MIN_LENGTH}
      specialCharsRegex={PWD_SPECIAL_CHAR_REGEXP}
      value={value}
      valueAgain={valueAgain}
      invalidTextColor={THEME_COLORS.text}
      validTextColor={THEME_COLORS.brand}
      iconSize={10}
      style={{
        fontSize: 'small',
        lineHeight: '12px',
        border: '1px solid',
        borderRadius: '4px',
        borderColor: '#DADADA',
        padding: '3px'
      }}
      messages={{
        minLength: t('password.min.length.rule', { length: PWD_MIN_LENGTH }),
        specialChar: t('password.special.rule', { specialChars: PWD_ALLOWED_SPECIAL_CHARS }),
        number: t('password.digit.rule'),
        capital: t('password.capital.rule'),
        lowercase: t('password.lowercase.rule'),
        match: t('password.match.rule')
      }}
    />
  );
};

export default PasswordValidator;

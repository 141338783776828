import { fromAddress, setKey, setLocationType, setRegion } from 'react-geocode';

setKey(process.env.REACT_APP_GEOCODE_API_KEY);
setRegion('fr');
setLocationType('ROOFTOP');

/**
 * Retrieves lat and lng from an address
 * @returns {undefined | {lat, lng}}
 */
const geocoder = async (address) => {
  try {
    const response = await fromAddress(address).then((response) => {
      const { results, status } = response;
      if (status === 'OK') {
        return results[0]?.geometry?.location;
      }
      console.error(`geocoder fromAddress request failed: status: ${status}`);
    });
    if (response) {
      const { lat, lng } = response;
      return { lat, lng };
    }
  } catch (e) {
    console.error('exception in geocoder utility', e);
  }
  return undefined;
};

export default geocoder;

import React from 'react';
import { Box } from 'grommet';

const MainTopMenu = ({ children, ...rest }) => {
  return (
    <Box
      {...rest}
      fill="horizontal"
      background="white"
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: 'xlarge', vertical: 'xsmall' }}
      style={{ boxShadow: '0px 4px 20px rgba(218, 218, 218, 0.25)' }}
    >
      {children}
    </Box>
  );
};

export default MainTopMenu;

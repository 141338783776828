import { Select } from 'grommet';
import styled from 'styled-components';

const CustomSelect = styled(Select)`
  && {
    font-size: ${(props) => (props.size ? props.size : '16px')};
    border: none !important;
    color: ${(props) => (props.textColor ? props.textColor : '#444444')};
  }
`;

export default CustomSelect;

import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const borderColor = '#000';
const errorBgColor = '#fbdddd';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderTopColor: '#000',
    borderTopWidth: 1,
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    borderRightColor: '#000',
    borderRightWidth: 2,
    borderLeftColor: '#000',
    borderLeftWidth: 2,
    alignItems: 'center',
    height: 50,
    textAlign: 'center',
    flexGrow: 1,
    fontSize: 10
  },
  cellBorder: { borderRightColor: borderColor, borderRightWidth: 1 },
  cell: {
    height: '100%',
    padding: '10px 5px',
    width: '15%'
  }
});

const TableRow = ({ data, eventHasRevenueInfo }) => (
  <View
    style={{ ...styles.container, ...(!data.confirmationMatches && { backgroundColor: errorBgColor }) }}
    wrap={false}
  >
    <Text style={{ ...styles.cell, ...styles.cellBorder }}>{data.time}</Text>
    <Text style={{ ...styles.cell, ...styles.cellBorder }}>{data.lastName}</Text>
    <Text style={{ ...styles.cell, ...styles.cellBorder }}>{data.firstName}</Text>
    <Text style={{ ...styles.cell, ...styles.cellBorder, width: '15%' }}>{data.confirmationEmployee}</Text>
    <Text style={{ ...styles.cell, ...styles.cellBorder, width: '15%' }}>{data.appointmentStatus}</Text>
    {eventHasRevenueInfo && <Text style={{ ...styles.cell, ...styles.cellBorder }}>{data.revenue}</Text>}
    <Text style={styles.cell}>{data.validity}</Text>
  </View>
);

export default TableRow;

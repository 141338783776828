import React from 'react';
import { Box, CheckBox, Text } from 'grommet';

const Check = (props) => {
  return (
    <Box direction="row" gap="small" pad="small" {...props}>
      <CheckBox
        checked={props.checked}
        onChange={(event) => props.setChecked(event.target.checked)}
        disabled={props?.disabled ? props.disabled : false}
      />
      <Text color={props.checked ? 'title' : 'text'} weight={props.checked ? 700 : 400}>
        {props.label}
      </Text>
    </Box>
  );
};

export default Check;

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Text } from 'grommet';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { ActionModal, ButtonWithSpinner, Calendar } from '../../../components';
import { updateEvent } from '../../../features/events/eventsSlice';

const MyBox = styled(Box)`
  box-shadow: 0px 4px 10px 5px rgba(233, 233, 233, 0.07);
`;

/**
 * computes minimum change date in future to change an existing event date
 * for event with no practitioner
 * min = tomorrow
 * @function
 * @returns {String} the min event change date in YYYY-MM-DD format
 */
const eventMinChangeDate = () => {
  return moment().add(parseInt(1, 10), 'days').tz('UTC').format('YYYY-MM-DD');
};

const EventDateSelector = ({ setShow, onChange, event, ...rest }) => {
  const { t } = useTranslation();
  const eventEarliestChange = eventMinChangeDate();
  const [value, setValue] = useState({});
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fatalError, setFatalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [, setDateChange] = useState(false);
  const [invalidDates, setInvalidDates] = useState([]);

  console.log(JSON.stringify(event));

  useEffect(() => {
    // disable all past dates back to early Unix Epoch date
    const dateRange = [moment('1970-01-01').tz('UTC').format('YYYY-MM-DD')];
    if (event) {
      let { specialty, dateStart, dateEnd, timeStart, timeEnd, location = '', practitionerInfo = '' } = event;

      if (event?.practitioner) {
        setFatalError(true);
        setModalErrorMessage(t('event.change.date.practitioner.event.error'));
      } else {
        setValue({
          specialty: specialty?._id,
          dateStart,
          dateEnd,
          timeStart,
          timeEnd,
          location,
          practitionerInfo
        });
        // existing event, start date can't be set earlier than today minus 15 days
        dateRange.push(moment(eventEarliestChange).subtract(1, 'days').format('YYYY-MM-DD'));
        setInvalidDates([dateRange]);
      }
    } else {
      setFatalError(true);
      setModalErrorMessage(t('event.change.date.no.event.error'));
    }
  }, [event, eventEarliestChange, t]);

  const setDate = (date) => {
    setDateChange(true);
    setValue({ ...value, dateStart: date, dateEnd: date });
  };

  const handleOnHideConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
    setShow(false);
    onChange();
  }, [setShow, onChange]);

  const handleOnHideErrorModal = useCallback(() => {
    setModalErrorMessage('');
    if (fatalError) {
      setShow(false); // fatal error, closing dialog
    }
  }, [fatalError, setShow]);

  const handleError = (errorMessage) => {
    console.error('EventDateSelector.js - Error during event update', errorMessage);
    if (errorMessage === 'event_date_not_modifiable' || errorMessage === 'event_type_not_modifiable') {
      setModalErrorMessage(t('page.createOrUpdateEvent.error.modal.already.accepted'));
    } else {
      // A generic message
      setModalErrorMessage(event ? t('event.update.error') : t('event.create.error'));
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      let dataEvent = { ...value };
      if (event) {
        const { status, data } = await dispatch(updateEvent(event._id, dataEvent));
        if (status === 'success') {
          setShowConfirmationModal(true);
        } else {
          handleError(data);
        }
      }
    } catch (e) {
      handleError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MyBox pad="medium" background="white" round="4px" flex={false} {...rest}>
      <Box align="center">
        <Box margin="medium" align="center" justify="center">
          <Text size="large" weight="bold" color="brand" margin={{ bottom: 'small' }} textAlign="center">
            {t('event.date.change.modal.title')}
          </Text>
        </Box>
        <Text>{t('event.date.change.modal.description')}</Text>
        <Box direction="row" width="450px">
          <Calendar
            fill
            disabled={invalidDates}
            margin="small"
            setDate={setDate}
            date={value.dateStart}
            daysOfWeek={true}
            firstDayOfWeek={1}
            showAdjacentDays={false}
          />
        </Box>
        <Box direction="row" gap="small" margin={{ horizontal: 'small', top: 'small' }} align="center" justify="start">
          <Box width="150px">
            <Button fill bold label={t('common.button.cancel')} size="medium" onClick={() => setShow(false)} />
          </Box>
          <Box width="200px">
            <ButtonWithSpinner
              fill
              bold
              primary
              isLoading={isLoading}
              label={t('event.change.date.button.label')}
              size="medium"
              onClick={handleSave}
            />
          </Box>
        </Box>
      </Box>
      <ActionModal
        showModal={showConfirmationModal}
        onHideModal={handleOnHideConfirmationModal}
        noCancelButton
        body={t('event.date.change.success.confirm.modal.text')}
        confirmLabel={t('common.label.ok').toUpperCase()}
      />
      <ActionModal
        showModal={!!modalErrorMessage}
        onHideModal={handleOnHideErrorModal}
        noCancelButton
        body={modalErrorMessage}
        confirmLabel={t('common.label.ok').toUpperCase()}
      />
    </MyBox>
  );
};

export default EventDateSelector;

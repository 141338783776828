import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faCirclePlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Formik } from 'formik';
import { Anchor, Box, Button, Card, FormField, ResponsiveContext, Select, TextInput } from 'grommet';
import { chunk } from 'lodash';
import * as Yup from 'yup';
import { THEME_COLORS } from '../../config/theme';
import { getUserGenderSelectOptions } from '../../constants';
import { getPractitionerOptions } from '../../features/specialties/specialtiesUtils';
import { normalizeDateStringOnBlur, normalizeStringOnBlur } from '../../utility/formValidation';

const getCardSpacing = (size) => {
  switch (size) {
    case 'small':
      return '5%';
    case 'medium':
      return '20%';
    default:
      return '25%';
  }
};

const InvitationInput = ({
  inputName,
  required,
  index,
  value,
  errors,
  disabled,
  t,
  handleChange,
  touched,
  handleBlur
}) => {
  const inputSize = 'small'; // size of form inputs text
  const formPlaceholders = {
    gender: t('form.gender.placeholder'),
    lastName: t('form.lastName.placeholder'),
    firstName: t('form.firstName.placeholder'),
    street: t('form.street.placeholder'),
    city: t('form.city.placeholder'),
    zipcode: t('form.zipcode.placeholder'),
    email: t('form.email.common.placeholder'),
    otherEmail: t('form.otherEmail.placeholder'),
    companyName: t('form.companyName.placeholder'),
    companySector: t('form.sector.placeholder'),
    siret: t('form.siret.placeholder'),
    specialty: t('form.specialty.placeholder'),
    position: t('form.professionalCategory.placeholder'),
    phone: t('form.phone.placeholder'),
    birthDate: t('form.birthDate.placeholder'),
    password: t('form.create.password.placeholder'),
    passwordConfirm: t('form.confirm.password.placeholder'),
    networkName: t('form.networkName.placeholder')
  };

  if (inputName === 'specialty') {
    return (
      <FormField
        label={t('form.specialty.label')}
        error={touched?.invitationList?.[index]?.[inputName] && errors?.invitationList?.[index]?.[inputName]}
        required={required}
        fill
      >
        <Select
          size={inputSize}
          name={`invitationList.${index}.specialty`}
          id={`invitationList.${index}.specialty`}
          plain
          placeholder={t('form.specialty.placeholder')}
          options={getPractitionerOptions()}
          labelKey="label"
          valueKey={{ key: 'key', reduce: true }}
          disabled={disabled}
          value={value}
          onChange={!disabled ? handleChange : null}
          onBlur={!disabled ? handleBlur : null}
        />
      </FormField>
    );
  }
  if (inputName === 'gender') {
    return (
      <FormField
        label={t('form.gender.label')}
        error={touched?.invitationList?.[index]?.[inputName] && errors?.invitationList?.[index]?.[inputName]}
        required={required}
        fill
      >
        <Select
          size={inputSize}
          name={`invitationList.${index}.gender`}
          id={`invitationList.${index}.gender`}
          plain
          placeholder={t('form.gender.placeholder')}
          options={getUserGenderSelectOptions()}
          labelKey="label"
          valueKey={{ key: 'keyString', reduce: true }}
          disabled={disabled}
          value={value}
          onChange={!disabled ? handleChange : null}
          onBlur={!disabled ? handleBlur : null}
        />
      </FormField>
    );
  }
  return (
    <FormField
      label={t(`form.${inputName}.label`)}
      error={touched?.invitationList?.[index]?.[inputName] && errors?.invitationList?.[index]?.[inputName]}
      required={required}
      fill
    >
      {/* TODO: internalize formPlaceholder object with localized string */}
      <TextInput
        size={inputSize}
        name={`invitationList.${index}.${inputName}`}
        placeholder={formPlaceholders[inputName]}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </FormField>
  );
};

const isRequired = (validationSchema, fieldName) => {
  const fieldValidationSchema = Yup.reach(validationSchema, `invitationList[].${fieldName}`);
  const tests = fieldValidationSchema.describe().tests;
  return tests ? !!tests.find((test) => test.name === 'required') : false;
};

const MultipleInvitationForm = ({ formBuilder, addInvitationLabel, onSubmit }) => {
  const { schema, initialValues, fields } = useMemo(
    () =>
      formBuilder.reduce(
        (acc, { fieldName, validation, initialValue, disabled }) => {
          acc.schema[fieldName] = validation;
          acc.initialValues[fieldName] = initialValue;
          acc.fields.push({ name: fieldName, disabled: disabled });
          return acc;
        },
        { schema: {}, initialValues: {}, fields: [] }
      ),
    [formBuilder]
  );

  const validationSchema = Yup.object().shape({
    invitationList: Yup.array().of(
      Yup.object().shape({
        ...schema
      })
    )
  });

  const { t } = useTranslation();
  const size = useContext(ResponsiveContext);
  const formRow = chunk(fields, 2);

  return (
    <Formik
      initialValues={{ invitationList: [initialValues] }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, handleSubmit, handleChange, touched, handleBlur, dirty, isValid, setFieldValue }) => (
        <FieldArray
          name="invitationList"
          render={(arrayHelpers) => (
            <div>
              {values.invitationList.map((formItem, formIndex) => (
                <Box key={`form-${formIndex}`} margin={{ bottom: 'medium', horizontal: getCardSpacing(size) }}>
                  <Card
                    pad={{ horizontal: 'medium', vertical: 'medium' }}
                    gap="xsmall"
                    flex={false}
                    margin={{ bottom: 'medium' }}
                  >
                    {values.invitationList.length > 1 && (
                      <Box direction="row" justify="end">
                        <FontAwesomeIcon
                          icon={faTimes}
                          color={THEME_COLORS.brand}
                          size="lg"
                          style={{ cursor: 'pointer' }}
                          onClick={() => arrayHelpers.remove(formIndex)}
                        />
                      </Box>
                    )}
                    {formRow.map((row, index) => (
                      <Box
                        key={`form-${formIndex}-row${index}`}
                        direction={size === 'small' ? 'column' : 'row'}
                        gap="medium"
                      >
                        {row.map((input) => (
                          <InvitationInput
                            key={`form-${formIndex}-${input.name}`}
                            inputName={input.name}
                            required={isRequired(validationSchema, input.name)}
                            index={formIndex}
                            value={formItem[input.name]}
                            errors={errors}
                            touched={touched}
                            disabled={input.disabled}
                            t={t}
                            handleChange={handleChange}
                            handleBlur={
                              input.name === 'phone'
                                ? (e) =>
                                    normalizeStringOnBlur(
                                      e,
                                      handleBlur,
                                      setFieldValue,
                                      `invitationList[${formIndex}].${input.name}`
                                    )
                                : input.name === 'birthDate'
                                  ? (e) => {
                                      normalizeDateStringOnBlur(
                                        e,
                                        handleBlur,
                                        setFieldValue,
                                        `invitationList[${formIndex}].${input.name}`
                                      );
                                    }
                                  : handleBlur
                            }
                          />
                        ))}
                      </Box>
                    ))}
                  </Card>
                  {formIndex === values.invitationList.length - 1 && (
                    <Anchor
                      weight="bold"
                      color={THEME_COLORS.brand}
                      icon={<FontAwesomeIcon icon={faCirclePlus} />}
                      label={addInvitationLabel}
                      onClick={() => arrayHelpers.push(initialValues)}
                    />
                  )}
                </Box>
              ))}

              <Box direction="row" justify="center" align="center" gap="medium" margin={{ vertical: 'small' }}>
                <Button
                  width="300px"
                  bold
                  primary
                  label={t('component.multipleInvitationForm.send', { count: values.invitationList.length })}
                  size="medium"
                  onClick={handleSubmit}
                  align="center"
                  disabled={!(isValid && dirty)}
                />
              </Box>
            </div>
          )}
        />
      )}
    </Formik>
  );
};

export default MultipleInvitationForm;

import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import { t } from 'i18next';
import { AvatarWithPlaceholder } from '../../components';
import { FileUploadKind } from '../../config';
import { THEME_COLORS } from '../../config/theme';
import { getUserGender, USER_GENDER } from '../../constants';
import PractitionerIdDisplay from '../../features/practitioner/PractitionerIdDisplay';
import { getPractitionerDefaultPresentation, getPractitionerTitleLabel } from '../../utility/practitionerUtils';
import Modal from './Modal';

//check if practitioner exist to render modal and prevent useMemo from being called conditionally
const PractitionerProfileModal = (props) => (props?.practitioner ? <ProfileModal {...props} /> : null);

const ProfileModal = ({ show, setShow, practitioner }) => {
  const practitionerPosition = getPractitionerTitleLabel(practitioner?.specialty);
  const practitionerPresentation = practitioner?.presentationText || getPractitionerDefaultPresentation(practitioner);
  const avatar = practitioner?.fileUploads?.find((file) => file.kind === FileUploadKind.avatar);
  const gender = practitioner?.gender ? getUserGender(practitioner.gender) : '';
  const background = practitioner?.background ? [...practitioner.background].sort((a, b) => b.year - a.year) : null;
  const experiences = practitioner?.experiences
    ? [...practitioner.experiences].sort((a, b) => b.startYear - a.startYear)
    : null;

  const handleCloseProfile = (e) => {
    e.stopPropagation();
    setShow(false);
  };

  const hasProfile = useMemo(
    () => (background && background.length) || (experiences && experiences.length),
    [background, experiences]
  );

  const presentationDisplay = useMemo(() => {
    const escape = (str) =>
      str.replace(/[&<>"']/g, (m) => ({ '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;' })[m]);
    const escStr = escape(practitionerPresentation);
    return escStr.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }, [practitionerPresentation]);

  return (
    <Modal show={show} setShow={setShow}>
      <Box round="4px" border={{ color: THEME_COLORS.text, size: '1px', style: 'solid' }} width={{ max: '600px' }}>
        <Box direction="row" alignSelf="end" margin="small">
          <FontAwesomeIcon
            icon={faCircleXmark}
            color={THEME_COLORS.title}
            size="1x"
            onClick={handleCloseProfile}
            cursor="pointer"
          />
        </Box>
        <Box pad={{ left: '45px', bottom: '25px', right: '45px' }}>
          <AvatarWithPlaceholder avatarSrc={avatar?.url} sizePx="96" isCompany={false} />
          <Text weight={700} size="large" color="brand" margin={{ top: '25px' }}>
            {practitioner?.gender !== USER_GENDER.OTHER && gender}{' '}
            {practitioner?.firstName ? practitioner.firstName : ''}{' '}
            {practitioner?.lastName ? practitioner.lastName.toUpperCase() : ''}, {practitionerPosition}
          </Text>
          <Box height={{ max: 'medium' }} overflow="auto" pad={{ top: 'small' }}>
            <Text size="16px" color="text" margin={{ top: '20px' }}>
              <div dangerouslySetInnerHTML={{ __html: presentationDisplay }} />
            </Text>
            {!hasProfile ? (
              <Text size="16px" color="text" margin={{ top: '20px' }} alignSelf="center">
                {t('page.practitioner.profile.empty')}
              </Text>
            ) : (
              <>
                {background && background.length > 0 && (
                  <>
                    <Text color="title" weight={700} margin={{ top: '20px' }}>
                      {t('common.practitioner.background')}
                    </Text>
                    <ul>
                      {background.map((record, index) => (
                        <li key={`background${index}`}>
                          <Text
                            size="16px"
                            color="text"
                            weight={400}
                            margin={{ left: '5px', top: '3px' }}
                            display="list-item"
                          >
                            {record.course} - {record.institution} - {record.year}
                          </Text>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {experiences && experiences.length > 0 && (
                  <>
                    <Text color="title" weight={700} margin={{ top: '20px' }}>
                      {t('common.practitioner.experience')}
                    </Text>
                    <ul>
                      {experiences.map((experience, index) => (
                        <li key={`experience-${index}`}>
                          <Text size="16px" color="text" weight={400} margin={{ left: '5px', top: '3px' }}>
                            {experience.description} - {experience.location}
                            {experience.startYear && (
                              <>
                                {experience.endYear ? (
                                  <>
                                    {' '}
                                    - {t('common.year.from.label').toLowerCase()} {experience.startYear}{' '}
                                    {t('common.year.to.lowercase.label')} {experience.endYear}
                                  </>
                                ) : (
                                  <> - {experience.startYear}</>
                                )}
                              </>
                            )}
                          </Text>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            )}
            {practitioner?.practitionerId && (
              <Box margin={{ top: '20px' }}>
                <PractitionerIdDisplay
                  textSize="medium"
                  practitionerId={practitioner.practitionerId}
                  labelWeight={700}
                  valueWeight={400}
                  hideIfEmpty
                />
              </Box>
            )}
          </Box>
          <Text size="13px" textAlign="center" margin={{ top: '35px' }}>
            <Trans i18nKey="page.practitioner.profile.warning" t={t} components={{ br: <br /> }} />
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};

export default PractitionerProfileModal;

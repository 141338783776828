import { faCircle } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Surround a FontAwesomeIcon with a circle
 * @param {Object} props Component props
 * @param {Object} props.icon A fontawesome icon
 * @param {string} props.sizePx  The size to apply
 * @param {string} props.color  The icon color
 * @returns
 */
const CircledFontAwesomeIcon = ({ icon, sizePx, color }) => {
  return (
    <div
      className="fa-stack"
      style={{
        width: sizePx,
        height: sizePx,
        color: color
      }}
    >
      <FontAwesomeIcon
        className="fa-stack-1x"
        style={{
          width: '50%',
          height: '50%'
        }}
        icon={icon}
      />
      <FontAwesomeIcon
        className="fa-stack-1x"
        style={{
          width: '100%',
          height: '100%'
        }}
        icon={faCircle}
      />
    </div>
  );
};

export default CircledFontAwesomeIcon;

import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

const locales = { fr };

// eslint-disable-next-line import/no-anonymous-default-export
export default function (date) {
  return formatDistanceToNow(date, {
    locale: locales[window.__localeId__]
  });
}

import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {},
  reducers: {
    updateNavigation: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { updateNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;

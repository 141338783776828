import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faTrash, faUpFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imageCompression from 'browser-image-compression';
import { Box, Button, Text } from 'grommet';
import { FileUploadKind, FileUploadTargetType } from '../../config';
import { THEME_COLORS } from '../../config/theme';
import { addOrUpdateFile, deleteFile } from '../../features/auth/authSlice';
import AvatarWithPlaceholder from '../Card/AvatarWithPlaceholder';

/**
 * @description Implement the avatar uploader allow user to upload, update or delete their avatar
 * @component
 * @param {Object} props component props
 * @param {Object} props.avatar Object containing all avatar properties
 * @param {string} props.targetId The targeted user or company id
 * @param {Boolean} [props.isCompany=false] indicates if it is used in the context of a company
 * @return {React.ReactElement}
 */
const AvatarUploader = ({ avatar, targetId, isCompany = false, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const avatarRef = useRef(null);

  const handleClickFileInput = (e) => {
    // VBZ-669: Hack in case we select the same file, in this case the input doesn't see the change
    e.target.value = '';
  };

  const handleDeleteAvatarClick = useCallback(() => {
    dispatch(deleteFile(avatar, targetId));
  }, [dispatch, avatar, targetId]);

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChangeAvatar = async (event) => {
    try {
      setIsLoading(true);
      const fileUploaded = event.target.files[0];
      const originalFileName = fileUploaded.name;
      const targetType = isCompany ? FileUploadTargetType.company : FileUploadTargetType.user;

      // compress image
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(fileUploaded, options);

      // Send file to the api and retrieve url
      await dispatch(
        addOrUpdateFile(compressedFile, originalFileName, FileUploadKind.avatar, avatar?._id, targetId, targetType)
      );
    } catch (e) {
      console.error('AvatarUploader.js/handleChangeAvatar', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box direction="column" {...props}>
      <Text size="small">
        {isCompany ? t('page.parameters.company.avatar.title') : t('page.parameters.practitioner.avatar.title')}
      </Text>
      <Box direction="row" align="center" gap="small" margin={{ top: 'small' }}>
        <AvatarWithPlaceholder avatarSrc={avatar?.url} sizePx="72" isCompany={isCompany} />
        <Box direction="column" gap="xxsmall">
          <Button
            icon={<FontAwesomeIcon icon={faUpFromBracket} color={THEME_COLORS.brand} />}
            bold
            label={avatar ? t('common.button.modify') : t('common.button.select')}
            size="small"
            onClick={() => avatarRef.current.click()}
            disabled={isLoading}
          />
          <input
            type="file"
            ref={avatarRef}
            onChange={handleChangeAvatar}
            onClick={handleClickFileInput}
            style={{ display: 'none' }}
          />

          {avatar && (
            <Button
              icon={<FontAwesomeIcon icon={faTrash} color={THEME_COLORS.brand} />}
              bold
              label={t('common.button.delete')}
              size="small"
              onClick={handleDeleteAvatarClick}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AvatarUploader;

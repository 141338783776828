import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Layer, Text } from 'grommet';
import { Button } from '../../components';
import { THEME_COLORS } from '../../config/theme';

/**
 * @description Implements a modal confirmation dialog
 * @component
 * @property {String|Object} message - The confirmation message text if string or configuration object for <Trans> component
 * @property {String} actionText - the label of the action (primary) button
 * @property {callback} onConfirm - callback triggered when user confirms operation
 * @property {callback} onCancel - callback triggered when user cancels operation
 * @property {Object} [dialogIcon = faTriangleExclamation] - icon to use for confirm dialog
 * @property {Object} [iconColor = THEME_COLORS.text] - color of icon to use for confirm dialog
 * @property {Object} [iconSize = "3x"] - size of icon to use for confirm dialog
 */
const ConfirmationDialog = ({
  message,
  actionText,
  onConfirm,
  onCancel,
  dialogIcon = faTriangleExclamation,
  iconColor = THEME_COLORS.text,
  iconSize = '3x'
}) => {
  const { t } = useTranslation();

  return (
    <Layer position="center" onClickOutside={onCancel} onEsc={onCancel}>
      <Box pad="large" gap="medium">
        <Box direction="row" gap="small">
          <FontAwesomeIcon icon={dialogIcon} color={iconColor} size={iconSize} />
          {typeof message === 'string' ? (
            <Text>{message}</Text>
          ) : (
            <Trans i18nKey={message.id} t={t} components={message.components} />
          )}
        </Box>
        <Box direction="row" gap="medium" align="center" justify="center">
          <Button
            label={t('common.button.cancel')}
            color="brand"
            border={{ color: 'brand', size: '2px' }}
            onClick={onCancel}
          />
          <Button primary label={actionText} onClick={onConfirm} />
        </Box>
      </Box>
    </Layer>
  );
};

export default ConfirmationDialog;

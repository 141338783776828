import React from 'react';
import { Box } from 'grommet';

// TODO : add in components with props options (width, height, background)
const RoundButton = ({ size = '28px', outline = false, color = 'accent', children, ...rest } = {}) => (
  <Box
    style={{ cursor: 'pointer' }}
    round="full"
    width={size}
    height={size}
    background={!outline ? color : ''}
    border={outline && { color, side: 'all', size: '1px' }}
    align="center"
    justify="center"
    flex={false}
    {...rest}
  >
    {children}
  </Box>
);

export default RoundButton;

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { ToolTip } from '../../components';
import { theme } from '../../config/theme';
import { VALIDATION_STATUS } from '../../constants';
import { isRemoteSpecialty } from '../../features/specialties/specialtiesUtils';
import { CARD_ACTION_BOX_MARGIN, CARD_ACTION_TEXT_SIZE, getEventCompanyAvatarSrc, toDate } from '../Card/cardData';
import { CardButton } from '../index';
import AvatarWithPlaceholder from './AvatarWithPlaceholder';

const ShadowBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(210, 210, 210, 0.08);
  border-radius: 8px;
  border: ${(props) => props.selected && `2px ${theme.global.colors.brand} dashed`};
  padding: ${(props) => props.selected && '3px'};
`;

const AvailableEventsCard = ({
  company,
  event,
  setInscription,
  setConfirmConditions,
  register,
  selected,
  layoutBasis = 'full',
  minWidth = '300px',
  isDelegateEvent,
  ...rest
}) => {
  const user = useSelector((state) => state?.auth?.user);
  const isUserActive = useMemo(() => user?.status === VALIDATION_STATUS.activated, [user]);
  const { t } = useTranslation();
  const { specialty } = event ?? {};

  const handleRegister = useCallback(() => {
    if (isUserActive) {
      register(event._id);
      if (isDelegateEvent || isRemoteSpecialty(specialty)) {
        setConfirmConditions(event._id);
      } else {
        setInscription(true);
      }
    }
  }, [event, specialty, isUserActive, setInscription, register, isDelegateEvent, setConfirmConditions]);

  return (
    <Box pad="small" basis={layoutBasis} style={{ minWidth }} flex={false} {...rest}>
      <ShadowBox
        round="8px"
        overflow="hidden"
        align="center"
        background="brand"
        selected={selected}
        margin={{ bottom: 'medium', top: 'medium' }}
      >
        <Box fill="horizontal" background="brand" pad="small" height="80px">
          <Text color="white">
            {toDate(event.dateStart)}
            {' - '}
            {event.timeStart}
            {' à '}
            {event.timeEnd}
          </Text>
          {isDelegateEvent && (
            <Text weight={700} color="white">
              {event.specialty.label}
            </Text>
          )}
        </Box>
        <Box fill background="white" justify="center" margin="medium">
          <Box margin={{ vertical: 'small', horizontal: 'medium' }} align="center" gap="small">
            <AvatarWithPlaceholder avatarSrc={getEventCompanyAvatarSrc(event)} isCompany sizePx="72" />
            <Box align="center" margin={{ bottom: 'medium' }} style={{ textAlign: 'center', wordBreak: 'break-word' }}>
              <Text color="title" weight={700} size="20px">
                {company && company.name}
              </Text>
              <Text>{company && company.address.street}</Text>
              <Text>
                {company && company.address.zipcode} {company && company.address.city}
              </Text>
            </Box>
          </Box>
          <ToolTip content={isUserActive ? '' : "Votre compte n'est pas activé"} placement="bottom">
            <CardButton onClick={handleRegister} pad={{ vertical: 'xsmall' }} margin={CARD_ACTION_BOX_MARGIN}>
              <Text color={isUserActive ? 'brand' : 'red'} size={CARD_ACTION_TEXT_SIZE}>
                {t('practitioner.event.registration.title')}
              </Text>
            </CardButton>
          </ToolTip>
        </Box>
      </ShadowBox>
    </Box>
  );
};

export default AvailableEventsCard;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { upperCase } from 'lodash';
import moment from 'moment';
import { THEME_COLORS } from '../../config/theme';
import { useAppointmentsInformations } from '../../features/events/useAppointmentsInformations';
import { usePopulatedAppointment } from '../../features/events/usePopulatedAppointment';
import {
  AppointmentStatus,
  computeAppointmentRevenue,
  getAppointmentStatusLabel,
  isEventWithEquipment,
  parseRevenue
} from '../../utility/practitionerUtils';
import { Spinner, ToolTip } from '../index';
import AnalyticsDocument from './AppointmentsPdf/AnalyticsDocument';

const EventAnalyticsDownloadButton = ({ event, tooltipPlacement }) => {
  const { t } = useTranslation();
  const { achievedAppointmentsCount, totalRevenue } = useAppointmentsInformations(event?.appointments, event);
  const populatedAppointments = usePopulatedAppointment(event?.appointments, false, true) || [];
  let tableData = [];

  if (event && event?.status === 'registered') {
    tableData = populatedAppointments.reduce((acc, appointment) => {
      let confirmationMatches = true;
      let validity = upperCase(t('common.label.ok'));
      const isAnEventWithEquipment = isEventWithEquipment(event);
      if (appointment.beneficiaryAttended === true) {
        if (appointment.status && appointment.status !== AppointmentStatus.seen) {
          confirmationMatches = false;
          validity = t('pdf.events.analytics.validity.error.attendance');
        }
        if (
          isAnEventWithEquipment &&
          ((appointment.beneficiaryEquipped === true && !appointment.revenue) ||
            (appointment.beneficiaryEquipped === false && !!appointment.revenue))
        ) {
          confirmationMatches = false;
          validity = t('pdf.events.analytics.validity.error.revenue');
        }
      } else if (appointment.beneficiaryAttended === false) {
        if (appointment.status && appointment.status === AppointmentStatus.seen) {
          confirmationMatches = false;
          validity = t('pdf.events.analytics.validity.error.attendance');
        }
      }
      let beneficiaryAttended = t('common.beneficiary.appointment.status.unknown');
      if (appointment.beneficiaryAttended === true) {
        beneficiaryAttended = t('common.beneficiary.appointment.status.present');
      } else if (appointment.beneficiaryAttended === false) {
        beneficiaryAttended = t('common.beneficiary.appointment.status.absent');
      }
      acc.push({
        time: `${appointment.start} - ${appointment.end}`,
        lastName: appointment.populatedEmployee.lastName,
        firstName: appointment.populatedEmployee.firstName,
        confirmationEmployee: beneficiaryAttended,
        appointmentStatus: getAppointmentStatusLabel(appointment?.status),
        revenue: parseRevenue(computeAppointmentRevenue(appointment, event)),
        confirmationMatches,
        validity
      });
      return acc;
    }, []);
  }

  return (
    <>
      {tableData.length > 0 ? (
        <ToolTip content={t('pdf.events.analytics.button.tooltip')} placement={tooltipPlacement || 'bottom'}>
          <PDFDownloadLink
            document={
              <AnalyticsDocument
                tableData={tableData}
                achievedAppointmentsCount={achievedAppointmentsCount}
                totalRevenue={totalRevenue}
                event={event}
              />
            }
            fileName={t('pdf.events.analytics.fileName', {
              eventDate: moment(event.dateStart).tz('UTC').format('DD_MM_YYYY')
            })}
            onClick={(e) => e.stopPropagation()}
          >
            {({ loading }) =>
              loading ? (
                <Spinner size={7} />
              ) : (
                <FontAwesomeIcon icon={faDownload} color={THEME_COLORS.brand} size="lg" />
              )
            }
          </PDFDownloadLink>
        </ToolTip>
      ) : (
        <FontAwesomeIcon icon={faDownload} color={THEME_COLORS.brand} opacity={0.3} size="lg" />
      )}
    </>
  );
};

export default EventAnalyticsDownloadButton;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Layer, Text } from 'grommet';
import * as levenshtein from 'js-levenshtein';
import { TruncatedText } from 'src/components';
import { THEME_COLORS } from '../../config/theme';

/**
 * checks if value matches any of the existing values just by considering
 * lower case value or if provided Levenshtein distance is matched
 * @param {string} value - the new value to check
 * @param {string|null} curValue - the current value in case of edit or null
 * @param {string[]} existingValues - existing values to compare value to
 * @param {number} [levenshteinDistance=2] - levenshtein distance to use for algorithm
 * @returns {string[] | []} - Array of potential matching strings from existing values, empty array if no match found
 */
export const inputHasMatch = (value, curValue, existingValues, levenshteinDistance = 2) => {
  const matches = [];
  const lowerCaseVal = value.toLowerCase();
  const valuesToCheck = curValue ? existingValues.filter((val) => val !== curValue) : existingValues;
  valuesToCheck.forEach((existingValue) => {
    if (
      existingValue.toLocaleLowerCase() === lowerCaseVal ||
      levenshtein(value, existingValue) <= levenshteinDistance
    ) {
      matches.push(existingValue);
    }
  });
  return matches;
};

const DuplicateInputWarning = ({ warningMsgId, confirmMsgId, matches, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Layer position="center">
      <Box pad="medium" gap="medium" width="500px">
        <Box direction="row" gap="small">
          <FontAwesomeIcon icon={faTriangleExclamation} color={THEME_COLORS.text} size="3x" />
          <Text>{t(warningMsgId, { count: matches.length })}</Text>
        </Box>
        <Box>
          <ul>
            {matches.map((match, index) => {
              return (
                <li key={`match-${index}`}>
                  <TruncatedText message={match} width="400px" />
                </li>
              );
            })}
          </ul>
        </Box>
        <Box align="center">
          <Text>{t(confirmMsgId)}</Text>
        </Box>
        <Box direction="row" gap="medium" align="center" justify="center">
          <Button secondary label={t('common.button.cancel')} onClick={onCancel} />
          <Button primary label={t('common.label.confirm')} onClick={onConfirm} />
        </Box>
      </Box>
    </Layer>
  );
};

export default DuplicateInputWarning;
